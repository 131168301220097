import {
  LOGIN_REQUEST,
  REGISTER_USER_REQUEST,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  CLEAR_ERRORS,
  LOAD_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  GOOGLE_REQUEST,
  GOOGLE_SUCCESS,
  GOOGLE_FAIL,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_ROLE_REQUEST,
  FETCH_NOTIFICATIONS,
  FETCH_UNREAD_NOTIFICATION_COUNT,
  MARK_NOTIFICATION_READ,
} from "../constants/userConstants";
import { auth, db, googleProvider } from "../firebase.config";
import { get, post, custom_request, patch, del } from "../util/APIUtils";

import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
} from "firebase/auth";
import { login } from "../util/APIUtils";
import { ACCESS_TOKEN, API_BASE_URL } from "../constantsNew";

export const UPDATE_USER_AVATAR = "UPDATE_USER_AVATAR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const FETCH_TIMEZONES_REQUEST = "FETCH_TIMEZONES_REQUEST";
export const FETCH_TIMEZONES_SUCCESS = "FETCH_TIMEZONES_SUCCESS";
export const FETCH_TIMEZONES_FAILURE = "FETCH_TIMEZONES_FAILURE";
export const SET_USER_TIMEZONE = "SET_USER_TIMEZONE";
export const SET_USER_TIMEZONE_ERROR = "SET_USER_TIMEZONE_ERROR";

export const signUpUsingEmail = (email, password, name) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });
    const userRef = collection(db, "user");
    const q = query(userRef, where("email", "==", email));
    const getUserInfo = await getDocs(q);

    if (getUserInfo.docs.length === 0) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;

          await setDoc(doc(db, "user", user.uid), {
            name: name,
            email: email,

            uid: user.uid,
            role: "user",
            profile_image: "",
          });
          await setDoc(doc(db, "userChats", user.uid), {});
          const data = {
            uid: user.uid,
          };
          localStorage.setItem("loginType", "email");
          dispatch({ type: REGISTER_USER_SUCCESS, payload: user.uid });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("user exists");
    }
  } catch (err) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: err,
    });
  }
};

export const loginUsingPhone =
  (phone, otp, fullName, email) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      console.log(otp);
      window.confirmationResult
        .confirm(otp)
        .then(async (res) => {
          const userRef = collection(db, "user");
          const q = query(userRef, where("email", "==", email));
          const getUserInfo = await getDocs(q);
          const userMobileRef = collection(db, "user");
          const qMobile = query(userMobileRef, where("ContactNo", "==", phone));
          const getUserMobileInfo = await getDocs(qMobile);
          if (
            getUserInfo.docs.length === 0 &&
            getUserMobileInfo.docs.length === 0
          ) {
            await setDoc(doc(db, "user", res?.user?.uid), {
              FirstName: fullName,
              email: email,
              ContactNo: phone,
              uid: res.user.uid,
              role: "user",
              profile_image: "",
            })
              .then(() => {
                localStorage.setItem("loginType", "phone");
                dispatch({
                  type: REGISTER_USER_SUCCESS,
                  payload: res.user.uid,
                });
              })
              .catch((err) => {
                dispatch({
                  type: REGISTER_USER_FAIL,
                  payload: err,
                });
              });
          } else if (
            getUserInfo.docs.length !== 0 &&
            getUserMobileInfo.docs.length === 0
          ) {
            alert("Email already in use");
          } else {
            dispatch({ type: LOGIN_SUCCESS, payload: res.user.uid });
          }
        })
        .catch((err) => {
          dispatch({
            type: LOGIN_FAIL,
            payload: "OTP is Incorrect",
          });
        });
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.message,
      });
    }
  };

export const loadUser = (uid) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });
    const userRef = doc(db, "user", uid);
    // const userSnap = await getDoc(userRef);

    const userData = await onSnapshot(doc(db, "user", uid), (doc) => {
      console.log("Current data: ", doc.data());
      dispatch({ type: LOAD_USER_SUCCESS, payload: doc.data() });
    });
    console.log(userData);
  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.message });
  }
};

export const loginUsingEmail = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    login({ email, password })
      .then((response) => {
        // Signed in
        console.log("Signed in ====> ", response);
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);

        dispatch({ type: LOGIN_SUCCESS, payload: response.uid });
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error.message);
        dispatch({
          type: LOGIN_FAIL,
          payload: error.message,
        });
      });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    signOut(auth)
      .then(() => {
        console.log("user logged out");
      })
      .catch((error) => {
        console.log(error.message);
      });
    localStorage.setItem("loginType", "");
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error.message });
  }
};

export const signUpUsingGoogle = () => async (dispatch) => {
  try {
    dispatch({ type: GOOGLE_REQUEST });
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        const user = result.user;
        console.log(user);
        const userRef = collection(db, "user");
        const q = query(userRef, where("email", "==", user.email));
        const getUserInfo = await getDocs(q);
        if (getUserInfo.docs.length === 0) {
          await setDoc(doc(db, "user", user.uid), {
            uid: user.uid,
            role: "user",
            name: user.displayName,
            email: user.email,
            ContactNo: "",
            profile_image: user?.photoURL,
          });
          await setDoc(doc(db, "userChats", user.uid), {});
          const data = {
            uid: user.uid,
          };
        }
        localStorage.setItem("loginType", "google");
        dispatch({ type: GOOGLE_SUCCESS, payload: user.uid });
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error.message);
        // ...
      });
  } catch (error) {
    dispatch({ type: GOOGLE_FAIL, payload: error.message });
  }
};
export const updateProfile =
  (uid, name, email, phone, role, profileUrl) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST });
      await updateDoc(doc(db, "user", uid), {
        FirstName: name,
        email: email,

        ContactNo: "+91 " + phone,

        profile_image: profileUrl,
      })
        .then(() => {
          console.log("User Updated");
        })
        .catch((error) => {
          console.log(error.message);
        });

      dispatch({ type: UPDATE_PROFILE_SUCCESS });
    } catch (error) {
      dispatch({ type: UPDATE_PROFILE_FAIL, payload: error.message });
    }
  };

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const uploadProfileImage = (file) => async (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await post(
      "/post-service/api/file-uploads",
      formData,
      undefined,
      "fileUpload"
    );
    return response;
  } catch (error) {
    console.error("Error uploading profile image:", error);
    throw error;
  }
};

export const updateUserAvatar = (userId, avatarUrl) => async (dispatch) => {
  const updateRequest = {
    avatar: avatarUrl,
  };

  const headers = new Headers();
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
    headers.append("Content-Type", "application/json");
  }

  try {
    const response = await fetch(
      API_BASE_URL + `/stridecal-backend/api/app-users/${userId}`,
      {
        method: "PATCH",
        body: JSON.stringify(updateRequest),
        headers: headers,
      }
    );
    const data = await response.json();
    dispatch({ type: UPDATE_USER_AVATAR, payload: data });
    return data;
  } catch (error) {
    console.error("Error updating user avatar:", error);
    throw error;
  }
};

export const updateUserProfile = (userData) => ({
  type: "UPDATE_USER_PROFILE",
  payload: userData,
});

export const addUserRoles =
  (userId, role, setUserProfile, setUpdateSuccess) => async (dispatch) => {
    const response = await patch(
      `/stridecal-backend/api/app-users/${userId}/roles`,
      { role }
    );
    if (response) {
      console.log("Roles updated successfully:", response);
      // Update the Redux store with the new user data
      dispatch(updateUserProfile(response));
      // Update the local state with the response data
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        ...response,
      }));
      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 3000); // Reset after 3 seconds
    }
  };

export const removeUserRoles =
  (userId, role, setUserProfile, setUpdateSuccess) => async (dispatch) => {
    const response = await del(
      `/stridecal-backend/api/app-users/${userId}/roles/${role}`
    );

    if (response) {
      console.log("Role removed successfully:", response);
      // Update the Redux store with the new user data
      dispatch(updateUserProfile(response));
      // Update the local state with the response data
      setUserProfile((prevProfile) => ({
        ...prevProfile,
        ...response,
      }));
      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 3000); // Reset after 3 seconds
    }
  };

export const fetchTimezones = () => async (dispatch) => {
  dispatch({ type: FETCH_TIMEZONES_REQUEST });
  try {
    const response = await get("/calendar-service/api/calendar/timezones");
    dispatch({
      type: FETCH_TIMEZONES_SUCCESS,
      payload: response.timeZones,
    });
  } catch (error) {
    dispatch({
      type: FETCH_TIMEZONES_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchUserTimezone = (userName) => async (dispatch) => {
  try {
    const response = await get("/calendar-service/api/calendar/userTimezones");
    if (response) {
      dispatch({
        type: SET_USER_TIMEZONE,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: SET_USER_TIMEZONE_ERROR,
      payload: error.message,
    });
  }
};


export const fetchNotifications = (page) => async (dispatch) => {
  
  try {
    let pageNumber = page? page.pageNumber+1 : 0;
    const response = await get(`/notification-service/ui/v1/notifications?page=${pageNumber}`);
    console.log("Notifications",response)
    dispatch({
      type: FETCH_NOTIFICATIONS,
      payload: response,
    });
  } catch (error) {
    console.log("Could not fetch notfications",error)
  }
};

export const fetchUnreadNotificationCount = () => async (dispatch) => {
  
  try {
    const response = await get("/notification-service/ui/v1/notifications/unread/count");
    console.log("Notifications",response)
    dispatch({
      type: FETCH_UNREAD_NOTIFICATION_COUNT,
      payload: response,
    });
  } catch (error) {
    console.log("Could not fetch notfications",error)
  }
};

export const markNotificationRead = (id) => async (dispatch) => {
  
  try {
    const response = await patch(`/notification-service/api/v1/notifications/${id}/status`,{isUnread: false});
    console.log("Notifications",response)
    dispatch({
      type: MARK_NOTIFICATION_READ,
      payload: response,
    });
  } catch (error) {
    console.log("Could not mark notfication as read",error)
  }
};
