import React, { useEffect, useRef, useState } from "react";
import {
  BiPlus,
  BiImage,
  BiCalendar,
  BiCheck,
  BiX,
  BiTrash,
  BiChevronDown,
  BiChevronUp,
} from "react-icons/bi";
import AuthImage from "../AuthImage/AuthImage";
import AddTodoModal from "./AddTodoModal";
import { Confirm } from "react-st-modal";

const TodosSection = ({
  project,
  handleSaveTodo,
  handleUpdateTodo,
  handleDeleteTodo,
}) => {
  const [showTodoModal, setShowTodoModal] = useState(false);
  const [showInlineAdd, setShowInlineAdd] = useState(false);
  const [newTodoTitle, setNewTodoTitle] = useState("");
  const [selectedTodo, setSelectedTodo] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null);
  const [showCompleted, setShowCompleted] = useState(true);

  const inputRef = useRef(null);
  const confirmationRef = useRef(null);
  const saveButtonRef = useRef(null);

  // Separate todos into pending and completed
  const pendingTodos = project?.toDos.filter((todo) => !todo.isCompleted) || [];
  const completedTodos =
    project?.toDos.filter((todo) => todo.isCompleted) || [];

  useEffect(() => {
    if (showInlineAdd && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInlineAdd]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        confirmationRef.current &&
        !confirmationRef.current.contains(event.target)
      ) {
        setShowConfirmation(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatDate = (dueDate) => {
    const date = new Date(dueDate);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  };

  const TodoItem = ({ toDo }) => (
    <li className="p-4 hover:bg-gray-50 transition duration-150 ease-in-out cursor-pointer">
      <div className="flex items-center space-x-4">
        <div
          className="flex-shrink-0 w-8 h-8"
          onClick={() => handleEditTodo(toDo)}
        >
          {toDo.fileUrl ? (
            <AuthImage
              imageurl={toDo.fileUrl}
              className="w-full h-full object-cover rounded"
            />
          ) : (
            <div className="w-full h-full bg-gray-200 rounded flex items-center justify-center">
              <BiImage size={20} className="text-gray-400" />
            </div>
          )}
        </div>
        <div className="flex-grow" onClick={() => handleEditTodo(toDo)}>
          <h4
            className={`text-lg font-semibold ${
              toDo.isCompleted ? "text-gray-500 line-through" : "text-gray-800"
            } truncate`}
          >
            {toDo.title}
          </h4>
          <div className="flex items-center text-sm text-gray-500">
            <BiCalendar size={14} className="mr-1" />
            <span>
              {toDo.dueDate ? formatDate(toDo.dueDate) : "No due date"}
            </span>
          </div>
        </div>
        <div className="flex-shrink-0 flex items-center space-x-2">
          <span className="px-2 py-1 text-xs font-semibold rounded bg-gray-200 text-gray-700">
            {toDo.todoType || "TASK"}
          </span>
          <span
            className={`px-2 py-1 text-xs font-semibold rounded ${
              toDo.priority === "HIGHEST"
                ? "bg-red-500 text-white"
                : toDo.priority === "HIGH"
                ? "bg-orange-500 text-white"
                : toDo.priority === "MEDIUM"
                ? "bg-yellow-500 text-white"
                : toDo.priority === "LOW"
                ? "bg-green-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {toDo.priority || "N/A"}
          </span>
          <div className="relative">
            <button
              onClick={(e) => handleStatusClick(e, toDo.id)}
              className={`px-2 py-1 text-xs font-semibold rounded ${
                toDo.isCompleted
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              }`}
            >
              {toDo.isCompleted ? "Completed" : "Pending"}
            </button>
            {showConfirmation === toDo.id && (
              <div
                ref={confirmationRef}
                className="absolute right-0 mt-2 py-1 bg-white rounded-md shadow-xl z-20 min-w-max"
              >
                <button
                  className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:text-blue-500 transition-colors duration-200"
                  onClick={() => handleConfirmStatusChange(toDo)}
                >
                  Mark as {toDo.isCompleted ? "Pending" : "Done"}
                </button>
              </div>
            )}
          </div>
          {project.adminId === null && (
            <button
              onClick={(e) => handleDeleteClick(e, toDo.id)}
              className="px-2 py-1 text-xs font-semibold rounded bg-red-100 text-red-800 hover:bg-red-200 transition-colors duration-200"
            >
              <BiTrash size={14} />
            </button>
          )}
        </div>
      </div>
    </li>
  );

  // Rest of the handler functions remain the same
  const handleEditTodo = (todo) => {
    setSelectedTodo(todo);
    setShowTodoModal(true);
  };

  const handleSaveOrUpdate = (todoData) => {
    if (selectedTodo) {
      handleUpdateTodo(todoData);
    } else {
      handleSaveTodo(todoData);
    }
    setSelectedTodo(null);
  };

  const handleInlineAddTodo = () => {
    if (newTodoTitle.trim()) {
      handleSaveTodo({
        title: newTodoTitle,
        isCompleted: false,
        priority: "MEDIUM",
        todoType: "TASK",
      });
      setNewTodoTitle("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleDeleteClick = async (e, todoId) => {
    e.stopPropagation();
    const isConfirmed = await Confirm(
      "Are you sure you want to delete this todo?",
      "Confirm Deletion"
    );
    if (isConfirmed) {
      handleDeleteTodo(project.id, todoId);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInlineAddTodo();
    }
  };

  const handleClickOutside = (e) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      saveButtonRef.current &&
      !saveButtonRef.current.contains(e.target)
    ) {
      setShowInlineAdd(false);
    }
  };

  const handleStatusClick = (e, todoId) => {
    e.stopPropagation();
    setShowConfirmation(todoId);
  };

  const handleConfirmStatusChange = (todo) => {
    handleUpdateTodo({
      ...todo,
      isCompleted: !todo.isCompleted,
    });
    setShowConfirmation(null);
  };

  return (
    <div className="w-full space-y-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-bold text-gray-800">Todos</h3>
        {!project.programId && (
          <button
            onClick={() => setShowTodoModal(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg flex items-center shadow-md hover:bg-blue-700 transition duration-300"
          >
            <BiPlus size={20} className="mr-2" />
            <span>Add Todo</span>
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        {showInlineAdd ? (
          <div className="p-4 bg-gray-50 border-b border-gray-200">
            <div className="flex items-center">
              <input
                ref={inputRef}
                type="text"
                value={newTodoTitle}
                onChange={(e) => setNewTodoTitle(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Enter todo title"
                className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <button
                ref={saveButtonRef}
                onClick={handleInlineAddTodo}
                className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 transition duration-300"
              >
                <BiCheck size={20} />
              </button>
              <button
                onClick={() => setShowInlineAdd(false)}
                className="ml-2 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300"
              >
                <BiX size={20} />
              </button>
            </div>
          </div>
        ) : (
          <>
            {!project.programId && (
              <div
                className="p-4 bg-gray-50 border-b border-gray-200 flex items-center text-blue-600 hover:text-blue-800 cursor-pointer transition-colors duration-200"
                onClick={() => setShowInlineAdd(true)}
              >
                <BiPlus size={20} className="mr-2" />
                <span className="text-sm font-medium">Quick Add Todo</span>
              </div>
            )}
          </>
        )}

        {/* Pending Todos Section */}
        <div className="border-b border-gray-200">
          <div className="bg-gray-50 px-4 py-3">
            <div className="flex items-center justify-between">
              <h4 className="font-semibold text-gray-700">
                Pending Tasks ({pendingTodos.length})
              </h4>
            </div>
          </div>
          <ul className="divide-y divide-gray-200">
            {pendingTodos.map((todo, index) => (
              <TodoItem key={index} toDo={todo} />
            ))}
            {pendingTodos.length === 0 && (
              <li className="p-4 text-center text-gray-500">
                No pending tasks
              </li>
            )}
          </ul>
        </div>

        {/* Completed Todos Section */}
        <div>
          <div
            className="bg-gray-50 px-4 py-3 cursor-pointer"
            onClick={() => setShowCompleted(!showCompleted)}
          >
            <div className="flex items-center justify-between">
              <h4 className="font-semibold text-gray-700">
                Completed Tasks ({completedTodos.length})
              </h4>
              {showCompleted ? (
                <BiChevronUp size={20} />
              ) : (
                <BiChevronDown size={20} />
              )}
            </div>
          </div>
          {showCompleted && (
            <ul className="divide-y divide-gray-200">
              {completedTodos.map((todo, index) => (
                <TodoItem key={index} toDo={todo} />
              ))}
              {completedTodos.length === 0 && (
                <li className="p-4 text-center text-gray-500">
                  No completed tasks
                </li>
              )}
            </ul>
          )}
        </div>
      </div>

      <AddTodoModal
        visible={showTodoModal}
        onClose={() => {
          setShowTodoModal(false);
          setSelectedTodo(null);
        }}
        onSave={handleSaveOrUpdate}
        initialValues={selectedTodo}
      />
    </div>
  );
};

export default TodosSection;
