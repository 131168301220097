import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  fetchColorScheme,
  createEvent,
  fetchEventById,
} from "../../actions/calendarActions";
import { addMinutes } from "date-fns";
import SearchIcon from "../../assets/icons/search.svg";
import NotificationIcon from "../../assets/icons/notification.svg";
import Modal from "../Planner/Modal";
import preppyDog from "../../assets/preppyDog.png";
import CreateProjectModal from "../Projects/CreateProjectModal";
import Notification from "../Notification/Notification";
import CustomColorDropdown from "./CustomColorDropdown";
import moment from "moment";
import DetailsModal from "../Planner/DetailsModal";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const TopBar = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { users } = useSelector((state) => state);
  const currentUser = users?.currentUser;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const colorScheme = useSelector((state) => state.calendar.colorScheme);
  const notifications = useSelector(
    (state) => state.myNotifications.notifications
  );
  const notificationPage = useSelector(
    (state) => state.myNotifications.notificationPage
  );
  const unreadNotificationCount = useSelector(
    (state) => state.myNotifications.unreadNotificationCount
  );
  const menuRef = useRef();
  const notificationRef = useRef();
  const [showNotification, setShowNotification] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const initialEventState = {
    title: "",
    summary: "",
    start: "",
    end: "",
    eventEndDate: "",
    recurrenceType: "NONE",
    daysOfWeek: [],
    colorCode: "",
    eventType: "REMINDER",
  };
  const [newEvent, setNewEvent] = useState(initialEventState);

  useEffect(() => {
    dispatch(fetchColorScheme());
  }, [dispatch]);

  const onCloseModal = () => setShowModal(false);
  const onCloseDetailsModal = () => setShowDetailsModal(false);

  const toggleModal = () => {
    setShowModal(!showModal);
    setShowMenu(false);
  };

  const showEventMenu = () => setShowMenu(!showMenu);

  const showEventDetails = useCallback((id) => {
    setShowNotification(false);
    dispatch(fetchEventById(id, setEventDetails));
  });

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target) &&
      !event.target.closest("[data-notification-toggle]")
    ) {
      setShowNotification(false);
    }
  };

  const createEventModal = (type) => {
    setNewEvent({ ...initialEventState, eventType: type });
    toggleModal();
  };

  useEffect(() => {
    if (eventDetails) {
      console.log("EVENTDETAILS");
      setShowDetailsModal(true);
    }
  }, [eventDetails]);

  useEffect(() => {
    if (showNotification) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotification]);

  const toggleNotification = (event) => {
    event.stopPropagation();
    setShowNotification((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setNewEvent((prev) => ({
        ...prev,
        daysOfWeek: checked
          ? [...prev.daysOfWeek, value]
          : prev.daysOfWeek.filter((day) => day !== value),
      }));
    } else {
      setNewEvent((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let eventToCreate = { ...newEvent };

    if (eventToCreate.eventType === "DEADLINE") {
      eventToCreate.start = eventToCreate.end;
    }

    dispatch(createEvent(eventToCreate));
    setShowModal(false);
    setNewEvent(initialEventState);
  };

  const today = new Date();
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const formattedDate = today.toLocaleDateString("en-US", options);
  console.log("Notifications=========++>", notifications);
  return (
    <div className="bg-white px-4 gap-6 py-2 my-2 mx-3 hidden lg:grid grid-cols-5 grid-flow-col rounded-lg">
      <div className="col-span-3 flex items-center justify-between">
        <div>
          <h3 className="font-[600] text-[1.5rem]">
            Welcome {currentUser?.displayName}
          </h3>
          <p className="text-[#AEAEAE] text-[.9rem]">{formattedDate}</p>
        </div>
        <div>
          {splitLocation[1] === "program" ? (
            <button
              onClick={toggleModal}
              className="bg-[#5F33E1] rounded-lg py-2 text-white px-4"
            >
              + Create Project
            </button>
          ) : (
            <div ref={menuRef} style={{ position: "relative" }}>
              <button
                onClick={showEventMenu}
                className="flex bg-[#5F33E1] rounded-lg py-2 text-white px-4"
              >
                + Create Event
                <svg
                  className="-mr-1 h-5 w-5 text-gray-400 ml-2"
                  viewBox="0 0 20 20"
                  fill="white"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <div
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
                style={!showMenu ? { display: "none" } : {}}
              >
                <div className="py-1" role="none">
                  {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                  <button
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-[100%] text-left"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                    onClick={() => createEventModal("REMINDER")}
                  >
                    Reminder
                  </button>
                  <button
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-[100%] text-left"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-1"
                    onClick={() => createEventModal("DEADLINE")}
                  >
                    Deadline
                  </button>
                  <button
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-[100%] text-left"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-2"
                    onClick={() => createEventModal("SCHEDULED_EVENT")}
                  >
                    Scheduled Event
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="col-span-2 grid grid-cols-7 gap-4 items-center grid-flow-col">
        <div className="relative col-span-6">
          <input
            style={{ display: "none" }}
            className="py-3 px-12 w-full outline-none rounded-lg bg-[#FAFAFA]"
            placeholder="Search task, event, calendar"
          />
          <img
            style={{ display: "none" }}
            className="absolute w-[20px] left-[3%] top-[23%]"
            src={SearchIcon}
            alt="Search"
          />
        </div>
        <div className="col-span-1">
          <button
            className={`rounded-lg py-3 bg-[#FAFAFA] text-white px-4 relative transition-all duration-200 ${
              showNotification ? "bg-gray-200" : ""
            }`}
            onClick={toggleNotification}
            data-notification-toggle
          >
            <img
              src={NotificationIcon}
              alt="notification"
              className={`transition-transform duration-200 ${
                showNotification ? "scale-110" : ""
              }`}
            />
            {unreadNotificationCount > 0 && (
              <span className="absolute -right-[10px] -top-[5px] bg-[red] rounded-full px-1">
                {unreadNotificationCount > 99 ? "99+" : unreadNotificationCount}
              </span>
            )}
          </button>
          {showNotification && (
            <div
              ref={notificationRef}
              className="absolute right-0 mt-2 z-10 transform transition-transform duration-200 ease-in-out"
              style={{
                transformOrigin: "top right",
                animation: "slideIn 0.2s ease-out",
              }}
            >
              <Notification
                onClose={() => setShowNotification(false)}
                notifications={notifications}
                notificationPage={notificationPage}
                showEventDetails={showEventDetails}
              />
            </div>
          )}
        </div>
      </div>

      {splitLocation[1] === "program" ? (
        <CreateProjectModal
          showModal={showModal}
          setShowModal={setShowModal}
          onCloseModal={onCloseModal}
        />
      ) : (
        <Modal showModal={showModal} setShowModal={setShowModal}>
          <div className="eventModal p-8 max-w-full bg-white rounded-lg shadow-lg max-h-[90vh] overflow-y-auto">
            <div className="flex items-center justify-between mb-2">
              <img
                src={preppyDog}
                alt="preppy-dog"
                className="w-48 object-contain h-36"
              />
              <h2 className="text-xl mr-40 font-semibold text-gray-800 flex-grow text-center uppercase">
                Create {newEvent?.eventType === "REMINDER" && "Reminder"}
                {newEvent?.eventType === "DEADLINE" && "Deadline"}
                {newEvent?.eventType === "SCHEDULED_EVENT" && "Event"}
              </h2>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="w-1/2 scal-select">
                <label className="block text-gray-700 mb-1">Event Type:</label>
                <select
                  name="eventType"
                  value={newEvent.eventType}
                  onChange={handleChange}
                  className=" w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  <option value="REMINDER">Reminder</option>
                  <option value="DEADLINE">Deadline</option>
                  <option value="SCHEDULED_EVENT">Scheduled Event</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700 mb-1">Title:</label>
                <input
                  type="text"
                  name="title"
                  value={newEvent.title}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-1">Summary:</label>
                <input
                  type="text"
                  name="summary"
                  value={newEvent.summary}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {newEvent.eventType !== "DEADLINE" && (
                  <div>
                    <label className="block text-gray-700 mb-1">
                      Start Date and Time:
                    </label>
                    <DatePicker
                      selected={newEvent.start}
                      onChange={(date) =>
                        setNewEvent((prev) => ({ ...prev, start: date }))
                      }
                      minDate={new Date()}
                      showTimeSelect
                      dateFormat="Pp"
                      wrapperClassName="w-full"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                )}

                {newEvent.eventType !== "REMINDER" && (
                  <div className="flex-1">
                    <label className="block text-gray-700 mb-1">
                      {newEvent.eventType === "DEADLINE"
                        ? "Deadline Date and"
                        : "End"}{" "}
                      Time:
                    </label>
                    <DatePicker
                      selected={newEvent.end}
                      onChange={(date) =>
                        setNewEvent((prev) => ({ ...prev, end: date }))
                      }
                      minDate={
                        newEvent.eventType === "DEADLINE"
                          ? new Date()
                          : newEvent?.start || new Date()
                      }
                      minTime={addMinutes(newEvent.start, 0)}
                      maxTime={new Date(0, 0, 0, 23, 0)}
                      showTimeSelect={true}
                      showTimeSelectOnly={
                        newEvent.eventType === "SCHEDULED_EVENT" ? true : false
                      }
                      dateFormat={
                        newEvent.eventType === "SCHEDULED_EVENT"
                          ? "hh:mm aa"
                          : "Pp"
                      }
                      wrapperClassName="w-full"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                )}

                {((newEvent.eventType === "SCHEDULED_EVENT" &&
                  newEvent.recurrenceType !== "NONE") ||
                  (newEvent.eventType === "REMINDER" &&
                    newEvent.recurrenceType !== "NONE")) && (
                  <div className="flex-1">
                    <label className="block text-gray-700 mb-1">
                      Event End Date
                    </label>
                    <DatePicker
                      selected={newEvent.eventEndDate}
                      onChange={(date) =>
                        setNewEvent((prev) => ({
                          ...prev,
                          eventEndDate: date,
                        }))
                      }
                      calendarIconClassname="scal-icon"
                      minDate={newEvent?.start ? newEvent.start : new Date()}
                      showIcon={true}
                      dateFormat="Pp"
                      wrapperClassName="w-full"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </div>
                )}
                <div className="w-1/2">
                  <label className="block text-gray-700 mb-1">
                    Color Code:
                  </label>
                  <CustomColorDropdown
                    colorScheme={colorScheme}
                    newEvent={newEvent}
                    setNewEvent={setNewEvent}
                  />
                </div>
              </div>

              {newEvent.eventType !== "DEADLINE" && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700 mb-1">
                      Recurrence Type:
                    </label>
                    <select
                      name="recurrenceType"
                      value={newEvent.recurrenceType}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                      <option value="NONE">None</option>
                      <option value="DAILY">Daily</option>
                      <option value="WEEKLY">Weekly</option>
                      <option value="MONTHLY">Monthly</option>
                      <option value="YEARLY">Yearly</option>
                      <option value="DAYS_OF_WEEK">Days of Week</option>
                    </select>
                  </div>

                  {newEvent.recurrenceType === "DAYS_OF_WEEK" && (
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Select Days:
                      </label>
                      <div className="grid grid-cols-3 gap-2">
                        {daysOfWeek.map((day) => (
                          <div
                            key={day}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="checkbox"
                              value={day}
                              checked={newEvent.daysOfWeek.includes(day)}
                              onChange={handleChange}
                              className="form-checkbox h-4 w-4 text-blue-600"
                            />
                            <span>{day}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="flex justify-center mt-6">
                <button
                  type="submit"
                  className="px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                >
                  Create Event
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )}
      <DetailsModal
        showModal={showDetailsModal}
        setShowModal={setShowDetailsModal}
        onCloseModal={onCloseDetailsModal}
        event={eventDetails}
      />
    </div>
  );
};

export default TopBar;
