import React, { useState, useRef, useEffect } from "react";
import { BiPlus, BiCheck, BiX } from "react-icons/bi";
import AddLinkModal from "./AddLinkModal";

const WebLinksSection = ({ project, handleSaveLinks }) => {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showInlineAdd, setShowInlineAdd] = useState(false);
  const [newLinkUrl, setNewLinkUrl] = useState("");
  const [newLinkDescription, setNewLinkDescription] = useState("");
  const [urlError, setUrlError] = useState("");

  const urlInputRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (showInlineAdd && urlInputRef.current) {
      urlInputRef.current.focus();
    }
  }, [showInlineAdd]);

  const validateUrl = (url) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlPattern.test(url);
  };

  const handleInlineAddLink = () => {
    if (newLinkUrl.trim()) {
      if (validateUrl(newLinkUrl.trim())) {
        handleSaveLinks([
          { url: newLinkUrl.trim(), description: newLinkDescription.trim() },
        ]);
        setNewLinkUrl("");
        setNewLinkDescription("");
        setUrlError("");
        if (urlInputRef.current) {
          urlInputRef.current.focus();
        }
      } else {
        setUrlError("Please enter a valid URL");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleInlineAddLink();
    }
  };

  const handleClickOutside = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      setShowInlineAdd(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full space-y-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-bold text-gray-800">Web Links</h3>
        {!project.programId && (
          <button
            onClick={() => setShowLinkModal(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 transition duration-300 flex items-center"
          >
            <BiPlus size={20} className="mr-2" />
            <span>Add Link</span>
          </button>
        )}
      </div>
      <div className="bg-white rounded-lg shadow-md border border-gray-200">
        {showInlineAdd ? (
          <div className="p-4 bg-gray-100 border-b border-gray-200 rounded-t-lg">
            <form ref={formRef} className="flex flex-col space-y-2">
              <input
                ref={urlInputRef}
                value={newLinkUrl}
                onChange={(e) => {
                  setNewLinkUrl(e.target.value);
                  setUrlError("");
                }}
                onKeyDown={handleKeyDown}
                placeholder="Enter URL"
                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
              />
              {urlError && <p className="text-red-500 text-sm">{urlError}</p>}
              <input
                value={newLinkDescription}
                onChange={(e) => setNewLinkDescription(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Enter description (optional)"
                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm"
              />
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={handleInlineAddLink}
                  className="px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 shadow-sm"
                >
                  <BiCheck size={20} />
                </button>
                <button
                  type="button"
                  onClick={() => setShowInlineAdd(false)}
                  className="px-3 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300 shadow-sm"
                >
                  <BiX size={20} />
                </button>
              </div>
            </form>
          </div>
        ) : (
          <>
            {!project.programId && (
              <div
                className="p-4 bg-gray-50 border-b border-gray-200 rounded-t-lg flex items-center text-blue-600 hover:text-blue-800 cursor-pointer transition-colors duration-200"
                onClick={() => setShowInlineAdd(true)}
              >
                <BiPlus size={20} className="mr-2" />
                <span className="text-sm font-medium">Quick Add Link</span>
              </div>
            )}
          </>
        )}
        <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 p-4">
          {project?.webLinks && project.webLinks.length > 0 ? (
            project.webLinks.map((webLink, index) => (
              <div
                key={index}
                className="bg-white rounded shadow-md p-4 border border-gray-200"
              >
                <h3 className="text-lg font-bold mb-2">
                  {webLink.description || "No description"}
                </h3>
                <a
                  href={webLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 break-all"
                >
                  {webLink.url}
                </a>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center text-gray-500">
              No Web Links added
            </div>
          )}
        </div>
      </div>
      <AddLinkModal
        visible={showLinkModal}
        onClose={() => setShowLinkModal(false)}
        onSave={handleSaveLinks}
      />
    </div>
  );
};

export default WebLinksSection;
