import React, { useEffect, useRef, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FaUpload } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { createProject } from "../../actions/userProjectActions";
import { uploadFile } from "../../actions/chatActions";
import { message } from "antd";

export default function CreateProjectModal({
  showModal,
  setShowModal,
  onCloseModal,
  handleFile,
  fetchProjects,
  projectStatus,
}) {
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state);
  const [isUploading, setIsUploading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [projectData, setProjectData] = useState({
    projectName: "",
    projectDescription: "",
    projectStatus: "BACKLOG",
    labels: [],
    projectDate: "",
    projectImage: "",
  });

  const [filePreview, setFilePreview] = useState(null);
  const [currentTag, setCurrentTag] = useState("");

  useEffect(() => {
    if (projectStatus && projectStatus.length > 0) {
      setProjectData((prev) => ({ ...prev, projectStatus }));
    } else {
      setProjectData((prev) => ({ ...prev, projectStatus: "BACKLOG" }));
    }
  }, [projectStatus]);

  const getRanHex = (size) => {
    let result = [];
    let hexRef = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
    ];
    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join("");
  };

  const validateName = (name) => {
    if (!name.trim()) {
      setNameError("Project name is required");
      return false;
    }
    setNameError("");
    return true;
  };

  const handleCreateProject = () => {
    if (!validateName(projectData.projectName)) {
      return;
    }

    dispatch(createProject(projectData))
      .then((res) => {
        setProjectData({
          projectName: "",
          projectDescription: "",
          projectStatus: "",
          labels: [],
          projectDate: "",
          projectImage: "",
        });
        setShowModal(false);
        fetchProjects();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        const response = await dispatch(
          uploadFile(file, handleImageUploadResponse)
        );
        setFilePreview(URL.createObjectURL(file));
      } catch (error) {
        message.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleImageUploadResponse = async (response) => {
    setProjectData({
      ...projectData,
      projectImage: `/post-service/api/file-uploads/${response.id}`,
    });
  };

  const handleTagInput = (e) => {
    if (e.key === " " && currentTag.trim() !== "") {
      e.preventDefault();
      setProjectData({
        ...projectData,
        labels: [...projectData.labels, { label: currentTag.trim() }],
      });
      setCurrentTag("");
    } else if (
      e.key === "Backspace" &&
      currentTag === "" &&
      projectData.labels.length > 0
    ) {
      const newTags = [...projectData.labels];
      newTags.pop();
      setProjectData({ ...projectData, labels: newTags });
    }
  };

  const removeTag = (indexToRemove) => {
    setProjectData({
      ...projectData,
      labels: projectData.labels.filter((_, index) => index !== indexToRemove),
    });
  };

  const handleRemoveFile = () => {
    setProjectData({ ...projectData, projectImage: "" });
    setFilePreview(null);
    setIsUploading(false);
  };

  return (
    <Modal
      classNames={{ modal: "rounded-lg w-[900px]" }}
      open={showModal}
      onClose={onCloseModal}
      center
    >
      <div className="p-6">
        <h3 className="text-[2rem] font-[600] text-center mb-12">
          Create Project
        </h3>

        <div className="space-y-8">
          {/* Project Name and Tags Row */}
          <div className="flex gap-6">
            {/* Project Name */}
            <div className="flex flex-col w-1/2">
              <input
                value={projectData.projectName}
                onChange={(e) => {
                  setProjectData({
                    ...projectData,
                    projectName: e.target.value,
                  });
                  validateName(e.target.value);
                }}
                placeholder="Enter Project Name"
                className={`px-6 h-[45px] border-[1px] ${
                  nameError ? "border-red-500" : "border-gray-300"
                } rounded-lg w-full outline-none`}
              />
              {nameError && (
                <span className="text-red-500 text-sm mt-1">{nameError}</span>
              )}
            </div>

            {/* Project Tags */}
            <div className="flex flex-wrap gap-2 px-4 py-2 border-[1px] border-gray-300 rounded-lg min-h-[45px] w-1/2">
              {projectData?.labels.map((tagObj, index) => (
                <span
                  key={index}
                  className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-sm"
                >
                  {tagObj.label}
                  <button
                    onClick={() => removeTag(index)}
                    className="ml-1 text-blue-800"
                  >
                    &times;
                  </button>
                </span>
              ))}
              <input
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                onKeyDown={handleTagInput}
                placeholder={
                  projectData?.labels?.length === 0
                    ? "Enter Project Tags (Space to Add)"
                    : ""
                }
                className="flex-grow outline-none py-1"
              />
            </div>
          </div>

          {/* Status and Date */}
          <div className="flex gap-6">
            <select
              onChange={(e) =>
                setProjectData({
                  ...projectData,
                  projectStatus: e.target.value,
                })
              }
              value={projectData?.projectStatus}
              className="px-6 h-[45px] border-[1px] bg-white border-gray-300 rounded-lg w-1/2 outline-none"
            >
              <option value="BACKLOG">Backlog</option>
              <option value="TODO">To Do</option>
              <option value="INPROGRESS">In Progress</option>
              <option value="DONE">Done</option>
            </select>

            <input
              value={projectData?.projectDate}
              onChange={(e) =>
                setProjectData({
                  ...projectData,
                  projectDate: e.target.value,
                })
              }
              placeholder="Enter Project Date"
              type="date"
              className="px-6 border-[1px] h-[45px] border-gray-300 rounded-lg w-1/2 outline-none"
            />
          </div>

          {/* Description */}
          <textarea
            value={projectData.projectDescription}
            onChange={(e) =>
              setProjectData({
                ...projectData,
                projectDescription: e.target.value,
              })
            }
            placeholder="Enter Project Description"
            className="px-6 border-[1px] h-[120px] border-gray-300 rounded-lg w-full outline-none py-3"
          />

          {/* File Upload Section */}
          <div className="flex items-center gap-4">
            {(filePreview || isUploading) && (
              <div className="relative w-[50px] h-[50px]">
                {isUploading ? (
                  <div className="w-[50px] h-[50px] rounded-md bg-gray-200 animate-pulse">
                    <div className="h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 background-animate"></div>
                  </div>
                ) : (
                  <div className="relative">
                    <img
                      src={filePreview}
                      alt="File preview"
                      className="object-cover rounded-md w-[50px] h-[50px]"
                    />
                    <button
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs"
                      onClick={handleRemoveFile}
                    >
                      ×
                    </button>
                  </div>
                )}
              </div>
            )}
            <button
              onClick={handleClick}
              className="flex items-center gap-2 px-4 py-2.5 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
            >
              <FaUpload className="text-lg" />
              <span>Upload Project Image</span>
            </button>
          </div>
        </div>

        {/* Create Button */}
        <div className="mt-12 flex justify-end">
          <button
            onClick={handleCreateProject}
            disabled={isUploading}
            className="px-12 rounded-lg py-3 text-white bg-[#2098f3] hover:bg-[#1a7ac2] transition-colors disabled:bg-gray-400"
          >
            {isUploading ? "Uploading..." : "Create Project"}
          </button>
        </div>

        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          className="hidden"
          accept="audio/*, video/*, image/*"
        />
      </div>
    </Modal>
  );
}
