import React, { useRef, useState, useEffect } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  uploadProfileImage,
  updateUserAvatar,
  updateUserProfile,
  addUserRoles,
  removeUserRoles, // Add this action
} from "../../actions/userActions";
import CameraIcon from "../../assets/camera.svg";
import UserIcon from "../../assets/default-user-icon.png";
import { API_BASE_URL, ACCESS_TOKEN } from "../../constantsNew";
import AuthImage from "../../components/AuthImage";
import { patch } from "../../util/APIUtils";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);
  const hiddenFileInput = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [userRoles, setUserRoles] = useState(currentUser.userRoles);
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    avatar: "",
    gender: "",
    birthday: "",
    type: "",
    school: "",
    roles: [],
  });

  useEffect(() => {
    if (currentUser) {
      setUserProfile({
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        email: currentUser.email || "",
        phone: currentUser.phone || "",
        avatar: currentUser.avatar || "",
        gender: currentUser.gender || "",
        birthday: currentUser.birthday || "",
        type: currentUser.type || "",
        school: currentUser.school || "",
      });
    }
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    setUpdateError(null);
    setUpdateSuccess(false);
    try {
      const updatedFields = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        phone: userProfile.phone,
        gender: userProfile.gender,
        birthday: userProfile.birthday,
      };

      const response = await patch(
        `/stridecal-backend/api/app-users/${currentUser.id}`,
        updatedFields
      );

      if (response) {
        console.log("Profile updated successfully:", response);
        // Update the Redux store with the new user data
        dispatch(updateUserProfile(response));
        // Update the local state with the response data
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          ...response,
        }));
        setUpdateSuccess(true);
        setTimeout(() => setUpdateSuccess(false), 3000); // Reset after 3 seconds
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setUpdateError("Failed to update profile. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };
  const roles = [
    {
      label: "User",
      value: "ROLE_USER",
    },
    {
      label: "Student",
      value: "ROLE_STUDENT",
    },
    {
      label: "Teacher",
      value: "ROLE_TEACHER",
    },
    {
      label: "Parent",
      value: "ROLE_PARENT",
    },
    {
      label: "Organisation Admin",
      value: "ROLE_MODERATOR",
    },
    { label: "Admin", value: "ROLE_ADMIN" },
  ];
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleRoleChange = (value) => {
    console.log(value, currentUser.userRoles);
    setUserRoles(value);
    if (value.length > currentUser.userRoles.length) {
      let additionalRole = value.filter(
        (v) => !currentUser.userRoles.includes(v)
      );
      console.log(additionalRole);
      dispatch(
        addUserRoles(
          currentUser.id,
          additionalRole[0],
          setUserProfile,
          setUpdateSuccess
        )
      );
    } else {
      let roleToRemove = currentUser.userRoles.filter(
        (r) => !value.includes(r)
      );
      console.log("Role to remove", roleToRemove);
      dispatch(
        removeUserRoles(
          currentUser.id,
          roleToRemove[0],
          setUserProfile,
          setUpdateSuccess
        )
      );
    }
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        const response = await dispatch(uploadProfileImage(file));
        const avatarUrl = `/post-service/api/file-uploads/${response.id}`;
        await dispatch(updateUserAvatar(currentUser.id, avatarUrl));
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          avatar: avatarUrl,
        }));
      } catch (error) {
        console.error("Error uploading profile image:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="px-12 py-6 w-[800px] bg-white rounded-lg shadow-lg">
        <h4 className="font-[600] text-start text-[1.3rem]">
          Update Your Profile
        </h4>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="grid grid-cols-11 mt-4 mb-2 items-center justify-start gap-1">
              <h5 className="col-span-2">Personal Details</h5>
              <hr className="bg-gray-400 col-span-9 w-full" />
            </div>
            <div className="grid grid-cols-5 mt-6 gap-8 grid-flow-col">
              <div className="col-span-1 flex items-center justify-start flex-col gap-3">
                <div className="relative">
                  <AuthImage
                    imageurl={userProfile.avatar || UserIcon}
                    className="w-[70px] h-[70px] rounded-full object-cover"
                  />
                  {isUploading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                    </div>
                  )}
                  <label
                    htmlFor="avatarUpload"
                    className="absolute bottom-0 right-0 bg-gray-200 rounded-full p-1 cursor-pointer"
                  >
                    <img src={CameraIcon} alt="Upload" className="w-4 h-4" />
                  </label>
                </div>
                <p
                  className="text-[#4379EE] cursor-pointer"
                  onClick={handleClick}
                >
                  {isUploading ? "Uploading..." : "Upload Image"}
                </p>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept="image/*"
                />
              </div>
              <div className="col-span-2">
                <div className="mb-4">
                  <label className="mb-2 block">First Name</label>
                  <input
                    name="firstName"
                    value={userProfile.firstName}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="text"
                    placeholder="Enter your First Name"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Your Email</label>
                  <input
                    name="email"
                    value={userProfile.email}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="email"
                    placeholder="Enter your Email"
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Date of Birth</label>
                  <input
                    name="birthday"
                    value={userProfile.birthday}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="date"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="mb-4">
                  <label className="mb-2 block">Last Name</label>
                  <input
                    name="lastName"
                    value={userProfile.lastName}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="text"
                    placeholder="Enter your last name"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Phone Number</label>
                  <input
                    name="phone"
                    value={userProfile.phone}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                    type="tel"
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className="mb-4">
                  <label className="mb-2 block">Gender</label>
                  <select
                    name="gender"
                    value={userProfile.gender}
                    onChange={handleInputChange}
                    className="py-3 px-4 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-6">
            <div className="w-2/3">
              <label className="mb-2 block">Upgrade Role</label>
              {/* <div className="card flex justify-content-center">
                  <MultiSelect value={currentUser.userRoles} onChange={handleInputChange} options={roles} optionLabel="name" 
                        placeholder="Select Cities" maxSelectedLabels={3} className="w-full md:w-20rem" />
              </div> */}
              <Select
                options={roles}
                name="role"
                mode="multiple"
                value={userRoles}
                onChange={handleRoleChange}
                className="py-1 px-1 rounded-lg border-gray-300 border-[1px] w-full bg-gray-100"
              />
              {/* <option value="">Select role</option>
                <option value="ROLE_USER">User</option>
                <option value="ROLE_STUDENT">Student</option>
                <option value="ROLE_TEACHER">Teacher</option>
                <option value="ROLE_PARENT">Parent</option>
              </Select> */}
            </div>
            <button
              type="submit"
              className={`py-3 text-[0.8rem] px-16 rounded-lg ${
                updateSuccess
                  ? "bg-green-500"
                  : isUpdating
                  ? "bg-gray-400"
                  : "bg-[#4880FF]"
              } text-white relative`}
              disabled={isUpdating}
            >
              {isUpdating
                ? "Updating..."
                : updateSuccess
                ? "Updated!"
                : "Update Profile"}
              {updateSuccess && (
                <span className="absolute right-2 top-1/2 transform -translate-y-1/2">
                  ✓
                </span>
              )}
            </button>
          </div>
          {updateError && <p className="text-red-500 mt-2">{updateError}</p>}
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
