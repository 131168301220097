import React from 'react'
import RewardTop from '../components/Reward/RewardTop'
import RewardMiddle from '../components/Reward/RewardMiddle'
import RewardBottom from '../components/Reward/RewardBottom'

const Reward = () => {
  return (
    // <div className='grid grid-cols-5 bg-gray-100 grid-flow-col' >
    // <Sidebar />
    // <div className='col-span-4' >
    //   <TopBar />
      <div className='h-[85vh] overflow-scroll' >
      <RewardTop />
     <RewardMiddle />
     <RewardBottom />
      
     </div>
//     </div>
// </div>
  )
}

export default Reward