import React, { useState, useEffect } from "react";

import TaskArea from "../components/Dashboard/TaskArea";
import CreateProjectModal from "../components/Projects/CreateProjectModal";
import { useSelector } from "react-redux";

import { ACCESS_TOKEN, API_BASE_URL } from "../constantsNew";
const Program = () => {
  const [showModal, setShowModal] = useState(false);
  // const dispatch = useDispatch()
  // const [projectsList, setProjectsList] = useState([])
  const [backlogList, setBacklogList] = useState([]);
  const [doneList, setDoneList] = useState([]);
  const [inProgressList, setInProgressList] = useState([]);
  const [toDoList, setToDoList] = useState([]);
  const [projectStatus, setProjectStatus] = useState("BACKLOG");
  const { users } = useSelector((state) => state);
  // const currentUser = users?.currentUser;

  // const getRanHex = size => {
  //   let result = [];
  //   let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

  //   for (let n = 0; n < size; n++) {
  //     result.push(hexRef[Math.floor(Math.random() * 16)]);
  //   }
  //   return result.join('');
  // }

  const fetchProjects = () => {
    setBacklogList([]);
    setInProgressList([]);
    setToDoList([]);
    setDoneList([]);

    const headers = new Headers({
      "Content-Type": "application/json",
    });
    if (localStorage.getItem(ACCESS_TOKEN)) {
      headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      );
    }

    // Post to backend
    fetch(API_BASE_URL + `/userproject-service/api/user-projects`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        data?.content?.map((item) => {
          if (item?.projectStatus === "TODO") {
            setToDoList((prev) => [...prev, item]);
          } else if (item?.projectStatus === "BACKLOG") {
            console.log(backlogList, item);
            setBacklogList((prev) => [...prev, item]);
          } else if (item?.projectStatus === "INPROGRESS") {
            setInProgressList((prev) => [...prev, item]);
          } else if (item?.projectStatus === "DONE") {
            setDoneList((prev) => [...prev, item]);
          }
        });
        console.log(data);
      })
      .catch((error) => {
        console.error("Error saving post:", error);
      });
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div className=" overflow-auto" style={{ height: "calc(100vh - 100px)" }}>
      <TaskArea
        fetchProjects={fetchProjects}
        backlogList={backlogList}
        inProgressList={inProgressList}
        toDoList={toDoList}
        reviewList={doneList}
        showModal={showModal}
        setShowModal={setShowModal}
        setProjectStatus={setProjectStatus}
      />
      <CreateProjectModal
        showModal={showModal}
        setShowModal={setShowModal}
        onCloseModal={onCloseModal}
        fetchProjects={fetchProjects}
        projectStatus={projectStatus}
      />
    </div>
  );
};

export default Program;
