// export const API_BASE_URL_ = "https://api.stridecal.com";
// export const WEBSOCKET_BASE_URL_W_ = "https://ws.stridecal.com";
// export const CHAT_API_BASE_URL_ = "https://api.stridecal.com/chat-service";
// export const WEBSOCKET_BASE_URL_ = "ws.stridecal.com";
export const CMS_BASE_URL =
  window._env_?.REACT_APP_CMS_BASE_URL || process.env.REACT_APP_CMS_BASE_URL;

// For local testing use the below endpoints by removing underscore and commenting the above endpoints
export const API_BASE_URL =
  window._env_?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL;
export const WEBSOCKET_BASE_URL_W =
  window._env_?.REACT_APP_WEBSOCKET_BASE_URL_W ||
  process.env.REACT_APP_WEBSOCKET_BASE_URL_W;
export const CHAT_API_BASE_URL =
  window._env_?.REACT_APP_CHAT_API_BASE_URL ||
  process.env.REACT_APP_CHAT_API_BASE_URL;
export const WEBSOCKET_BASE_URL =
  window._env_?.REACT_APP_WEBSOCKET_BASE_URL ||
  process.env.REACT_APP_WEBSOCKET_BASE_URL;

export const ACCESS_TOKEN = "accessToken";
export const WEBSOCKET_URL = WEBSOCKET_BASE_URL_W + "/websocket";
export const WEBSOCKET_URL_WS = "ws://" + WEBSOCKET_BASE_URL + "/websocket";
export const USER = "user";
export const OAUTH2_REDIRECT_URI =
  window._env_?.REACT_APP_OAUTH2_REDIRECT_URI ||
  process.env.REACT_APP_OAUTH2_REDIRECT_URI;

export const GOOGLE_AUTH_URL =
  API_BASE_URL +
  "/stridecal-backend/oauth2/authorization/google?redirect_uri=" +
  OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  API_BASE_URL +
  "/stridecal-backend/oauth2/authorization/facebook?redirect_uri=" +
  OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
  API_BASE_URL +
  "/stridecal-backend/oauth2/authorization/github?redirect_uri=" +
  OAUTH2_REDIRECT_URI;

export const EVENT_TYPES = {
  REMINDER: "Reminder",
  DEADLINE: "Deadline",
  SCHEDULED_EVENT: "Routine",
};

export const RECURRENCE_TYPES = {
  NONE: "No Repeat",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  DAYS_OF_WEEK: "Days of Week",
};
