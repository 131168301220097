import React, { useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import PreppyDog from "../../assets/preppyDog.png"
import { Link } from "react-router-dom";
import { FaUpload } from "react-icons/fa6";
export default function CreateScheduleModal({showModal,onCloseModal,scheduleData,setScheduleData,handleSchedule}) {
  
  return (
    <>
      
      <Modal classNames={{modal:"rounded-lg w-[900px] "}}  open={showModal} onClose={onCloseModal} center>
          <div className="flex items-center justify-center flex-col" >
           <h3 className="text-[2rem] font-[600]" >Create Project</h3>
            {/* <div className="flex items-center mb-8 justify-center gap-6" >
              <Link to="/onboarding" ><button  className="bg-[#4880FF] outline-none border-none text-white rounded-lg px-8 py-3" >Complete my profile</button></Link>  
                <button  onClick={onCloseModal} className="outline-none border-none px-8 py-3" >Thanks, I’ll do it later</button>
            </div> */}
            <div className="flex items-center gap-6 mb-3 mt-12 justify-between " >
                <div>
                <input value={scheduleData.name} onChange={(e)=>setScheduleData({...scheduleData,name:e.target.value})} placeholder="Enter Project Name" className="px-6 h-[40px] border-[1px]  border-gray-300 rounded-lg w-[350px] outline-none py-3" />
                </div>
                
            </div>
            
            <div className="flex items-center gap-6 my-3 justify-between " >
               <textarea value={scheduleData.content} onChange={(e)=>setScheduleData({...scheduleData,content:e.target.value})} placeholder="Enter Project Description" className="px-6 border-[1px] h-[120px] border-gray-300 rounded-lg w-[730px] outline-none py-3" />
            </div>
           
           
          </div>
          <div className="flex items-center justify-end gap-4">
              <button className="px-8 rounded-lg  py-3 bg-gray-200" onClick={onCloseModal} >Cancel</button>
                <button onClick={handleSchedule} className="px-8 rounded-lg  py-3 text-white bg-[#2098f3]" >Create</button>
            </div>
        </Modal>
    </>
  );
}