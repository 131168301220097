import React, { useEffect, useState } from "react";
import { get } from "../../util/APIUtils";
import BadgeIcon from "../../assets/badge.png";
import StarIcon from "../../assets/star.png";
import MedalIcon from "../../assets/medal.png";
import CrownIcon from "../../assets/crown.png";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-moment";
import { useDispatch } from "react-redux";
import { fetchRewards } from "../../actions/dashboardActions";
import { Divider, Tooltip as tp } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const DashboardTop = ({ activeTab, setActiveTab }) => {
  const [loading, setLoading] = useState(false);
  const [rewardLoading, setRewardLoading] = useState(false);
  const [rewards, setRewards] = useState();
  const [summaryData, setSummaryData] = useState([]);
  const [timeRange, setTimeRange] = useState("DAY");
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useDispatch();

  const fetchProjectSummary = async (range) => {
    setLoading(true);
    const response = await get(
      `/userproject-service/api/user-projects/projectSummaries?type=${range}`
    );
    setSummaryData(response);
    setLoading(false);
  };

  useEffect(() => {
    dispatch(fetchRewards({ setRewards, setRewardLoading }));
  }, []);
  useEffect(() => {
    fetchProjectSummary(timeRange);
  }, [timeRange]);

  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  const calculateTotals = () => {
    const stars = rewards?.stars || 0;
    const badges = rewards?.badge || 0;
    const crowns = rewards?.crown || 0;
    const medals = rewards?.medal || 0;

    const totalStars = stars + badges * 10 + crowns * 100 + medals * 1000;
    const totalBadges = badges + crowns * 10 + medals * 100;
    const totalCrowns = crowns + medals * 10;

    return { totalStars, totalBadges, totalCrowns, medals };
  };

  const totals = calculateTotals();

  const chartData = {
    labels: summaryData.map((item) => new Date(item.createdDate)),
    datasets: [
      {
        label: "Active Projects",
        data: summaryData.map((item) => item.active),
        fill: false,
        borderColor: "#4bc0c0",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Completed Projects",
        data: summaryData.map((item) => item.completed),
        fill: false,
        borderColor: "#c04b4b",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Remaining Projects",
        data: summaryData.map((item) => item.remaining),
        fill: false,
        borderColor: "#F3BB4B",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Total Projects",
        data: summaryData.map((item) => item.total),
        fill: false,
        borderColor: "#DF43AA",
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const getTimeUnit = () => {
    if (timeRange === "DAY") return "hour";
    if (timeRange === "WEEK") return "day";
    return "day";
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#000000",
        },
      },
      title: {
        display: true,
        text: `Projects Summary (${timeRange})`,
        color: "#000000",
        align: "start",
        font: {
          size: 20,
          weight: "bold",
          family: "'Helvetica Neue', 'Arial', sans-serif",
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const data = summaryData[tooltipItem.dataIndex];
            return [
              `Time: ${tooltipItem.label}`,
              `Active: ${data.active}`,
              `Completed: ${data.completed}`,
              `Remaining: ${data.remaining}`,
              `Total: ${data.total}`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: getTimeUnit(),
          displayFormats: {
            hour: "h:mm a",
            day: "MMM D",
          },
        },
        ticks: {
          color: "#000000",
          padding: 5,
        },
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: "#000000",
        },
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
  };

  return (
    <div className="grid grid-cols-7 grid-flow-col">
      <div className="col-span-5 p-5 mx-1.5 rounded-lg bg-white items-center justify-center">
        <div className="flex justify-end space-x-3 mb-2">
          <button
            className={`py-1 px-3 rounded-lg ${
              timeRange === "DAY" ? "bg-purple-600 text-white" : "bg-gray-200"
            }`}
            onClick={() => handleTimeRangeChange("DAY")}
          >
            Day
          </button>
          <button
            className={`py-1 px-3 rounded-lg ${
              timeRange === "WEEK" ? "bg-purple-600 text-white" : "bg-gray-200"
            }`}
            onClick={() => handleTimeRangeChange("WEEK")}
          >
            Week
          </button>
          <button
            className={`py-1 px-3 rounded-lg ${
              timeRange === "MONTH" ? "bg-purple-600 text-white" : "bg-gray-200"
            }`}
            onClick={() => handleTimeRangeChange("MONTH")}
          >
            Month
          </button>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Line data={chartData} options={options} height={80} />
        )}
      </div>
      <div className="col-span-2 bg-white mx-1.5 rounded-lg py-3 px-6 shadow-lg">
        <div className="flex items-center justify-between">
          <h3 className="text-[1.5rem] font-semibold text-gray-800">
            Rewards 🏆
          </h3>
          <tp title={"Help"}>
            <button
              className="text-gray-700 cursor-pointer text-[1.2rem] bg-transparent border-gray-500 border-2 rounded-full w-10 h-10 flex items-center justify-center"
              onClick={() => setShowHelp((prev) => !prev)}
            >
              ?
            </button>
          </tp>
        </div>

        <div className="flex flex-col my-3 gap-4">
          {showHelp ? (
            <div className="p-3 bg-yellow-100 rounded-lg">
              <p className="text-gray-800 text-lg font-medium">
                Here’s How Your Rewards Add Up!
              </p>
              <p className="text-gray-700 mt-2 flex items-start gap-2">
                <img src={StarIcon} alt="Star" className="w-6 h-6" />
                <strong>Total Stars:</strong> You have {totals.totalStars}{" "}
                stars, which includes stars converted to badges, crowns, and
                medals!
              </p>
              <Divider className="mt-0 mb-1" />
              <p className="text-gray-700 flex items-start gap-2">
                <img src={BadgeIcon} alt="Badge" className="w-6 h-6" />
                <strong>Total Badges:</strong> You’ve earned{" "}
                {totals.totalBadges} badges, counting both direct badges and
                those converted to crowns and medals.
              </p>
              <Divider className="mt-0 mb-1" />
              <p className="text-gray-700 flex items-start gap-2">
                <img src={CrownIcon} alt="Crown" className="w-6 h-6" />
                <strong>Total Crowns:</strong> You’ve gathered{" "}
                {totals.totalCrowns} crowns including those converted to medals.
              </p>
              <Divider className="mt-0 mb-1" />
              <p className="text-gray-700 flex items-start gap-2">
                <img src={MedalIcon} alt="Medal" className="w-6 h-6" />
                <strong>Total Medals:</strong> You have {totals.medals} medals
                in total!
              </p>
              <Divider className="mt-0 mb-1" />
              <p className="text-gray-600">
                ***For every 1 Task completed 1 Star is rewarded, 10 Stars = 1
                Badge, 10 Badges = 1 Crown, 10 Crowns = 1 Medal!
              </p>
            </div>
          ) : (
            rewards && (
              <>
                <div className="flex items-center gap-3 p-3 bg-gray-100 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
                  <img
                    src={MedalIcon}
                    alt="Medal Icon"
                    className="w-8 h-8"
                    style={{
                      filter: rewards.medal > 0 ? "none" : "grayscale(100%)",
                    }}
                  />
                  <span className="text-gray-700 text-lg font-medium">
                    {rewards.medal > 0 ? `${rewards.medal} Medals` : "0 Medals"}
                  </span>
                </div>
                <div className="flex items-center gap-3 p-3 bg-gray-100 rounded-lg shadow-sm  hover:shadow-md transition-shadow duration-200">
                  <img
                    src={CrownIcon}
                    alt="Crown Icon"
                    className="w-8 h-8"
                    style={{
                      filter: rewards.crown > 0 ? "none" : "grayscale(100%)",
                    }}
                  />
                  <span className="text-gray-700 text-lg font-medium">
                    {rewards.crown > 0 ? `${rewards.crown} Crowns` : "0 Crowns"}
                  </span>
                </div>
                <div className="flex items-center gap-3 p-3 bg-gray-100 rounded-lg shadow-sm  hover:shadow-md transition-shadow duration-200">
                  <img
                    src={BadgeIcon}
                    alt="Badge Icon"
                    className="w-8 h-8"
                    style={{
                      filter: rewards.badge > 0 ? "none" : "grayscale(100%)",
                    }}
                  />
                  <span className="text-gray-700 text-lg font-medium">
                    {rewards.badge > 0 ? `${rewards.badge} Badges` : "0 Badges"}
                  </span>
                </div>
                <div className="flex items-center gap-3 p-3 bg-gray-100 rounded-lg shadow-sm  hover:shadow-md transition-shadow duration-200">
                  <img
                    src={StarIcon}
                    alt="Star Icon"
                    className="w-8 h-8"
                    style={{
                      filter: rewards.stars > 0 ? "none" : "grayscale(100%)",
                    }}
                  />
                  <span className="text-gray-700 text-lg font-medium">
                    {rewards.stars > 0 ? `${rewards.stars} Stars` : "0 Stars"}
                  </span>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTop;
