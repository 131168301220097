import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Radio, Select, Spin } from "antd";
import { uploadFile } from "../../actions/academicProgramActions";
import { useDispatch } from "react-redux";
import AuthImage from "../AuthImage/AuthImage";
import preppyDog from "../../assets/preppyDog.png";

const AddTodoModal = ({ visible, onClose, onSave, initialValues = null }) => {
  const [form] = Form.useForm();
  const [isValid, setIsValid] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();

  const isEditMode = !!initialValues;

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
        dueDate: initialValues.dueDate
          ? initialValues.dueDate.split("T")[0]
          : null,
      });
      setFileUrl({ fileUrl: initialValues.fileUrl });
      setIsValid(true);
    } else {
      handleReset();
    }
  }, [initialValues, form]);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        await dispatch(uploadFile(file, setFileUrl));
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave({
        ...values,
        dueDate: values.dueDate ? new Date(values.dueDate).toISOString() : null,
        fileUrl: fileUrl?.fileUrl,
        isCompleted: false,
        id: initialValues?.id,
      });
      handleReset();
      onClose();
    });
  };

  const handleFormChange = () => {
    const hasErrors = form
      .getFieldsError()
      .some(({ errors }) => errors.length > 0);
    const hasValues = form.getFieldsValue(["title"]).title;

    setIsValid(!hasErrors && hasValues);
  };

  const handleReset = () => {
    form.resetFields();
    setFileUrl(null);
    setIsValid(false);
  };

  const handleCancel = () => {
    handleReset();
    onClose();
  };

  return (
    <Modal
      title={
        <div className="flex items-center">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4 flex items-center justify-center">
            <img
              src={preppyDog}
              alt="preppy-dog"
              className="w-36 object-contain h-24 mr-4"
            />
            <span className="flex-grow text-center mr-52">
              {isEditMode ? "Edit Todo" : "Add Todo"}
            </span>
          </h3>
        </div>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          disabled={!isValid}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "60vh", overflow: "auto" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={handleFormChange}
        initialValues={{
          todoType: "TASK",
          priority: "MEDIUM",
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <div className="flex space-x-4">
          <Form.Item name="todoType" label="Todo Type" className="flex-1">
            <Radio.Group>
              <Radio value="TASK">Task</Radio>
              <Radio value="ASSIGNMENT">Assignment</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="dueDate" label="Due Date" className="flex-1">
            <Input type="date" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex space-x-4">
          <Form.Item name="priority" label="Priority" className="flex-1">
            <Select>
              <Select.Option value="HIGHEST">Highest</Select.Option>
              <Select.Option value="HIGH">High</Select.Option>
              <Select.Option value="MEDIUM">Medium</Select.Option>
              <Select.Option value="LOW">Low</Select.Option>
            </Select>
          </Form.Item>
          <div className="flex-1">
            <Form.Item label="Task image">
              <input
                type="file"
                className="block w-full text-sm text-slate-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-violet-50 file:text-violet-700
                      hover:file:bg-violet-100"
                onChange={handleUpload}
                accept="image/*"
              />
            </Form.Item>
            <div className="mt-2 h-16 w-16 relative">
              {isUploading ? (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded">
                  <Spin size="small" />
                </div>
              ) : fileUrl ? (
                <AuthImage
                  imageurl={fileUrl?.fileUrl}
                  className="w-full h-full object-cover rounded"
                />
              ) : null}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default AddTodoModal;
