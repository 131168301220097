import React from 'react'
import { Link } from 'react-router-dom'
import {  EyeIcon,EyeSlashIcon, PhoneIcon } from '@heroicons/react/24/outline';
const CreateAccountForm = ({name,setName,email,setEmail,setPassword,password,handleRegister,showPassword,setShowPassword}) => {
  return (
    <div className='bg-white w-[600px] rounded-[20px] px-12 py-[2.5rem]' >
        <div>
            <h3 className='text-[32px] font-[600]' >Create an Account</h3>
            <p className='my-1 text-gray-500 text-[18px]' >Create a account to continue</p>
        </div>
        <div className='mt-6' >
        <div className='flex items-start mt-4 justify-start flex-col' > 
                <label className='mb-3' >Username:</label>
                <input value={name} onChange={(e)=>setName(e.target.value)} className='bg-[#F1F4F9] rounded-[8px] w-full border-[1px] border-[#D8D8D8] outline-none py-3 px-4' placeholder='Username' />
            </div>
            <div className='flex items-start mt-4 justify-start flex-col' > 
                <label className='mb-3' >Email address:</label>
                <input value={email} onChange={(e)=>setEmail(e.target.value)} className='bg-[#F1F4F9] rounded-[8px] w-full border-[1px] outline-none border-[#D8D8D8] py-3 px-4' placeholder='esteban_schiller@gmail.com' />
            </div>
          
            <div className='flex items-start mt-4 justify-start flex-col' > 
            <div className='flex items-center w-full justify-between mb-3' >
                <p className='' >Password:</p>
                
                </div>
                <div className='relative w-full' >
                <input type={showPassword ? "text" : "password"} value={password} onChange={(e)=>setPassword(e.target.value)}  className='bg-[#F1F4F9] rounded-[8px] w-full border-[1px] outline-none border-[#D8D8D8] py-3 px-4' placeholder='******' />
                <button
        
       
        disabled={!password}
        onClick={()=>setShowPassword(!showPassword)}
        className="!absolute   right-2 top-4 "
      >
        {showPassword ? <EyeIcon className='w-[15px]  text-blue-500' /> :<EyeSlashIcon className='w-[15px] text-blue-500' />}
      </button>
                </div>
            </div>
        </div>
        <div className='mt-12' >
            <button onClick={handleRegister} className='bg-[#4880FF] py-3 text-white rounded-[8px] w-[100%]' >Sign Up</button>
            <p className='mt-3' >Already have a Account? <Link to="/login" > <span className='text-[#4880FF] font-[500] underline' >Login</span> </Link>  </p>
        </div>
    </div>
  )
}

export default CreateAccountForm