import { get } from "../util/APIUtils";


export const fetchRewards =  ({setRewardLoading,setRewards}) => async (dispatch)=>{
    setRewardLoading(true);
    const response = await get(
      `/reward-service/api/v1/rewards/myRewards`
    );
    setRewardLoading(false);
    setRewards(response);
  };