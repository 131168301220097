import React from 'react'
import RewardMiddleLeft from "../../assets/rewardMiddleLeft.png"
import TrophyIcon from "../../assets/trophy.png"
import { Progress } from '@material-tailwind/react'
const RewardMiddle = () => {
  return (
    <div className='grid mx-3 my-3 grid-cols-5 grid-flow-col' >
            <div style={{backgroundImage:`url(${RewardMiddleLeft})`}} className='col-span-2 gap-4 grid grid-cols-3 grid-flow-col bg-center bg-no-repeat bg-cover rounded-lg pt-3 px-3' >
                <div className='col-span-2 mt-4' >
                    <h3 className='font-[600] text-[1.1rem] text-[#7743DB]' >Become the Star performer of the Month to get 2500 points</h3>
                    {/* <Progress className="mt-8 " size="lg" color="purple"  value={80} /> */}
                    <div class="w-[75%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div class="bg-blue-600 h-2.5 rounded-full" style={{width: `${6/12*100}%`}}></div>
                    </div>
                    <p className='text-[#8353DC] text-[.8rem] mt-2' >12 more tasks to finish to become the star performer.</p>
                </div>
                <div>
                    <img src={TrophyIcon} />
                </div>
            </div>
            <div className='bg-[#D69400] mx-2 col-span-3 rounded-lg py-3 px-6' >
                <div className='flex items-center justify-between' >
                    <h3 className='text-white text-[1.3rem] font-[500]' >Complete 8 more tasks to get another 1000 points</h3>
                    <div className='bg-[#fff3d150] text-[.8rem] text-white py-2 px-3 rounded-xl' >Redeem Points</div>

                </div>
                <div className='grid my-2 gap-8 grid-cols-5' >
                    <div className='col-span-4' >
                    {/* <Progress className="mt-8 " size="lg" color="white"  value={80} /> */}
                    <div class="w-[75%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div class="bg-blue-600 h-2.5 rounded-full" style={{width: `${4/12*100}%`}}></div>
                    </div>
                    <p className='text-[1rem] mt-3 text-white '>You’ve completed 4 tasks</p>
                    </div>
                    <div className='col-span-1 flex items-center justify-center flex-col rounded-lg bg-[#fff3d150] py-3 px-4'>
                    <p className='text-[2rem] text-white ' >5467</p>
                    <p className='text-[.8rem] text-white '>Total Points</p>
                     </div>
                </div>
            </div>
    </div>
  )
}

export default RewardMiddle