import { useEffect, useState } from "react";
import { fetchEvent } from "../../actions/calendarActions";
import { useDispatch } from "react-redux";
import moment  from "moment";
const CalendarEvent = (props)=>{
    const dispatch = useDispatch();
    
    const {event,eventId,...restProps} = props;
    console.log("EventId",eventId)
    const [calEvent,setCalEvent] = useState();
    // const [event,setEvent]=useState();
    // const [loading,setLoading]=useState(false);
    // useEffect(()=>{
    //   if(eventLink){
    //     dispatch(fetchEvent(eventLink,setEvent,true))
    //   }
    // },[eventLink])
    useEffect(()=>{
        if(event){
            setCalEvent(event);
        }
    },[event])
    useEffect(()=>{
        if(eventId){
            
            dispatch(fetchEvent(`/calendar-service/api/calendar/events/${eventId}`,setCalEvent,false))
        }
    },[eventId])



    return <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" {...restProps}>
        
        <>
            {calEvent && <>
                    <div className="font-semibold">{calEvent.title}</div>
                    <div>{calEvent.summary?event.summary:''}</div>
                    <div>{calEvent.eventType?event.eventType:''}</div>
                    <div>{calEvent.startTime? moment(calEvent.startTime,"YYYY-MM-DD HH:mm:aa").format("LLLL "):''} - {calEvent.endTime?moment(calEvent.endTime,"YYYY-MM-DD HH:mm:aa").format("LLLL "):''}</div>
                </>}
        </>
        
    </div>
}

export default CalendarEvent;