import { useSelector } from "react-redux";

import { CLEAR_ERRORS,TRANSCRIPTION_JOB_COMPLETED,FETCH_TRANSCRIPTION_JOB_STATUS} from "../constants/userConstants"
// import { CHANGE_BOT_SUCCESS } from "../constants/botConstants";

const initialState = {
    transcriptionJobStatus: undefined,
  };

export const websocketMessageReducer = (state = initialState, action) => {

    switch (action.type) {
      case FETCH_TRANSCRIPTION_JOB_STATUS:

        return {
            ...state,
            transcriptionJobStatus:action.payload,
          
        }
    case TRANSCRIPTION_JOB_COMPLETED:

        return {
            ...state,
            transcriptionJobStatus: undefined,
          
        }
      default:
        return state;
    }
  };