import React from "react";
import DashboardTop from "./DashboardTop";
import BottomLeft from "./BottomLeft";
import BottomRight from "./BottomRight";
import { ParentDashboard } from "./ParentDashboard";

const DashboardArea = ({ activeTab, setActiveTab }) => {
  const parentView = false;
  return (
    <div
      className="mx-3 overflow-auto"
      style={{ height: "calc(100vh - 90px)" }}
    >
      {parentView ? (
        <>
          <ParentDashboard />
        </>
      ) : (
        <>
          <DashboardTop activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className="grid grid-cols-2 my-3  grid-flow-col gap-2">
            <BottomLeft />
            <BottomRight />
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardArea;
