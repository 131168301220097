import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Alert } from "antd";
import { fetchTimezones } from "../../actions/userActions";
import { put, post } from "../../util/APIUtils";

const TimezoneSettings = () => {
  const dispatch = useDispatch();
  const timezones = useSelector((state) => state.users.timezones);
  const loading = useSelector((state) => state.users.loadingTimezones);
  const error = useSelector((state) => state.users.error);
  const currentUser = useSelector((state) => state.users.currentUser);
  const userTimezone = useSelector((state) => state.users.userTimezone);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  useEffect(() => {
    dispatch(fetchTimezones());
  }, [dispatch]);

  const handleTimezoneChange = async (value) => {
    await updateUserTimezone(value);
  };

  const updateUserTimezone = async (selectedTimezone) => {
    try {
      const payload = {
        id: userTimezone ? userTimezone.id : null,
        userName: currentUser.userName,
        timeZone: selectedTimezone,
      };

      let response;
      if (userTimezone) {
        response = await put(
          `/calendar-service/api/calendar/userTimezones/${userTimezone.id}`,
          payload
        );
      } else {
        response = await post(
          "/calendar-service/api/calendar/userTimezones",
          payload
        );
      }

      if (response) {
        setAlertMessage("Timezone updated successfully!");
        setAlertType("success");
        dispatch({ type: "SET_USER_TIMEZONE", payload: response });
      } else {
        throw new Error("Failed to update timezone");
      }
    } catch (error) {
      console.error("Error updating timezone:", error);
      setAlertMessage("Failed to update timezone. Please try again.");
      setAlertType("error");
    }
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  // Filter function for the search
  const filterOption = (input, option) => {
    const searchText = input.toLowerCase();
    const timezone = option.value.toLowerCase();

    const containsSearch = timezone.includes(searchText);
    const matchesCity = timezone
      .split("/")[1]
      ?.toLowerCase()
      .replace("_", " ")
      .includes(searchText);

    return containsSearch || matchesCity;
  };

  return (
    <div className="flex items-center justify-center">
      <div className="px-12 py-6 w-[600px] bg-white rounded-lg shadow-lg">
        <h4 className="font-semibold text-start text-lg">Timezone Settings</h4>
        <p className="mt-4">
          Configure your preferred timezone below by selecting from the list or
          type to search.
        </p>

        <div className="mt-6">
          <label className="mb-2 block">Select Timezone</label>
          <Select
            value={userTimezone ? userTimezone.timeZone : "UTC"}
            onChange={handleTimezoneChange}
            loading={loading}
            style={{ width: "100%" }}
            showSearch
            filterOption={filterOption}
            placeholder="Search or select a timezone"
            optionFilterProp="children"
          >
            {timezones.map((tz) => (
              <Select.Option key={tz} value={tz}>
                {tz.replace("_", " ")}
              </Select.Option>
            ))}
          </Select>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>

        {showAlert && (
          <Alert
            message={alertMessage}
            type={alertType}
            showIcon
            style={{ marginTop: "16px" }}
          />
        )}
      </div>
    </div>
  );
};

export default TimezoneSettings;
