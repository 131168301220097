import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, ButtonGroup } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import moment from "moment";
import "./DateTimeArea.css";

const DateBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  // border-bottom: 1px solid #e0e0e0;
  padding: 2px;
`;

const DateDisplay = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const DateItemsContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const DateItem = styled.div`
  text-align: center;
  cursor: pointer;
  flex: 1;
`;

const WeekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const getCurrentWeek = (currentDate) => {
  const currentWeek = [];
  const firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
  for (let i = 0; i < 7; i++) {
    const date = new Date(currentDate.setDate(firstDayOfWeek + i));
    currentWeek.push({
      day: WeekDays[i],
      date: date.getDate(),
      isToday: date.toDateString() === new Date().toDateString(),
    });
  }
  return currentWeek;
};

export const CalendarTabRenderer = ({
  currentDate,
  view,
  setView,
  handleDateChange,
  handleButtonClick,
}) => {
  const [time, setTime] = useState(moment().format("hh:mm:ss A"));
  const currentWeek = getCurrentWeek(new Date(currentDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(moment().format("hh:mm:ss A"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getDayStyle = (isToday) => ({
    borderBottom: isToday ? "2px solid #577CFF" : "0.5px solid #9F9F9F",
    backgroundColor: "transparent",
    color: isToday ? "#577CFF" : "#9F9F9F",
    marginBottom: "0.5em",
  });

  const getDateStyle = (isToday) => ({
    backgroundColor: isToday ? "#5f33e1" : "transparent",
    color: isToday ? "#ffffff" : "#9F9F9F",
    borderRadius: isToday ? "1rem" : "none",
    marginTop: "0.5em",
    paddingTop: "0.7rem",
    paddingBottom: isToday ? "0.7rem" : "0.5rem",
    marginLeft: isToday ? "0.5rem" : "none",
    marginRight: isToday ? "0.5rem" : "none",
  });

  return (
    <DateBarContainer>
      <DateDisplay>
        {moment(currentDate).format("YYYY MMMM")}
        <div style={{ color: "#7B7B7B", fontSize: "13px", fontWeight: "400" }}>
          {time}
        </div>
      </DateDisplay>
      <DateItemsContainer>
        <ButtonGroup>
          <Button onClick={() => handleDateChange("prev")}>
            <ChevronLeft />
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            className="m-3 p-2"
            onClick={() => {
              handleButtonClick("timeGridDay");
              setView("timeGridDay");
            }}
            style={
              view === "timeGridDay"
                ? { borderBottom: "3px solid #577CFF" }
                : {}
            }
          >
            Day
          </Button>
          <Button
            className="m-3 p-2"
            onClick={() => {
              handleButtonClick("timeGridWeek");
              setView("timeGridWeek");
            }}
            style={
              view === "timeGridWeek"
                ? { borderBottom: "3px solid #577CFF" }
                : {}
            }
          >
            Week
          </Button>

          <Button
            className="m-3 p-2"
            onClick={() => {
              handleButtonClick("dayGridMonth");
              setView("dayGridMonth");
            }}
            style={
              view === "dayGridMonth"
                ? { borderBottom: "3px solid #577CFF" }
                : {}
            }
          >
            Month
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={() => handleDateChange("next")}>
            <ChevronRight />
          </Button>
        </ButtonGroup>
      </DateItemsContainer>
      {view === "timeGridWeek" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "24px",
            marginRight: "10px",
          }}
        >
          {currentWeek.map((day, index) => (
            <DateItem key={index} isToday={day.isToday}>
              <div style={getDayStyle(day.isToday)}>{day.day}</div>
              <div style={getDateStyle(day.isToday)}>{day.date}</div>
            </DateItem>
          ))}
        </div>
      )}
      {view === "timeGridDay" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            color: "#9F9F9F",
            borderRadius: "1rem",
          }}
        >
          {moment(currentDate).format("ddd, Do MMM YYYY")}
        </div>
      )}
      {view === "dayGridMonth" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            color: "#9F9F9F",
            borderRadius: "1rem",
          }}
        >
          {moment(currentDate).format("MMM YYYY")}
        </div>
      )}
    </DateBarContainer>
  );
};
