import React, { useState } from "react";
import { User, Globe, Shield, Bell, Lock } from "lucide-react";
import Card from "./Card";
import Approvals from "./Approvals";
import UserManagement from "./UserManagement";

const Administration = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cards = [
    {
      title: "Approvals",
      component: Approvals,
      icon: User,
      description: "Manage your personal information",
    },
    {
      title: "User Management",
      component: UserManagement,
      icon: Globe,
      description: "Set your preferred timezone",
    },
    // {
    //   title: "Security Settings",
    //   component: SecuritySettings,
    //   icon: Shield,
    //   description: "Configure account security options",
    // },
    // {
    //   title: "Notification Preferences",
    //   component: NotificationPreferences,
    //   icon: Bell,
    //   description: "Customize your notification settings",
    // },
    // {
    //   title: "Privacy Settings",
    //   component: PrivacySettings,
    //   icon: Lock,
    //   description: "Control your privacy preferences",
    // },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {selectedCard ? (
        <div className="w-full">
          <button
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setSelectedCard(null)}
          >
            Back to Administration
          </button>
          <selectedCard.component />
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold mb-6 text-center">
            Administration
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {cards.map((card, index) => (
              <Card
                key={index}
                title={card.title}
                icon={card.icon}
                description={card.description}
                onClick={() => setSelectedCard(card)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Administration;
