import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import AuthImage from "../AuthImage/AuthImage";

const DragItem = ({ item, setIsDragged }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "item",
    item: item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  useEffect(() => {
    if (isDragging) {
      setIsDragged(true);
    } else if (!isDragging) {
      setIsDragged(false);
    }
  }, [isDragging]);

  return (
    <div
      ref={drag}
      className="bg-white px-4 my-2 py-3 rounded-lg"
      style={{
        opacity: (isDragging || item?.disabled) ? 0.5 : 1,
      }}
    >
      <div className="flex flex-wrap gap-2">
        {item?.labels &&
          item.labels.map((label, i) => (
            <button
              key={`projectLabel-${item.id}-${i}`}
              className="py-1 flex items-center justify-start bg-[#1A9AA2] px-3 rounded-md text-[.8rem] text-white"
              style={
                label.colorCode ? { backgroundColor: label.colorCode } : {}
              }
            >
              {label.label}
            </button>
          ))}
      </div>
      {item?.projectImage && (
        <span className="flex my-6 w-full">
          <AuthImage
            maxWidth={"100%"}
            className="w-full"
            imageurl={item?.projectImage}
          />
        </span>
      )}
      <h3 className="text-[#232360] font-[500] text-left">
        {item?.projectName}
      </h3>
      {item?.projectDescription && (
        <p
          className="text-[#768396] text-[.9rem] text-left"
          dangerouslySetInnerHTML={{ __html: item?.projectDescription }}
        ></p>
      )}
      {item?.projectDate && (
        <div className="py-2 px-2 font-[500] text-[.8rem] my-1 rounded-lg flex items-center justify-start border-[1px] border-[#E2E2E2] w-[100px]">
          <h3>{item?.projectDate}</h3>
        </div>
      )}
    </div>
  );
};

export default DragItem;
