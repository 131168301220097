import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Layout/Sidebar";
import TopBar from "../../components/Layout/TopBar";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  createEvent,
  createSchedule,
  createTask,
  fetchProgram,
  uploadFile,
  subscribe,
  unsubscribe,
  publish,
} from "../../actions/academicProgramActions";

import preppyDog from "../../assets/preppyDog.png";
import Modal from "../../components/Planner/Modal";
import DatePicker from "react-datepicker";
import { FiMoreHorizontal } from "react-icons/fi";

import { BiPlus, BiLoader } from "react-icons/bi";

import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import ProgramTask from "./ProgramTask";
import AuthImage from "../../components/AuthImage/AuthImage";
import { BiArrowBack, BiBell } from "react-icons/bi";
import RewardConfig from "./RewardConfig";
import { notification } from "antd";
import ProgramSchedule from "../../components/AcademicProgram/ProgramSchedule";
import { API_BASE_URL } from "../../constantsNew";
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const AcademicProgramsDetails = (props) => {
  const dispatch = useDispatch();
  const {market} = props;
  const { id } = useParams();
  const navigate = useNavigate();
  // const {match: {params}} = props
  const [showMenu, setShowMenu] = useState(false);
  const { programs } = useSelector((state) => state.academicPrograms);
  const currentUser = useSelector(state => state.users.currentUser);
  // const { tasks } = useSelector(state => state.programSchedules);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const actionMenu = useRef(null);
  const [program, setProgram] = useState();
  const [schedule, setSchedule] = useState();
  const [task, setTask] = useState();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [eventLink, setEventLink] = useState();
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [activeTab, setActiveTab] = useState("schedules");
  const [subscribed,setSubscribed]=useState();
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
    recurrenceType: "NONE",
    daysOfWeek: [],
  });

  useEffect(()=>{
    if(programs && programs.length>0){
      let p = programs?.find(p=>p.id ===id)
      setProgram(p)
    }
  },[programs])
  useEffect(() => {
    if (program) {
      setSchedules(program.schedules);
      setTasks(program.toDos);
    }
  }, [program]);
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    window.addEventListener("click", closeOpenMenus);
    return () => {
      window.removeEventListener("click", closeOpenMenus);
    };
  }, []);
  const closeOpenMenus = (e) => {
    console.log("CLose menu click", e, actionMenu.current);
    if (showMenu && !actionMenu.current?.contains(e.target)) {
      console.log("CLose menu click inside", e);
      setShowMenu(false);
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await getBase64(file);
        return {
          default: base64,
        };
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    setLoading(true);
    dispatch(fetchProgram(id, setProgram, setLoading));
    // dispatch(fetchSchedules());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProgram({ ...program, [name]: type === "checkbox" ? checked : value });
  };

  const handleScheduleChange = (e) => {
    const { name, value } = e.target;
    setSchedule({ ...schedule, [name]: value });
  };

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setTask({ ...task, [name]: value });
  };

  const addSchedule = (e) => {
    e.preventDefault();

    setShowCreateScheduleModal(true);
    setShowMenu(false);
  };

  const addTask = (e) => {
    e.preventDefault();

    setShowCreateTaskModal(true);
    setShowMenu(false);
  };

  const addEvent = (e) => {
    e.preventDefault();
    setShowCreateEventModal(true);
  };
  const handleSave = (e) => {
    e.preventDefault();
    console.log("Save");
  };

  useEffect(() => {
    if (showCreateTaskModal && showCreateTaskModal === false) {
      setTask();
    }
  }, [showCreateTaskModal]);
  //   const handleSave = (e) => {
  //     e.preventDefault();
  //     if (program.id) {
  //       dispatch(updateProgram(program.id, program)).then(() => {
  //         Alert('Program updated successfully');
  //         setShowCreateProgramModal(false)
  //         dispatch(fetchPrograms());
  //       });
  //     } else {
  //       dispatch(saveProgram(program)).then(() => {
  //         Alert('Program saved successfully');
  //         setShowCreateProgramModal(false)
  //         dispatch(fetchPrograms());
  //       });
  //     }

  //   };

  //   const handleEdit = (program) => {
  //     setProgram(program);
  //     setShowCreateProgramModal(true)
  //   };

  //   const handleDelete = (id) => {
  //     dispatch(deleteProgram(id)).then(() => {
  //       Alert('Program deleted successfully');
  //       dispatch(fetchPrograms());
  //     });
  //   };

  //   const handleScheduleChange = (schedules) => {
  //     setProgram({ ...program, schedules });
  //   };

  useEffect(()=>{
    if(program){
      let subscribed = program.academicProgramSubscriptions?.find(ps=> ps.userName===currentUser.email && (ps.active==null || ps.active));
      if(subscribed){
        setSubscribed(true)
      }
      else {
        setSubscribed(false)
      }
   }
  },[program])
  const saveSchedule = (e) => {
    e.preventDefault();
    setEventLoading(true);
    dispatch(
      createSchedule(id, schedule, setProgram, setEventLink, setEventLoading)
    );

    setShowCreateScheduleModal(false);
  };

  const saveTask = (e) => {
    e.preventDefault();
    setTaskLoading(true);
    console.log("Task", task);
    dispatch(createTask(id, task, setProgram, setTaskLoading));

    setShowCreateTaskModal(false);
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    console.log("File Size===>", file.size);
    dispatch(uploadFile(file, setTask));
  };
  const handleEditorChange = (data) => {
    setTask((prevState) => ({
      ...prevState,
      description: data,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setEventLoading(true);

    dispatch(createEvent(newEvent, setEventLink, setEventLoading));

    setShowCreateEventModal(false);
  };

  const editTask = (t) => {
    setTask(t);
    setShowCreateTaskModal(true);
  };

  const closeTaskModal = (close) => {
    setTask();
    setShowCreateTaskModal(close);
  };
  const tabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const onSubscribe = (response) => {
    const {res,message}=response;
    if(message){
      setProgram(res)
      notification.success({ message: message });
      } else {
        if(res.message){
          notification.error({message: res.message})
        }else {
          notification.error({message: "Program Subscription Failed. Please try later"})
        }
      }
  };
  const subscribeProgram = () => {
    if(program.academicProgramSubscriptions){
       let subscribed = program.academicProgramSubscriptions?.find(ps=> ps.userName===currentUser.email && (ps.active ==null || ps.active));
       if(subscribed){
        dispatch(unsubscribe(program, onSubscribe));    
       }
       else {
        dispatch(subscribe(program, onSubscribe));
       }
    }else 
    {
      dispatch(subscribe(program, onSubscribe));
    }
    
  };

  const onPublished = (res)=>{
    console.log(res);
    if(res.id){
      
      if(res.isPublished ===true){
        notification.success({message:'Program published successfully'}); 
      } else {
        notification.success({message:'Program unpublished successfully'}); 
      }
    } else {
      if(res.message){
        notification.error({message:res.message});
      } else {
        notification.error({message: "Program could not be published. Please try again later"})
      }
    }
  }
  const publishProgram =(p,r)=>{
    dispatch(publish(p,r,onPublished));
  }
  console.log("Program", program);
  return (
    <div className="h-[85vh] overflow-auto">
      <div className="bg-white mx-3 overflow-scroll h-[85vh] p-4 rounded-lg">
        {loading ? (
          <div> Loading...</div>
        ) : (
          <div>
            {program && (
              <>
                <div
                  className="progamActionMenu"
                  style={{ position: "relative" }}
                >
                  {/* <button onClick={()=>navigate(-1)} data-tooltip-target="tooltip-hover" data-tooltip-trigger="hover" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" ><BiArrowBack size={20}/></button>

                          <div id="tooltip-hover" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                              Back
                              <div class="tooltip-arrow" data-popper-arrow></div>
                          </div> */}
                  <span
                    className="mt-6 flex cursor-pointer"
                    onClick={() => navigate(-1)}
                    title="Back"
                  >
                    <BiArrowBack size={20} />
                  </span>
                  <h1 className="py-4 font-bold text-2xl">
                    {program.programName}{" "}
                  </h1>
                  <p
                    className="rte"
                    dangerouslySetInnerHTML={{ __html: program.description }}
                  ></p>
                  {currentUser?.id === program.ownerId && !market && 
                    <span style={{ position: "absolute", right: 10, top: 10 }}>
                        <button
                          className="mt-2 mr-2 w-full bg-[#7C38FD33] hover:bg-[#7C38FD] hover:text-white text-[#7C38FD] font-bold py-2 px-4 rounded-b transition-colors duration-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            publishProgram(program,!program.isPublished)
                          }}
                        >
                          {program.isPublished ? `Unpublish` : 'Publish' }
                        </button>
                        </span>
                      }
                  {currentUser?.id !== program.ownerId  && 
                  <span style={{ position: "absolute", right: 10, top: 10 }}>
                    <div className="relative inline-block text-left">
                      <button
                        type="button"
                        className={`inline-flex w-full justify-center gap-x-1.5 rounded-md ${!subscribed ? 'bg-[#5F33E1] ' : 'bg-[#ff0000]'} px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300`}
                        id="menu-button"
                        aria-expanded="true"
                        aria-haspopup="true"
                        onClick={subscribeProgram}
                      >
                        <span className="flex justify-center">
                          {subscribed ? 'Unsubscribe' : 'Subscribe'}
                        </span>{" "}
                        <span
                          className="flex justify-center"
                          style={{ marginTop: 2 }}
                        >
                          <BiBell size={16} />
                        </span>
                      </button>
                      
                      {/* <div>
                                  <button ref={actionMenu} type="button" className={`inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${showMenu && 'bg-blue-100 hover:bg-blue-50'}`} id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={()=>setShowMenu(!showMenu)}>
                                    <FiMoreHorizontal size={20}/>
                                  </button>
                                </div>

                                <div className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${showMenu ? 'ransform opacity-100 scale-100 transition ease-in duration-75' : 'transition ease-out duration-100 transform opacity-0 scale-95 hidden'}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                  <div className="py-1" role="none">
                                    
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-0"><span onClick={addSchedule} className="p-2 m-0  rounded" style={{display: "flex", justifyContent: "left",alignItems:'center'}}> <span style={{verticalAlign:'middle'}} title='Add schedule'><BiPlus size={20}/> </span><span>Add schedule</span></span></a>
                                    <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-1"><span onClick={addTask} className="p-2 m-0  rounded" style={{display: "flex", justifyContent: "left",alignItems:'center'}}> <span style={{verticalAlign:'middle'}} title='Add schedule'><BiPlus size={20}/> </span><span>Add Task</span></span> </a>
                                    
                                    
                                  </div>
                                </div> */}
                    </div>
                  </span>
                }
                </div>

                <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                  <ul className="flex flex-wrap -mb-px">
                    <li className="me-2">
                      <span
                        onClick={() => tabChange("schedules")}
                        className={`inline-block cursor-pointer p-4 border-b-2  rounded-t-lg ${
                          activeTab === "schedules"
                            ? "text-blue-600  border-blue-600  active dark:text-blue-500 dark:border-blue-500"
                            : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                        }`}
                      >
                        Schedules
                      </span>
                    </li>
                    <li className="me-2">
                      <span
                        onClick={() => tabChange("tasks")}
                        className={`inline-block p-4 cursor-pointer border-b-2 rounded-t-lg ${
                          activeTab === "tasks"
                            ? "text-blue-600  border-blue-600  active dark:text-blue-500 dark:border-blue-500"
                            : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                        }`}
                        aria-current="page"
                      >
                        Tasks
                      </span>
                    </li>
                    <li className="me-2">
                      <span
                        onClick={() => tabChange("chats")}
                        className={`inline-block p-4 cursor-pointer border-b-2 rounded-t-lg ${
                          activeTab === "chats"
                            ? "text-blue-600  border-blue-600  active dark:text-blue-500 dark:border-blue-500"
                            : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                        }`}
                        aria-current="page"
                      >
                        Program Chat
                      </span>
                    </li>
                    <li className="me-2">
                      <span
                        onClick={() => tabChange("rewardConfigs")}
                        className={`inline-block p-4 cursor-pointer border-b-2 rounded-t-lg ${
                          activeTab === "rewardConfigs"
                            ? "text-blue-600  border-blue-600  active dark:text-blue-500 dark:border-blue-500"
                            : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                        }`}
                        aria-current="page"
                      >
                        Reward configurations
                      </span>
                    </li>
                  </ul>
                </div>
                {activeTab === "schedules" && (
                  <div style={{ marginTop: 10 }} className="bg-lightgrey">
                    {/* <div>
                                <h2>Schedules</h2>
                                <button onClick={addSchedule} className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">Add Schedule</button>
                            </div> */}
                    <div className="w-full flex flex-col">
                      <div className="flow-root flex items-center ">
                        <h2 className="float-left  font-bold mb-4 bg-emerald-100 flex justify-between">
                          Schedules
                        </h2>
                        {!market && 
                        <div className="float-right h-fit min-h-full flex justify-end">
                          <button
                            onClick={addSchedule}
                            className="p-2 bg-blue-600 text-white rounded"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <span
                              style={{ verticalAlign: "middle" }}
                              title="Add schedule"
                            >
                              <BiPlus size={20} />{" "}
                            </span>
                            <span>Add schedule</span>
                          </button>{" "}
                        </div>
                        }
                      </div>
                    </div>
                    <div
                      style={{ marginBottom: 10, backgroundColor: "#f6f6f6" }}
                    >
                      {schedules && schedules.length > 0 ? (
                        schedules.map((schedule, i) => (
                          <div className="pl-4 border bg-white my-2">
                            <ProgramSchedule schedule={schedule} {...props}/>
                          </div>
                        ))
                      ) : (
                        <div className="pl-10 justify-center">
                          No schedules added
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === "tasks" && (
                  <div style={{ marginTop: 10 }} className="bg-lightgrey">
                    {/* <div>
                                <h2>Tasks</h2>
                                <button onClick={addTask} className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">Add Task</button>
                            </div> */}
                    <div className="w-full flex flex-col ">
                      <div className="flow-root flex items-center">
                        <h2 className="float-left  font-bold mb-4 flex  justify-between">
                          Tasks
                        </h2>
                        {!market && 
                        <div className="float-right h-fit min-h-full flex justify-end">
                          <button
                            onClick={addTask}
                            className="p-2 bg-blue-600 text-white rounded"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <span
                              style={{ verticalAlign: "middle" }}
                              title="Add schedule"
                            >
                              <BiPlus size={20} />{" "}
                            </span>
                            <span>Add task</span>
                          </button>{" "}
                        </div>
                        }
                      </div>
                    </div>
                    <div
                      style={{ marginBottom: 10, backgroundColor: "#f6f6f6" }}
                    >
                      {program?.toDos.length > 0 ? (
                        program.toDos.map((task, i) => (
                          <div className="pl-4 border bg-white my-2">
                            <ProgramTask
                              task={task}
                              key={`task-${i}`}
                              editTask={editTask}
                              {...props}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="pl-10 justify-center">
                          No tasks added
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === "chats" && (
                  <div style={{ marginTop: 10 }} className="bg-lightgrey">
                    {/* <div>
                                <h2>Tasks</h2>
                                <button onClick={addTask} className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">Add Task</button>
                            </div> */}
                    <div className="w-full flex flex-col ">
                      <div className="flow-root flex items-center">
                        <h2 className="float-left  font-bold mb-4 flex  justify-between">
                          Program chat
                        </h2>

                        {/* <div className="float-right h-fit min-h-full flex justify-end"><button onClick={addTask} className="p-2 bg-blue-600 text-white rounded" style={{display: "flex", justifyContent: "center",alignItems:'center'}}> <span style={{verticalAlign:'middle'}} title='Add schedule'><BiPlus size={20}/> </span><span>Add task</span></button> </div> */}
                      </div>
                    </div>
                    {program?.chat ? (
                      <div style={{ marginBottom: 10 }}>
                        <Link
                          to={`/forum/${program.chat.chatId}`}
                          style={{ color: "#1e88e5" }}
                        >
                          Go to Chat
                        </Link>
                      </div>
                    ) : (
                      <div> No chat associated with this program</div>
                    )}
                  </div>
                )}
                {activeTab === "rewardConfigs" && (
                  <div style={{ marginTop: 10 }} className="bg-lightgrey">
                    {/* <div>
                                <h2>Tasks</h2>
                                <button onClick={addTask} className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">Add Task</button>
                            </div> */}
                    <RewardConfig program={program} {...props}/>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <Modal
        showModal={showCreateScheduleModal}
        setShowModal={setShowCreateScheduleModal}
      >
        <div className="p-6 max-w-full bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between mb-2">
            <img
              src={preppyDog}
              alt="preppy-dog"
              className="w-48 object-contain h-36"
            />
            <h2 className="text-xl mr-40 font-semibold text-gray-800 flex-grow text-center">
              CREATE SCHEDULE
            </h2>
          </div>
          <form className="space-y-6">
            <div>
              <label className="block text-gray-700 mb-1">Name</label>
              <input
                type="text"
                name="scheduleName"
                value={schedule?.scheduleName}
                onChange={handleScheduleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex mt-6">
              <button
                type="button"
                className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={saveSchedule}
              >
                Save Schedule
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        showModal={showCreateEventModal}
        setShowModal={setShowCreateEventModal}
      >
        <div className="p-6 max-w-full bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between mb-2">
            <img
              src={preppyDog}
              alt="preppy-dog"
              className="w-48 object-contain h-36"
            />
            <h2 className="text-xl mr-40 font-semibold text-gray-800 flex-grow text-center">
              CREATE EVENT
            </h2>
          </div>
          <form className="space-y-6">
            <div>
              <label className="block text-gray-700 mb-1">Title</label>
              <input
                type="text"
                name="title"
                value={newEvent.title}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-gray-700 mb-1">
                  Start Date and Time
                </label>
                <DatePicker
                  selected={newEvent.start}
                  onChange={(date) =>
                    setNewEvent((prev) => ({ ...prev, start: date }))
                  }
                  showTimeSelect
                  dateFormat="Pp"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 mb-1">
                  End Date and Time
                </label>
                <DatePicker
                  selected={newEvent.end}
                  onChange={(date) =>
                    setNewEvent((prev) => ({ ...prev, end: date }))
                  }
                  showTimeSelect
                  dateFormat="Pp"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 mb-1">
                Recurrence Type:
              </label>
              <select
                name="recurrenceType"
                value={newEvent.recurrenceType}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                <option value="NONE">None</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
                <option value="YEARLY">Yearly</option>
                <option value="DAYS_OF_WEEK">Days of Week</option>
              </select>
            </div>
            {newEvent.recurrenceType === "DAYS_OF_WEEK" && (
              <div>
                <label className="block text-gray-700 mb-1">Select Days:</label>
                <div className="space-y-2">
                  {daysOfWeek.map((day) => (
                    <div key={day} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value={day}
                        checked={newEvent.daysOfWeek.includes(day)}
                        onChange={handleChange}
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span>{day}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex justify-center mt-6">
              <button
                type="button"
                className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={handleSubmit}
                disabled={eventLoading}
              >
                {eventLoading && <BiLoader size={16} />}
                Save Event
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal showModal={showCreateTaskModal} setShowModal={closeTaskModal}>
        <div className="p-6 max-w-full bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between mb-2">
            <img
              src={preppyDog}
              alt="preppy-dog"
              className="w-32 object-contain h-32"
            />
            <h2 className="text-xl mr-40 font-semibold text-gray-800 flex-grow text-center">
              CREATE TASK
            </h2>
          </div>
          <form
            className="space-y-6"
            style={{ height: "70vh", overflow: "auto" }}
          >
            <div>
              <label className="block text-gray-700 mb-1">Name</label>
              <input
                type="text"
                name={"title"}
                value={task?.title}
                onChange={handleTaskChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-1">Description</label>

              <CKEditor
                type=""
                name={"description"}
                editor={ClassicEditor}
                config={{
                  // ckfinder: {
                  //   // Upload the images to the server using the CKFinder QuickUpload command
                  //   // You have to change this address to your server that has the ckfinder php connector
                  //   uploadUrl: `${API_BASE_URL}/post-service/api/file-uploads` //Enter your upload url
                  // }
                  extraPlugins: [uploadPlugin],
                }}
                data={task?.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log({ event, editor, data })
                  handleEditorChange(data);
                }}
              />
              {/* <input
                    type="text"
                    name="scheduleName"
                    value={task?.description}
                    onChange={handleTaskChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                /> */}
            </div>
            <div className="flex">
              <div className="flex-1  max-w-full">
                <label className="block text-gray-700 mb-1">
                  Due Date and Time
                </label>
                <DatePicker
                  selected={task?.dueDate}
                  onChange={(date) =>
                    setTask((prev) => ({ ...prev, dueDate: date }))
                  }
                  showTimeSelect
                  dateFormat="Pp"
                  name={"dueDate"}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="flex-1  max-w-full">
                <label className="block text-gray-700 mb-1">Task image</label>
                <input
                  type="file"
                  className="block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100"
                  onChange={handleUpload}
                  accept="image/*"
                />
                {task?.fileUrl && (
                  <div>
                    <AuthImage
                      imageurl={task.fileUrl}
                      className="w-[100px] h-48 object-contain"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex mt-6">
              <button
                type="button"
                className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={saveTask}
              >
                Save Task
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AcademicProgramsDetails;
