import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import Iconone from "../../assets/Group 2.png";
import Icontwo from "../../assets/share (1) 1.png";
import Iconthree from "../../assets/close-circle.png";
import CameraImage from "../../assets/camera.svg";
import MicImage from "../../assets/mic.png";
import moment from "moment";
import { del } from "../../util/APIUtils";
import { Confirm } from "react-st-modal";
import preppyDog from "../../assets/preppyDog.png";
import {
  fetchEvents,
  updateEvent,
  fetchColorScheme,
  fetchPlans,
} from "../../actions/calendarActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaEdit, FaSave, FaSpinner, FaTrashAlt } from "react-icons/fa";
import CustomColorDropdown from "../Layout/CustomColorDropdown";
import { EVENT_TYPES } from "../../constantsNew";

export const DELETE_EVENT = "DELETE_EVENT";

const DetailsModal = React.memo(
  ({
    showModal,
    setShowModal,
    onCloseModal,
    detail,
    event,
    updateEventsAfterSave,
  }) => {
    console.log("DetailsModal rendering", event);
    const dispatch = useDispatch();

    const [color, setColor] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState("");
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [summary, setSummary] = useState("");

    const [newEvent, setNewEvent] = useState({
      title: "",
      start: null,
      end: null,
      colorCode: "",
      summary: "",
    });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (event) {
        setColor(
          event.colorCode
            ? event.colorCode
            : event?._def?.ui?.backgroundColor
            ? event?._def?.ui?.backgroundColor
            : ""
        );
        setTitle(event.title || "");
        setSummary(event.summary || "");
        setStart(event.startDate ? new Date(event.startDate) : null);
        setEnd(event.endDate ? new Date(event.endDate) : null);
        setNewEvent({
          title: event.title || "",
          summary: event.summary || "",
          start: event.startDate ? new Date(event.startDate) : null,
          end: event.endDate ? new Date(event.endDate) : null,
          colorCode: event.colorCode
            ? event.colorCode
            : event?._def?.ui?.backgroundColor
            ? event?._def?.ui?.backgroundColor
            : "",
        });
      }
    }, [event]);

    useEffect(() => {
      dispatch(fetchColorScheme());
    }, [dispatch]);

    const colorScheme = useSelector((state) => state.calendar.colorScheme);

    const handleEdit = useCallback(() => {
      setIsEditing(true);
    }, []);

    const handleSave = useCallback(async () => {
      const eventData = {
        title,
        summary,
        start: start.toISOString(),
        end: end.toISOString(),
        colorCode: newEvent.colorCode,
      };
      await dispatch(updateEvent(event.id, eventData));
      setIsEditing(false);
      onCloseModal();
      // dispatch(fetchEvents());
      updateEventsAfterSave();
      dispatch(fetchPlans());
    }, [
      dispatch,
      event?.id,
      title,
      summary,
      start,
      end,
      newEvent.colorCode,
      onCloseModal,
    ]);

    const handleCancel = useCallback(() => {
      setIsEditing(false);
      if (event) {
        setTitle(event.title);
        setStart(event.startDate);
        setEnd(event.endDate);
        setColor(event.colorCode);
        setSummary(event.summary);
      }
    }, [event]);

    const { timeLeft, label, statusLabel } = useMemo(() => {
      const now = moment();
      let eventStart = moment(event?.startDate);
      let eventEnd = moment(event?.endDate);
      if(event?.eventType==='REMINDER'){
        eventEnd = eventStart;
      }
      let timeLeft, label, statusLabel;

      if (now.isBefore(eventStart)) {
        // Event has not started yet
        const duration = moment.duration(eventStart.diff(now));
        statusLabel = "Starts in";
        if (duration.asHours() < 24) {
          timeLeft = Math.ceil(duration.asHours());
          label = "Hours";
        } else {
          timeLeft = Math.ceil(duration.asDays());
          label = "Days";
        }
      } else if (now.isBefore(eventEnd)) {
        // Event has started but not ended
        const duration = moment.duration(eventEnd.diff(now));
        statusLabel = "Ends in";
        if (duration.asHours() < 24) {
          timeLeft = Math.ceil(duration.asHours());
          label = "Hours";
        } else {
          timeLeft = Math.ceil(duration.asDays());
          label = "Days";
        }
      } else {
        // Event has ended
        const duration = moment.duration(now.diff(eventEnd));
        statusLabel = "Ended";
        if (duration.asHours() < 24) {
          timeLeft = Math.ceil(duration.asHours());
          label = "Hours Ago";
        } else {
          timeLeft = Math.ceil(duration.asDays());
          label = "Days Ago";
        }
      }
      return { timeLeft, label, statusLabel };
    }, [event?.startDate, event?.endDate]);

    const handleDeleteEvent = async () => {
      setOpenDeleteModal(true);
    };

    const confirmDelete = async () => {
      setLoading(true); // Set loading to true when deletion starts
      try {
        await del(`/calendar-service/api/calendar/events/${event.id}`);
        dispatch({ type: DELETE_EVENT, payload: event.id });
        onCloseModal();
      } catch (error) {
        console.error("Error deleting event:", error);
      } finally {
        setLoading(false); // Set loading to false when deletion finishes
        setOpenDeleteModal(false);
      }
    };

    const cancelDelete = () => {
      setOpenDeleteModal(false);
    };

    const handleModalClose = useCallback(() => {
      setIsEditing(false);
      handleCancel();
      onCloseModal();
    }, [handleCancel, onCloseModal]);

    return (
      <>
        <Modal
          classNames={{ modal: "rounded-lg w-[800px] " }}
          open={showModal}
          onClose={handleModalClose}
          center
        >
          {event ? (
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <img
                  src={preppyDog}
                  alt="preppy-dog"
                  className="w-24 object-contain h-24"
                />
                <h2 className="text-3xl font-semibold text-gray-800">
                  {isEditing ? "Edit Event" : "Event Details"}
                </h2>
                <div className="flex items-center gap-2">
                  {!isEditing && (
                    <>
                      <FaTrashAlt
                        onClick={handleDeleteEvent}
                        className="text-red-500 cursor-pointer text-xl hover:text-red-600"
                      />
                      <FaEdit
                        onClick={handleEdit}
                        className="text-gray-800 cursor-pointer text-xl hover:text-gray-600"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <div className="mb-4">
                    {isEditing ? (
                      <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        // className="w-full p-2 text-2xl font-semibold text-gray-700 border rounded"
                        className="w-full text-2xl font-semibold text-gray-800 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                      />
                    ) : (
                      <h3 className="text-2xl font-semibold text-gray-800">
                        {event?.title}
                      </h3>
                    )}
                  </div>
                  <div className="mb-4">
                    {isEditing ? (
                      <>
                        <label className="block text-gray-700 mb-1">
                          Summary:
                        </label>
                        <textarea
                          type="text"
                          value={summary || event?._def?.extendedProps?.summary}
                          onChange={(e) => setSummary(e.target.value)}
                          // className="w-full p-2 text-2xl font-semibold text-gray-700 border rounded"
                          className="w-full p-2 text-gray-700 border rounded resize-none focus:border-blue-500 outline-none"
                          rows={6}
                        />
                      </>
                    ) : (
                      <p className="text-gray-700">
                        {event?.summary ?? event?._def?.extendedProps?.summary}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-1">
                      Event Type:
                    </label>
                    <p
                      className="text-sm bg-green-400 py-2 px-4 rounded-md inline font-medium text-white"
                      style={{
                        backgroundColor:
                          event?.colorCode ?? event?._def?.ui?.backgroundColor,
                      }}
                    >
                      {EVENT_TYPES[event.eventType] ??
                        EVENT_TYPES[event?._def?.extendedProps?.eventType] ??
                        "N/A"}
                      {console.log("event===>", event)}
                    </p>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-1">
                      {statusLabel}
                    </label>
                    <div className="flex items-center gap-2">
                      <p className="text-2xl font-semibold bg-blue-100 text-blue-800 py-2 px-4 rounded">
                        {timeLeft}
                      </p>
                      <p className="text-gray-600">{label}</p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-1">
                      Start Time:
                    </label>
                    {isEditing ? (
                      <DatePicker
                        selected={start}
                        onChange={(date) => setStart(date)}
                        showTimeSelect
                        dateFormat="d MMM, h:mm a"
                        className="bg-gray-200 text-sm px-4 py-3 rounded-lg text-center w-full"
                      />
                    ) : (
                      <p className="bg-gray-200 text-sm px-4 py-3 rounded-lg">
                        {moment(event.startDate).format("D MMM, h:mm A")}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                  {(event.endDate || isEditing) && <label className="block text-gray-700 mb-1">
                      End Time:
                    </label>}
                    {isEditing ? (
                      <DatePicker
                        selected={end}
                        onChange={(date) => setEnd(date)}
                        showTimeSelect
                        dateFormat="d MMM, h:mm a"
                        className="bg-red-100 text-red-500 text-sm px-4 py-3 rounded-lg text-center w-full"
                      />
                    ) : (<>
                      {event.endDate && 
                        <p className="bg-red-100 text-red-500 text-sm px-4 py-3 rounded-lg">
                        {moment(event.endDate).format("D MMM, h:mm A")}
                        </p>
                        
                      }
                      </>
                    )}
                  </div>
                </div>
              </div>
              {console.log("event", event?.EventImpl)}
              {!isEditing && (
                <div className="flex items-center gap-2 mb-4">
                  <span>Color:</span>
                  <div
                    className="w-6 h-6 rounded-full"
                    style={{
                      backgroundColor:
                        event?.colorCode ?? event?._def?.ui?.backgroundColor,
                    }}
                  ></div>
                </div>
              )}
              {isEditing && (
                <div className="flex flex-col gap-4 mt-4">
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-1">Color:</label>
                    <div className="w-[25%]">
                      <CustomColorDropdown
                        colorScheme={colorScheme}
                        newEvent={newEvent}
                        setNewEvent={setNewEvent}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-2">
                    <button
                      onClick={handleCancel}
                      className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                    >
                      <FaSave className="inline-block mr-2" />
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="py-8 px-8">
              <div className="flex items-center justify-between">
                <h3 className="text-gray-500">Academic Studies</h3>
                <div className="flex items-center justify-end gap-2">
                  <img src={Iconone} />
                  <img src={Icontwo} />
                  <img src={Iconthree} />
                </div>
              </div>
              <div className="flex items-center my-6 justify-between">
                <div>
                  <h3 className="text-black font-[600] text-[2rem]">
                    Math Assignment
                  </h3>
                  <p className="text-gray-900"> Description</p>
                </div>
                <div className="flex items-center gap-2 justify-center">
                  <h3>Starts in</h3>
                  <div>
                    <p className="py-4 px-6 text-[1.5rem] font-[600] bg-[#4452f161]">
                      5
                    </p>
                    <p className="text-center">Days</p>
                  </div>
                </div>
              </div>
              <div className="grid gap-3 grid-cols-5">
                <div className="col-span-3">
                  <div className="bg-[#FBFBFF] py-2 px-3">
                    <div className="pb-8 px-3 pt-2 border-b-[1px] border-gray-300">
                      <h4 className="text-gray-400">Enter Description</h4>
                    </div>
                    <div className="py-2 flex items-center justify-start gap-3">
                      <img className="w-[30px]" src={CameraImage} />
                      <img className="w-[30px]" src={MicImage} />
                    </div>
                  </div>
                </div>
                <div className="col-span-2  rounded-lg bg-[#FBFBFF]">
                  <div className="text-gray-500  py-4 px-8 border-gray-400  border-b-[1px]">
                    Attributes
                  </div>
                  <div className="flex items-center py-2 px-8 justify-start gap-8">
                    <p>Status</p>
                    <p className="bg-gray-200 text-[.8rem] px-4 py-3 rounded-lg ">
                      Not Started
                    </p>
                  </div>
                  <div className="flex items-center py-2 px-8 justify-start gap-8">
                    <p>Priority</p>
                    <p className="bg-red-100 text-red-500 text-[.8rem] px-4 py-3 rounded-lg ">
                      High
                    </p>
                  </div>
                  <div className="flex items-center py-2 px-8 justify-start gap-8">
                    <p>Assignee</p>
                    <p className="bg-purple-100 text-purple-500 text-[.8rem] px-4 py-3 rounded-lg ">
                      Sahil Thakur
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
        <Modal
          open={openDeleteModal}
          onClose={cancelDelete}
          center
          styles={{
            modal: {
              width: "500px",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            },
            closeButton: {
              outline: "none",
              cursor: "pointer",
            },
          }}
        >
          <h2 className="text-lg font-semibold mb-4 text-center">
            Delete Event
          </h2>
          <p className="text-gray-600 text-center mb-6">
            Are you sure you want to delete this event?
          </p>
          <div className="flex justify-end gap-4 mt-6">
            <button
              onClick={cancelDelete}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              onClick={confirmDelete}
              className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
              disabled={loading}
            >
              {loading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                  Deleting...
                </>
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </Modal>
      </>
    );
  }
);
export default React.memo(DetailsModal);
