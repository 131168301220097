import React, { useEffect, useState, useRef } from "react";
import { getImage } from "./authImage.actions";
import PropTypes from "prop-types";

const AuthImage = (props) => {
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { imageurl, maxWidth, maxHeight } = props;
  const fullScreenRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    if (imageurl && imageurl.indexOf("data:") === -1) {
      getImage({ imageurl, setImage, setIsLoading });
    } else if (imageurl) {
      setImage(imageurl);
      setIsLoading(false);
    } else {
      setImage(undefined);
      setIsLoading(false);
    }
  }, [imageurl]);

  const containerStyle = {
    maxWidth: maxWidth || "300px",
    maxHeight: maxHeight || "300px",
    width: "100%",
    height: "100%",
    aspectRatio: "1 / 1",
  };

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleClickOutside = (event) => {
    if (
      fullScreenRef.current &&
      !fullScreenRef.current.contains(event.target)
    ) {
      handleCloseFullScreen();
    }
  };

  useEffect(() => {
    if (isFullScreen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFullScreen]);

  return (
    <>
      <div className={`relative ${props.className}`} style={containerStyle}>
        {isLoading && (
          <div className="absolute inset-0 bg-gray-200 animate-pulse">
            <div className="h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 background-animate"></div>
          </div>
        )}
        {image && (
          <img
            src={image}
            className={`w-full h-full object-cover rounded-lg cursor-pointer ${
              isLoading ? "invisible" : ""
            }`}
            alt=""
            onLoad={() => setIsLoading(false)}
            onClick={handleImageClick}
          />
        )}
      </div>
      {isFullScreen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={handleCloseFullScreen}
        >
          <div
            ref={fullScreenRef}
            className="relative"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={image}
              alt=""
              className="max-w-[90vw] max-h-[90vh] object-contain"
            />
            <button
              className="absolute top-2 right-2 text-white text-xl bg-black bg-opacity-50 rounded-full w-8 h-8 flex items-center justify-center"
              onClick={handleCloseFullScreen}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

AuthImage.propTypes = {
  imageurl: PropTypes.string,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default AuthImage;
