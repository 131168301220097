import { API_BASE_URL } from "../constantsNew";
import { post, get, put, del } from "../util/APIUtils";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const ADD_LABELS = "ADD_LABELS";
export const ADD_WEB_LINKS = "ADD_WEB_LINKS";
export const CREATE_EVENT = "CREATE_EVENT";
export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const SELECT_PROJECT = "SELECT_PROJECT";
export const FETCH_PROJECT_DATA_SUCCESS = "FETCH_PROJECT_DATA_SUCCESS";
export const DELETE_TODO = "DELETE_TODO";
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_SCHEDULE_EVENT = "DELETE_SCHEDULE_EVENT";

export const createProject = (data) => {
  return async (dispatch) => {
    const response = await post("/userproject-service/api/user-projects", data);
    dispatch({ type: CREATE_PROJECT, payload: response });
  };
};

export const addLabels = (projectId, labels) => {
  return async (dispatch) => {
    const formattedLabels = labels.map((label) => ({
      label: label.name,
      colorCode: label.color,
    }));

    const response = await post(
      `/userproject-service/api/user-projects/${projectId}/labels`,
      formattedLabels
    );
    dispatch({ type: ADD_LABELS, payload: response });
  };
};

export const addWebLinks = (projectId, links) => {
  return async (dispatch) => {
    try {
      const response = await post(
        `/userproject-service/api/user-projects/${projectId}/weblinks`,
        links
      );
      dispatch({ type: ADD_LABELS, payload: response });
      return response.data;
    } catch (error) {
      console.error("Error adding web links:", error);
      throw error;
    }
  };
};

export const addTodos = (projectId, todos) => {
  return async (dispatch) => {
    try {
      const response = await post(
        `/userproject-service/api/user-projects/${projectId}/todos`,
        todos
      );
      dispatch({ type: ADD_LABELS, payload: response });
      return response.data;
    } catch (error) {
      console.error("Error adding todos:", error);
      throw error;
    }
  };
};

export const updateTodo = (projectId, updatedTodo) => {
  return async (dispatch) => {
    try {
      const response = await put(
        `/userproject-service/api/user-projects/todos/${updatedTodo.id}`,
        updatedTodo
      );
      dispatch({
        type: "UPDATE_TODO",
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating todo:", error);
      throw error;
    }
  };
};

export const addNotes = (projectId, notes) => {
  return async (dispatch) => {
    try {
      const response = await post(
        `/userproject-service/api/user-projects/${projectId}/notes`,
        notes
      );
      dispatch({ type: ADD_LABELS, payload: response });
      return response.data;
    } catch (error) {
      console.error("Error adding Notes:", error);
    }
  };
};

export const createEvent = (projectId, scheduleId, eventData) => {
  return async (dispatch) => {
    try {
      const response = await post(
        `/userproject-service/api/user-projects/${projectId}/schedules/${scheduleId}/events`,
        eventData
      );
      dispatch({
        type: ADD_LABELS,
        payload: response,
      });
      return response;
    } catch (error) {
      console.error("Error creating event:", error);
      throw error;
    }
  };
};

export const createSchedule = (id, scheduleName) => {
  return async (dispatch) => {
    try {
      const response = await post(
        `/userproject-service/api/user-projects/${id}/schedules`,
        { scheduleName: scheduleName }
      );
      dispatch({
        type: ADD_LABELS,
        payload: response,
      });
      return response;
    } catch (error) {
      console.error("Error creating schedule:", error);
      throw error;
    }
  };
};

export const uploadFile = (file, setFileUrl) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await post(
      `/post-service/api/file-uploads`,
      formData,
      undefined,
      "fileUpload"
    );
    setFileUrl(`${API_BASE_URL}/post-service/api/file-uploads/${response.id}`);
  };
};

export const selectProject = (projectId) => (dispatch) => {
  console.log("actiontest2", projectId);
  dispatch({
    type: SELECT_PROJECT,
    payload: projectId,
  });
};

export const fetchProjectData = (projectId) => {
  return async (dispatch) => {
    try {
      const response = await get(
        `/userproject-service/api/user-projects/${projectId}`
      );
      console.log("Fetched project data:", response.data);
      dispatch({ type: "FETCH_PROJECT_DATA_SUCCESS", payload: response.data });
      dispatch(selectProject(response));
    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };
};

export const fetchProjectDataSuccess = (data) => ({
  type: "FETCH_PROJECT_DATA_SUCCESS",
  payload: data,
});

export const deleteTodo = (projectId, todoId) => {
  return async (dispatch) => {
    try {
      await del(
        `/userproject-service/api/user-projects/${projectId}/todos/${todoId}`
      );
      dispatch({
        type: DELETE_TODO,
        payload: { projectId, todoId },
      });
    } catch (error) {
      console.error("Error deleting todo:", error);
      throw error;
    }
  };
};

export const deleteNote = (projectId, noteId) => {
  return async (dispatch) => {
    try {
      await del(
        `/userproject-service/api/user-projects/${projectId}/notes/${noteId}`
      );
      dispatch({
        type: DELETE_NOTE,
        payload: { projectId, noteId },
      });
    } catch (error) {
      console.error("Error deleting note:", error);
      throw error;
    }
  };
};
