import React from "react";
import { Link } from "react-router-dom";

const SignupEmail = ({ email, setEmail, handleSendOtp }) => {
  return (
    <div className="bg-white w-[600px] rounded-[20px] px-12 py-[2.5rem]">
      <div>
        <h3 className="text-[32px] text-center font-[600]">
          Create an Account
        </h3>
        <p className="my-1 text-gray-500 text-center text-[18px]">
          Create a account to continue
        </p>
      </div>
      <div className="mt-6">
        <div className="flex items-start mt-4 justify-start flex-col">
          <label className="mb-3">Email address:</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-[#F1F4F9] rounded-[8px] w-full border-[1px] outline-none border-[#D8D8D8] py-3 px-4"
            placeholder="esteban_schiller@gmail.com"
          />
        </div>
      </div>
      <div className="mt-12">
        <button
          onClick={handleSendOtp}
          className="bg-[#4880FF] py-3 text-white rounded-[8px] w-[100%]"
        >
          Send OTP
        </button>
        <p className="mt-3">
          Already have a Account?{" "}
          <Link to="/login">
            {" "}
            <span className="text-[#4880FF] font-[500] underline">
              Login
            </span>{" "}
          </Link>{" "}
        </p>
      </div>
    </div>
  );
};

export default SignupEmail;
