import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { put, post } from "../../util/APIUtils";

const UserManagement = () => {
  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-center">
      <div className="px-12 py-6 w-[600px] bg-white rounded-lg shadow-lg">
        <h4 className="font-semibold text-start text-lg">Work In Progress</h4>
      </div>
    </div>
  );
};

export default UserManagement;
