import React, { useState } from "react";
import { HiMiniEllipsisHorizontal } from "react-icons/hi2";
import { FiPlus } from "react-icons/fi";
import MathImage from "../../assets/math.png";
import ContentImage from "../../assets/Content.png";
import DesignImage from "../../assets/design.png";
import LastImage from "../../assets/last.png";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag } from "react-dnd";
import DragItem from "./DragElement";
import DropZone from "./DropZone";
import { patch } from "../../util/APIUtils";
import { Link } from "react-router-dom";
const TaskArea = ({
  showModal,
  setShowModal,
  backlogList,
  inProgressList,
  toDoList,
  reviewList,
  fetchProjects,
  setToDoList,
  setReviewList,
  setInProgressList,
  setBacklogList,
  setProjectStatus,
}) => {
  const [isDragged, setIsDragged] = useState(false);

  const handleDrop = (item, status) => {
    patch(`/userproject-service/api/user-projects/${item?.id}/projectStatus`, {
      status,
    })
      .then((response) => {
        fetchProjects();
      })
      .catch((error) => {
        console.error("Error saving post:", error);
      });
  };

  return (
    <div className="px-16 py-4 overflow-auto h-full bg-[#E8E8FF]">
      <DndProvider backend={HTML5Backend}>
        <div className="grid grid-cols-4 grid-flow-col">
          <div className="mr-2">
            <div className="bg-white flex items-center justify-between px-3 py-2 rounded-lg">
              <h3 className="font-[600] text-[1.2rem]">Backlog</h3>
              <div className="flex items-center justify-end gap-2">
                <HiMiniEllipsisHorizontal className="text-[1.2rem] text-[#768396]" />
                <button className="py-2 px-2 rounded-md bg-[#E8EAFF]">
                  <FiPlus
                    className="text-[#6772FE]"
                    onClick={() => {
                      setShowModal(true);
                      setProjectStatus("BACKLOG");
                    }}
                  />
                </button>
              </div>
            </div>
            {isDragged && <DropZone status="BACKLOG" onDrop={handleDrop} />}
            {backlogList.map((item) => (
              <Link to={`/program/${item?.id}/posts`}>
                <DragItem setIsDragged={setIsDragged} item={item} />
              </Link>
            ))}
            {/* <div className='bg-white   px-4  my-2 py-3 rounded-lg' >
                    <button className='py-1 flex items-center justify-start bg-[#1EA7FF] px-3 rounded-md text-[.8rem] text-white' >Research</button>
                    
                    <h3 className='text-[#232360] font-[500] text-left' >Copywriting Content</h3>
                    <p className='text-[#768396] text-[.9rem] text-left' >Create content for peceland App</p>
                    <div className='py-2 px-2 font-[500] text-[.8rem] my-1 rounded-lg flex items-center justify-start border-[1px] border-[#E2E2E2] w-[100px]' >
                        <h3>Aug 20, 2021</h3>
                    </div>
                </div>
                <div className='bg-white   px-4  my-2 py-3 rounded-lg' >
                    <button className='py-1 flex items-center justify-start bg-[#E97342] px-3 rounded-md text-[.8rem] text-white' >Planning</button>
                  
                    <h3 className='text-[#232360] font-[500] text-left' >Update requiment list</h3>
                    <p className='text-[#768396] text-[.9rem] text-left' >Create content for peceland App</p>
                    <div className='py-2 px-2 font-[500] text-[.8rem] my-1 rounded-lg flex items-center justify-start border-[1px] border-[#E2E2E2] w-[100px]' >
                        <h3>Aug 20, 2021</h3>
                    </div>
                </div> */}
          </div>

          <div className="mr-2">
            <div className="bg-white flex items-center justify-between px-3 py-2 rounded-lg">
              <h3 className="font-[600] text-[1.2rem]">To Do</h3>
              <div className="flex items-center justify-end gap-2">
                <HiMiniEllipsisHorizontal className="text-[1.2rem] text-[#768396]" />
                <button className="py-2 px-2 rounded-md bg-[#E8EAFF]">
                  <FiPlus
                    className="text-[#6772FE]"
                    onClick={() => {
                      setShowModal(true);
                      setProjectStatus("TODO");
                    }}
                  />
                </button>
              </div>
            </div>
            {isDragged && <DropZone status="TODO" onDrop={handleDrop} />}
            {toDoList.map((item) => (
              <Link to={`/program/${item?.id}`}>
                <DragItem setIsDragged={setIsDragged} item={item} />
              </Link>
            ))}
            {/* <div className='bg-white   px-4  my-2 py-3 rounded-lg' >
                <button className='py-1 flex items-center justify-start bg-[#1EA7FF] px-3 rounded-md text-[.8rem] text-white' >Research</button>
                    
                    <h3 className='text-[#232360] font-[500] text-left' >auditing information architecture</h3>
                    <p className='text-[#768396] text-[.9rem] text-left' >Create content for peceland App</p>
                    <div className='py-2 px-2 font-[500] text-[.8rem] my-1 rounded-lg flex items-center justify-start border-[1px] border-[#E2E2E2] w-[100px]' >
                        <h3>Aug 20, 2021</h3>
                    </div>
                </div>
                <div className='bg-white   px-4  my-2 py-3 rounded-lg' >
                    <button className='py-1 flex items-center justify-start bg-[#F59E0B] px-3 rounded-md text-[.8rem] text-white' >Content</button>
                    <img className='w-full my-3' src={ContentImage} />
                    <h3 className='text-[#232360] font-[500] text-left' >Update support documentation</h3>
                    <p className='text-[#768396] text-[.9rem] text-left' >Create content for peceland App</p>
                    <div className='py-2 px-2 font-[500] text-[.8rem] my-1 rounded-lg flex items-center justify-start border-[1px] border-[#E2E2E2] w-[100px]' >
                        <h3>Aug 20, 2021</h3>
                    </div>
                </div>
                <div className='bg-white   px-4  my-2 py-3 rounded-lg' >
                    <button className='py-1 flex items-center justify-start bg-[#E97342] px-3 rounded-md text-[.8rem] text-white' >Planning</button>
                  
                    <h3 className='text-[#232360] font-[500] text-left' >Update requiment list</h3>
                    <p className='text-[#768396] text-[.9rem] text-left' >Create content for peceland App</p>
                    <div className='py-2 px-2 font-[500] text-[.8rem] my-1 rounded-lg flex items-center justify-start border-[1px] border-[#E2E2E2] w-[100px]' >
                        <h3>Aug 20, 2021</h3>
                    </div>
                </div> */}
          </div>
          <div className="mr-2">
            <div className="bg-white flex items-center justify-between px-3 py-2 rounded-lg">
              <h3 className="font-[600] text-[1.2rem]">In Progress</h3>
              <div className="flex items-center justify-end gap-2">
                <HiMiniEllipsisHorizontal className="text-[1.2rem] text-[#768396]" />
                <button className="py-2 px-2 rounded-md bg-[#E8EAFF]">
                  <FiPlus
                    className="text-[#6772FE]"
                    onClick={() => {
                      setShowModal(true);
                      setProjectStatus("INPROGRESS");
                    }}
                  />
                </button>
              </div>
            </div>
            {isDragged && <DropZone status="INPROGRESS" onDrop={handleDrop} />}
            {inProgressList.map((item) => (
              <Link to={`/program/${item?.id}`}>
                <DragItem setIsDragged={setIsDragged} item={item} />
              </Link>
            ))}
          </div>
          <div className="mr-2">
            <div className="bg-white flex items-center justify-between px-3 py-2 rounded-lg">
              <h3 className="font-[600] text-[1.2rem]">Done</h3>
              <div className="flex items-center justify-end gap-2">
                <HiMiniEllipsisHorizontal className="text-[1.2rem] text-[#768396]" />
                <button className="py-2 px-2 rounded-md bg-[#E8EAFF]">
                  <FiPlus
                    className="text-[#6772FE]"
                    onClick={() => {
                      setShowModal(true);
                      setProjectStatus("DONE");
                    }}
                  />
                </button>
              </div>
            </div>
            {isDragged && <DropZone status="DONE" onDrop={handleDrop} />}
            {reviewList.map((item) => (
              <Link to={`/program/${item?.id}`}>
                <DragItem setIsDragged={setIsDragged} item={item} />
              </Link>
            ))}
          </div>
        </div>
      </DndProvider>
    </div>
  );
};

export default TaskArea;
