import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaChevronDown,
  FaPlus,
  FaChevronUp,
  FaArrowRightFromBracket,
} from "react-icons/fa6";
import DefaultUserIcon from "../../assets/default-user-icon.png";
import {
  leaveChat,
  toggleAddParticipantPopup,
} from "../../actions/chatActions";
import AuthImage from "../AuthImage/AuthImage";

const ProfileRight = () => {
  const dispatch = useDispatch();
  const currentChatId = useSelector((state) => state.chat.currentChatId);
  const chats = useSelector((state) => state.chat.chats);
  const currentUser = useSelector((state) => state.users.currentUser);
  const messages = useSelector(
    (state) => state.chat.messages[currentChatId] || []
  );

  const [attachmentsVisible, setAttachmentsVisible] = useState(true);
  const [membersVisible, setMembersVisible] = useState(true);

  const currentChat = chats.find((chat) => chat.id === currentChatId);
  const otherParticipants =
    currentChat?.participants?.filter(
      (participant) => participant.user.id !== currentUser?.id
    ) || [];

  let otherParticipantName = "";
  if (otherParticipants.length > 1) {
    otherParticipantName = "Group Name";
  } else if (otherParticipants.length === 1) {
    otherParticipantName = `${
      otherParticipants[0].user.firstName || "Unknown"
    } ${otherParticipants[0].user.lastName || "User"}`;
  }
  if (currentChat?.name) {
    otherParticipantName = currentChat.name;
  }

  let otherParticipantUsername = "";
  if (otherParticipants.length > 1) {
    otherParticipantUsername = `Group - ${currentChat.participants.length} member(s)`;
  } else if (otherParticipants.length === 1) {
    otherParticipantUsername = `@${
      otherParticipants[0].user.username || "unknown"
    }`;
  }

  const otherParticipantAvatar =
    otherParticipants.length === 1
      ? otherParticipants[0]?.user.avatar
      : DefaultUserIcon;

  const imageAttachments = useMemo(() => {
    return messages
      .filter((message) => message.imageUrl)
      .map((message) => ({
        id: message.id,
        url: message.imageUrl,
      }));
  }, [messages]);

  const handleAddMemberClick = () => {
    dispatch(toggleAddParticipantPopup(true));
  };

  const toggleAttachments = () => {
    setAttachmentsVisible(!attachmentsVisible);
  };

  const toggleMembers = () => {
    setMembersVisible(!membersVisible);
  };
  console.log("ttttt", chats, "curr:", currentChat);

  const handleLeaveChat = () => {
    const participantId = currentChat?.participants?.find(
      (participant) => participant.user.id === currentUser?.id
    ).id;
    dispatch(leaveChat(currentChatId, participantId));
  };

  return (
    <div
      className="py-3 px-3 z-10"
      style={{ height: "100%", overflowY: "auto" }}
    >
      <div className="flex items-center justify-center flex-col">
        <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
          <AuthImage
            imageurl={otherParticipantAvatar || DefaultUserIcon}
            className="w-full h-full object-cover"
            maxWidth="50px"
            maxHeight="50px"
          />
        </div>
        <p className="font-[600] text-[1.5rem] mt-2">{otherParticipantName}</p>
        <p className="font-[400] text-gray-600 text-[1rem] mt-2">
          {otherParticipantUsername}
        </p>
      </div>
      <div>
        <div
          className="flex items-center justify-between mt-4 cursor-pointer"
          onClick={toggleAttachments}
        >
          <h3 className="font-[600] text-[1rem]">Attachment</h3>
          {attachmentsVisible ? (
            <FaChevronUp className="text-[1rem]" />
          ) : (
            <FaChevronDown className="text-[1rem]" />
          )}
        </div>
        {attachmentsVisible && (
          <div
            className={`grid grid-cols-3 gap-2 mt-3 ${
              imageAttachments.length > 6 ? "overflow-y-auto max-h-[140px]" : ""
            }`}
          >
            {imageAttachments.map((attachment) => (
              <div
                key={attachment.id}
                className="w-full h-[70px] rounded overflow-hidden"
              >
                <AuthImage
                  imageurl={attachment.url}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <div
          className="flex items-center justify-between mt-4 cursor-pointer"
          onClick={toggleMembers}
        >
          <h3 className="font-[600] text-[1rem]">Members</h3>
          {membersVisible ? (
            <FaChevronUp className="text-[1rem]" />
          ) : (
            <FaChevronDown className="text-[1rem]" />
          )}
        </div>
        {membersVisible && !currentChat?.programId && (
          <div className="flex my-6 items-center justify-start gap-6">
            <button
              className="flex items-center gap-2 text-[#5051F9] font-bold py-2 px-4 rounded"
              onClick={handleAddMemberClick}
            >
              <FaPlus />
              Add Member
            </button>
          </div>
        )}
        {membersVisible && (
          <div className=" overflow-y-auto" style={{ maxHeight: "250px" }}>
            {currentChat?.participants?.map((member, index) => (
              <div
                key={index}
                className="flex mt-3 items-center justify-start gap-6"
              >
                <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
                  <AuthImage
                    imageurl={member.user.avatar || DefaultUserIcon}
                    className="w-full h-full object-cover"
                    maxWidth="50px"
                    maxHeight="50px"
                  />
                </div>
                <div>
                  <h6 className="my-1 font-[600] text-[0.8rem]">
                    {member.user.firstName} {member.user.lastName}
                  </h6>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {currentChat?.name && (
        <div
          className="flex justify-center items-center m-2 text-center cursor-pointer text-[#41D37E]"
          onClick={() => handleLeaveChat()}
        >
          <FaArrowRightFromBracket /> &nbsp;Leave Chat
        </div>
      )}
    </div>
  );
};

export default ProfileRight;
