import React from "react";
import { Shield } from "lucide-react";

const SecuritySettings = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Security Settings</h2>
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-4">
          <Shield className="w-8 h-8 text-blue-500 mr-4" />
          <h3 className="text-xl font-semibold">Manage Your Security</h3>
        </div>
        <p className="text-gray-600 mb-4">
          Configure your account security options.
        </p>
        <p>Security settings coming sooon!!</p>
      </div>
    </div>
  );
};

export default SecuritySettings;
