import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { ACCESS_TOKEN, API_BASE_URL } from "../../constantsNew";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { get } from "../../util/APIUtils";

const WaveformPlayer = ({ message, setActivity, sendActivity }) => {
  const fileUpload = message.fileUpload;
  const [audioData, setAudioData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { transcriptionJobStatus } = useSelector(
    (state) => state.websocketMessages
  );
  // const [transcribedMessage, setTranscribedMessage] = useState({});
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  const fetchAudioFile = async () => {
    try {
      const headers = new Headers({
        "Content-Type": "application/json",
      });
      if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append(
          "Authorization",
          "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        );
      }
      const response = await fetch(
        API_BASE_URL + `/post-service/api/file-uploads/${fileUpload.id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const data = await response.arrayBuffer();
      const blob = new Blob([data], { type: "audio/wav" });
      const url = URL.createObjectURL(blob);
      setAudioData(url);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching audio file:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAudioFile();
  }, []);

  useEffect(() => {
    if (audioData && waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#fff",
        progressColor: "#cabcf8",
        barWidth: 2,
        barHeight: 30,
        height: 35,
        width: 250,
        // cursorWidth: 0,
      });

      wavesurfer.current.load(audioData);

      wavesurfer.current.on("ready", () => {
        setIsLoading(false);
      });

      return () => {
        if (wavesurfer.current) {
          wavesurfer.current.destroy();
          wavesurfer.current = null;
        }
      };
    }
  }, [audioData]);

  const handlePlayPause = () => {
    if (wavesurfer.current.isPlaying()) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
    }
  };

  // async function fetchTranscriptionData(id) {
  //   const response = await get(`/post-service/api/transcriptionJobs/${id}`);
  //   console.log("transcription", response);
  //   setTranscribedMessage({ ...transcribedMessage, id: response });
  // }

  // useEffect(() => {
  //   if (transcriptionJobStatus && transcriptionJobStatus.jobId) {
  //     fetchTranscriptionData(transcriptionJobStatus.jobId);
  //   }
  // }, [transcriptionJobStatus]);

  return (
    <div className="w-full max-w-xl flex items-center justify-center rounded-full px-4 ">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <TailSpin
            height="10"
            width="10"
            color="#cabcf5"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="flex flex-row">
            <div className="flex items-center justify-center bg-gray-200 hover:bg-gray-300 rounded-full p-2 mr-4">
              <button onClick={handlePlayPause} className="p-1">
                <svg
                  className="w-4 h-4 text-gray-700"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {wavesurfer.current && wavesurfer.current.isPlaying() ? (
                    <path
                      fillRule="evenodd"
                      d="M5 4h4v16H5V4zm10 0h4v16h-4V4z"
                      clipRule="evenodd"
                    />
                  ) : (
                    <path
                      fillRule="evenodd"
                      d="M6 4.5v15l12-7.5-12-7.5z"
                      clipRule="evenodd"
                    />
                  )}
                </svg>
              </button>
            </div>
            <div
              ref={waveformRef}
              className="w-full"
              style={{ padding: 0 }}
            ></div>
          </div>
          <div className="flex justify-start w-full ">
            {/* {transcribedMessage &&
              transcribedMessage.id &&
              message.transcriptionData &&
              message.transcriptionData.transcribeId ==
                transcribedMessage.id.jobName.split("transcription-")[1] && (
                <div className="w-full mt-4">
                  <div className="flex justify-start w-full">
                    Here’s what we gathered:
                  </div>
                  <div className="flex justify-center w-full">
                    '{transcribedMessage?.id?.results[0]}'
                  </div>
                  <div>
                    <div className="flex flex-row justify-start items-center mt-2">
                      Is this correct?
                      <button
                        className="text-white mx-2 w-[60px] rounded-lg text-[0.8rem] py-1 px-2 bg-[#fafafa4e]"
                        onClick={() => {
                          setActivity(transcribedMessage?.id?.results[0]);
                          sendActivity();
                        }}
                      >
                        Yes
                      </button>
                      <button className="text-white mx-2 w-[60px] rounded-lg text-[0.8rem] py-1 px-2 bg-[#fafafa4e]">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default WaveformPlayer;
