import { useRef } from 'react';
import './LiveNotes.css';
import { RiFileUploadLine } from "react-icons/ri";
import PaperclipIcon from "../../assets/paperclip.svg"
export const FileUploader = ({ handleFile }) => {
  const hiddenFileInput = useRef(null);

  // Programmatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  // Call the handleFile function directly with the file
  const handleChange = event => {
    const file = event.target.files[0];
    if (file) {
      // Directly call handleFile with the file
      handleFile(file);
    }
  };

  return (
    <>
      <button className="button-upload" onClick={handleClick}>
        <img src={PaperclipIcon} className='mx-1 w-[0.8rem] lg:w-[1.1rem]' />
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}} // Make the file input element invisible
        accept="audio/*, video/*, image/*" // Optionally, restrict file types
      />
    </>
  );
};