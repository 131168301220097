import { message } from "antd";
import { API_BASE_URL, ACCESS_TOKEN, CMS_BASE_URL } from "../constantsNew";
import moment from "moment-timezone";
const request = (options) => {
  let headersTemp = {};
  if (!options.type || (options.type && options.type !== "fileUpload")) {
    headersTemp["Content-Type"] = "application/json";
  }

  const headers = new Headers(headersTemp);

  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    headers.set("Authorization", "Bearer " + accessToken);
  }

  if (options.additionalHeaders) {
    for (const [key, value] of Object.entries(options.additionalHeaders)) {
      if (key.toLowerCase() !== "authorization") {
        headers.set(key, value);
      }
    }
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  console.log("CONFIG==>", options);
  return fetch(options.url, options).then((response) => {
    console.log(response);
    if (response.status === 204) {
      return "OK";
    }
    return response
      .json()
      .then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
      .catch((error) => {
        console.error("Error:", error);
        if (response.status === 200) {
          return "OK";
        } else {
          return Promise.reject(error);
        }
      });
  });
};

export const custom_request = (options) => {
  return request(options);
};
export const getFile = (req) => {
  const headers = new Headers({});
  // return request(req);
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }
  req.headers = headers;
  return fetch(req.url, req);
};
export const get = (url, additionalHeaders, completeUrl) => {
  let urlTemp;
  if (completeUrl) {
    urlTemp = url;
  } else {
    urlTemp = `${API_BASE_URL}${url}`;
  }
  const options = {
    url: urlTemp,
    method: "GET",
    additionalHeaders: additionalHeaders,
  };
  return request(options);
};

export const post = (url, body, additionalHeaders, type) => {
  const options = {
    url: `${API_BASE_URL}${url}`,
    method: "POST",
    body: type && type === "fileUpload" ? body : JSON.stringify(body),
    additionalHeaders: additionalHeaders,
  };
  if (type) {
    options.type = type;
  }
  return request(options);
};

export const put = (url, body, additionalHeaders) => {
  const options = {
    url: `${API_BASE_URL}${url}`,
    method: "PUT",
    body: JSON.stringify(body),
    additionalHeaders: additionalHeaders,
  };
  return request(options);
};

export const patch = (url, body, additionalHeaders) => {
  const options = {
    url: `${API_BASE_URL}${url}`,
    method: "PATCH",
    body: JSON.stringify(body),
    additionalHeaders: additionalHeaders,
  };
  return request(options);
};

export const del = (url, additionalHeaders) => {
  const options = {
    url: `${API_BASE_URL}${url}`,
    method: "DELETE",
    additionalHeaders: additionalHeaders,
  };
  return request(options);
};

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/stridecal-backend/api/user/me",
    method: "GET",
  });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/stridecal-backend/api/auth/signin",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/stridecal-backend/api/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function onboard(onboardRequest, id) {
  return request({
    url: API_BASE_URL + `/stridecal-backend/api/app-users/${id}`,
    method: "PATCH",
    body: JSON.stringify(onboardRequest),
  });
}

export function chatWithAi(activity, userName) {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    message.error("User not logged in.");
    return Promise.reject("No access token set.");
  }

  return request({
    url: CMS_BASE_URL + "/api/agent/live-notes-x",
    method: "POST",
    body: JSON.stringify({
      messages: activity,
      thread_id: localStorage.getItem(ACCESS_TOKEN),
      timezone: moment.tz.guess(),
      user_id: userName,
    }),
  }).catch((error) => {
    message.error({
      error:
        "Failed to chat with AI. Sorry for inconvinience, we are working on it.",
      duration: 5,
    });
    return Promise.reject(error); // Forward the error if needed
  });
}

export function sendOTP(otpRequest) {
  return request({
    url: API_BASE_URL + "/stridecal-backend/api/auth/generateOtp",
    method: "POST",
    body: JSON.stringify(otpRequest),
  });
}
export function verifyOTP(otpRequest) {
  return request({
    url: API_BASE_URL + "/stridecal-backend/api/auth/verifyOtp",
    method: "POST",
    body: JSON.stringify(otpRequest),
  });
}
