import React, { useRef, useEffect, useState } from "react";
import {
  Trash2,
  Edit2,
  Pin,
  Copy,
  Forward,
  Reply,
  Check,
  X,
} from "lucide-react";

const MessageActions = ({
  message,
  currentUser,
  onClose,
  onDelete,
  onEdit,
  onPin,
  onCopy,
  onForward,
  onReply,
  isEditing,
  onEditSubmit,
  onEditCancel,
}) => {
  const actionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const ActionButton = ({ onClick, icon: Icon, label, color = "gray" }) => (
    <div className="group relative">
      <button
        onClick={() => {
          onClick(message.id);
          if (label !== "Edit") {
            onClose();
          }
        }}
        className={`p-2 rounded-full hover:bg-gray-100 transition-all duration-200
          ${color === "red" ? "hover:bg-red-50" : ""}
          ${color === "blue" ? "hover:bg-blue-50" : ""}
          ${color === "green" ? "hover:bg-green-50" : ""}
        `}
      >
        <Icon
          size={18}
          className={`
            ${color === "red" ? "text-red-500" : ""}
            ${color === "blue" ? "text-blue-500" : ""}
            ${color === "green" ? "text-green-500" : ""}
            ${color === "gray" ? "text-gray-600" : ""}
          `}
        />
      </button>
      <div className="absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 -bottom-8 left-1/2 -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap">
        {label}
      </div>
    </div>
  );

  return (
    <div
      ref={actionsRef}
      className="absolute right-0 top-0 bg-white shadow-lg rounded-full px-2 py-1 flex items-center space-x-1 z-10"
    >
      {isEditing ? (
        <>
          <ActionButton
            onClick={onEditSubmit}
            icon={Check}
            label="Save"
            color="green"
          />
          <ActionButton
            onClick={onEditCancel}
            icon={X}
            label="Cancel"
            color="red"
          />
        </>
      ) : (
        <>
          <ActionButton
            onClick={onDelete}
            icon={Trash2}
            label="Delete"
            color="red"
          />
          {message.authorId === currentUser?.id && (
            <ActionButton
              onClick={onEdit}
              icon={Edit2}
              label="Edit"
              color="blue"
            />
          )}
          <ActionButton onClick={onPin} icon={Pin} label="Pin" />
          <ActionButton onClick={onCopy} icon={Copy} label="Copy" />
          <ActionButton onClick={onForward} icon={Forward} label="Forward" />
          <ActionButton onClick={onReply} icon={Reply} label="Reply" />
        </>
      )}
    </div>
  );
};

export default MessageActions;
