import { get, post, custom_request, patch, del, put } from "../util/APIUtils";
import { CHAT_API_BASE_URL } from "../constantsNew";
import WebsocketService from "../common/WebsocketService";

// Action Types
export const FETCH_CHATS = "FETCH_CHATS";
export const CREATE_CHAT = "CREATE_CHAT";
export const FETCH_PARTICIPANTS = "FETCH_PARTICIPANTS";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const CLEAR_SEARCHED_USERS = "CLEAR_SEARCHED_USERS";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
export const REFRESH_CHAT = "REFRESH_CHAT";
export const TYPING_STATUS = "TYPING_STATUS";
export const TYPING_STATUS_CLEAR = "TYPING_STATUS_CLEAR";
export const SET_SEARCHED_USERS = "SET_SEARCHED_USERS";
export const SET_CHAT_SEARCHED_USERS = "SET_CHAT_SEARCHED_USERS";
export const CLEAR_CHAT_SEARCHED_USERS = "CLEAR_CHAT_SEARCHED_USERS";
export const TOGGLE_ADD_PARTICIPANT_POPUP = "TOGGLE_ADD_PARTICIPANT_POPUP";
export const RENAME_CHAT = "RENAME_CHAT";
export const DELETE_CHAT = "DELETE_CHAT";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const NEW_NOTIFICATIONS = "NEW_NOTIFICATIONS";
export const NEW_NOTIFICATIONS_ARRAY = "NEW_NOTIFICATIONS_ARRAY";
export const PIN_MESSAGE = "PIN_MESSAGE";
export const UNPIN_MESSAGE = "UNPIN_MESSAGE";
export const EDIT_MESSAGE = "EDIT_MESSAGE";

// Fetch chats for current user
export const fetchChats = () => async (dispatch, getState) => {
  const state = getState();
  const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
  const options = {
    url: `${CHAT_API_BASE_URL}/ui/v1/chats/users`,
    method: "GET",
    additionalHeaders: additionalHeaders,
  };
  const response = await custom_request(options);

  dispatch({ type: FETCH_CHATS, payload: response });
};

// Create a new chat with a participant
export const createChat = (userId) => async (dispatch, getState) => {
  const state = getState();
  const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
  const response = await get(
    `/chat-service/ui/v1/chats/create/${userId}`,
    additionalHeaders
  );
  dispatch({ type: CREATE_CHAT, payload: response });
  dispatch(setCurrentChat(response.id));
};

// Fetch participants for a specific chat
// Donot use this method, not implemented in backend
export const fetchParticipants =
  (participantId, chatId) => async (dispatch, getState) => {
    const state = getState();
    const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
    const response = await get(
      `/ui/v1/chats/participant/${participantId}/${chatId}`,
      additionalHeaders
    );
    dispatch({ type: FETCH_PARTICIPANTS, payload: response, chatId });
  };

// Fetch searched user
export const fetchSearchedUser =
  (searchedUser) => async (dispatch, getState) => {
    const state = getState();
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const additionalHeaders = {
      Authorization: `Bearer ${accessToken}`,
      "X-auth-user-id": state.users.currentUser.id,
    };
    const options = {
      url: `${CHAT_API_BASE_URL}/ui/v1/chats/items/search/${searchedUser}`,
      method: "GET",
      additionalHeaders: additionalHeaders,
    };

    try {
      const response = await custom_request(options);
      dispatch({ type: SET_SEARCHED_USERS, payload: response });
    } catch (error) {
      console.error("Error fetching searched users:", error);
    }
  };

// Add a participant to a chat
export const addParticipant =
  (chatId, userId) => async (dispatch, getState) => {
    const state = getState();
    const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
    const response = await post(
      `/chat-service/ui/v1/chats/${chatId}/participants/${userId}`,
      additionalHeaders
    );
    const newParticipant = { user: response }; // Wrap the response in a user object
    dispatch({ type: ADD_PARTICIPANT, payload: { chatId, newParticipant } });
  };

// Fetch messages for a specific chat
export const fetchChatMessages = (chatId) => async (dispatch, getState) => {
  const state = getState();
  const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
  const response = await get(
    `/chat-service/ui/v1/chats/${chatId}/messages`,
    additionalHeaders
  );
  dispatch({ type: FETCH_MESSAGES, payload: { chatId, messages: response } });
};

export const sendTypingStatus =
  (messageObj, otherParticipant) => (dispatch) => {
    console.log("Sending typing status", otherParticipant);

    if (otherParticipant && otherParticipant.length > 0) {
      otherParticipant.map((p) => {
        WebsocketService.send(`/topic/chat/${p.user.id}`, messageObj);
      });
    }
  };
// Send a message in a chat
export const sendMessage =
  (chatId, text, imageUrl) => async (dispatch, getState) => {
    const state = getState();
    const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
    const payload = { text };
    if (imageUrl) {
      payload.imageUrl = imageUrl;
    }
    let response = await post(
      `/chat-service/ui/v1/chats/${chatId}/add/message`,
      payload,
      additionalHeaders
    );
    console.log("Add message response=====>", response);
    let message = response[state.users.currentUser.id];
    console.log("Add message response=====>", message);
    dispatch({ type: SEND_MESSAGE, payload: { chatId, message } });
  };

// Set current chat
export const setCurrentChat = (chatId) => ({
  type: SET_CURRENT_CHAT,
  payload: chatId,
});

export const uploadFile = (file, callback) => async (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);
  console.log("Uploading...");
  try {
    const response = await post(
      "/post-service/api/file-uploads",
      formData,
      undefined,
      "fileUpload"
    );
    if (callback) callback(response);
    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

// New action creator for ChattingArea search
export const fetchChatSearchedUser =
  (searchedUser) => async (dispatch, getState) => {
    const state = getState();
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const additionalHeaders = {
      Authorization: `Bearer ${accessToken}`,
      "X-auth-user-id": state.users.currentUser.id,
    };
    const options = {
      url: `${CHAT_API_BASE_URL}/ui/v1/chats/items/search/${searchedUser}`,
      method: "GET",
      additionalHeaders: additionalHeaders,
    };

    try {
      const response = await custom_request(options);
      dispatch({ type: SET_CHAT_SEARCHED_USERS, payload: response });
    } catch (error) {
      console.error("Error fetching searched users for chat:", error);
    }
  };

export const toggleAddParticipantPopup = (show) => ({
  type: TOGGLE_ADD_PARTICIPANT_POPUP,
  payload: show,
});

// Add this new action creator
export const renameChat = (chatId, newName) => async (dispatch, getState) => {
  const state = getState();
  const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
  try {
    const response = await patch(
      `/chat-service/ui/v1/chats/${chatId}/name?name=${encodeURIComponent(
        newName
      )}`,
      additionalHeaders
    );
    dispatch({
      type: RENAME_CHAT,
      payload: { chatId, newName: response.name },
    });
  } catch (error) {
    console.error("Error renaming chat:", error);
  }
};

export const leaveChat = (chatId, participantId) => async (dispatch) => {
  console.log("demn", participantId, ":", chatId);
  await get(`/chat-service/ui/v1/chats/leave/${participantId}/${chatId}`);
  dispatch({ type: DELETE_CHAT, payload: chatId });
};

export const deleteMessage = (msgId, currentChatId) => async (dispatch) => {
  try {
    await del(`/chat-service/ui/v1/chats/${msgId}/delete`);
    dispatch({ type: DELETE_MESSAGE, payload: { msgId, currentChatId } });
  } catch (error) {
    console.error("Error fetching searched users for chat:", error);
  }
};

export const pinMessage = (chatId, messageId) => async (dispatch, getState) => {
  const state = getState();
  const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
  try {
    await put(
      `/chat-service/ui/v1/chats/${chatId}/pin/${messageId}`,
      {},
      additionalHeaders
    );
    dispatch({
      type: PIN_MESSAGE,
      payload: { chatId, messageId },
    });
  } catch (error) {
    console.error("Error pinning message:", error);
  }
};

export const unpinMessage =
  (chatId, messageId) => async (dispatch, getState) => {
    const state = getState();
    const additionalHeaders = { "X-auth-user-id": state.users.currentUser.id };
    try {
      await put(
        `/chat-service/ui/v1/chats/${chatId}/unpin/${messageId}`,
        {},
        additionalHeaders
      );
      dispatch({
        type: UNPIN_MESSAGE,
        payload: { chatId, messageId },
      });
    } catch (error) {
      console.error("Error unpinning message:", error);
    }
  };

export const editMessage =
  (chatId, messageId, text) => async (dispatch, getState) => {
    const state = getState();
    const additionalHeaders = {
      "X-auth-user-id": state.users.currentUser.id,
      "Content-Type": "text/plain",
    };
    try {
      const response = await put(
        `/chat-service/ui/v1/chats/${chatId}/messages/${messageId}`,
        { text }
      );

      console.log(response, "ttt--->>>");

      const updatedMessage = response.text || response.message || response;

      dispatch({
        type: EDIT_MESSAGE,
        payload: {
          chatId,
          messageId,
          updatedMessage,
        },
      });
      dispatch(fetchChatMessages(chatId));
    } catch (error) {
      console.error("Error editing message:", error);
      throw error;
    }
  };
