import React, { useState } from "react";
import { Modal, Button } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import preppyDog from "../../assets/preppyDog.png";

const AddNoteModal = ({ visible, onClose, onSave }) => {
  const [noteContent, setNoteContent] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleSave = () => {
    onSave({
      content: noteContent,
    });
    handleReset();
    onClose();
  };

  const handleReset = () => {
    setNoteContent("");
    setIsValid(false);
  };

  const handleCancel = () => {
    handleReset();
    onClose();
  };

  const handleEditorChange = (data) => {
    setNoteContent(data);
    setIsValid(data.trim().length > 0);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await getBase64(file);
        return {
          default: base64,
        };
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <Modal
      width={800}
      height={1000}
      title={
        <div className="flex items-center justify-between">
          <img
            src={preppyDog}
            alt="preppy-dog"
            className="w-48 object-contain h-36 mr-4"
          />
          <h2 className="text-xl font-semibold text-gray-800 flex-grow text-center">
            ADD NOTE
          </h2>
          <div className="w-48" />
        </div>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          disabled={!isValid}
          className="bg-blue-500 text-white hover:bg-blue-600"
        >
          Save
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "60vh", overflow: "auto" }}
    >
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <CKEditor
          editor={ClassicEditor}
          config={{
            extraPlugins: [uploadPlugin],
          }}
          data={noteContent}
          onChange={(event, editor) => {
            const data = editor.getData();
            handleEditorChange(data);
          }}
        />
      </div>
    </Modal>
  );
};

export default AddNoteModal;
