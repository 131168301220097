import { API_BASE_URL } from '../constantsNew';
import { calendarEvents } from '../data/CalendarEvents';
import { get, post, put, del, patch } from '../util/APIUtils';
import { notification } from 'antd';
export const FETCH_PROGRAMS = 'FETCH_PROGRAMS';
export const SAVE_PROGRAM = 'SAVE_PROGRAM';
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const UPDATE_PROGRAM_SCHEDULE ="UPDATE_PROGRAM_SCHEDULE";
export const DELETE_PROGRAM = 'DELETE_PROGRAM';

export const fetchPrograms = (market,page = 0, size = 10) => {
  return async (dispatch) => {
    const response = await get(`/organization-service/api/academicPrograms?page=${page}&size=${size}&market=${market ? market : false}`);
    dispatch({ type: FETCH_PROGRAMS, payload: response });
  };
};

export const saveProgram = (programData) => {
  return async (dispatch) => {
    const response = await post('/organization-service/api/academicPrograms', programData);
    dispatch({ type: SAVE_PROGRAM, payload: response });
    return response;
  };
};

export const updateProgram = (id, programData) => {
  return async (dispatch) => {
    delete programData.createdDate;
    delete programData.updatedDate;
    delete programData.schedules;
    delete programData.rewardConfigs;
    delete programData.chat;
    const response = await patch(`/organization-service/api/academicPrograms/${id}`, programData);
    dispatch({ type: UPDATE_PROGRAM, payload: response });
  };
};

export const deleteProgram = (id) => {
  return async (dispatch) => {
    await del(`/organization-service/api/academicPrograms/${id}`);
    dispatch({ type: DELETE_PROGRAM, payload: id });
  };
};

export const fetchProgram = (id,setProgram,setLoading) => {
  return async (dispatch) => {
    const response = await get(`/organization-service/api/academicPrograms/${id}`);
    // dispatch({ type: FETCH_PROGRAM, payload: response });

    setProgram(response)
    setLoading(false)
  };
};

export const fetchRewardConfigTypes = (setRewardConfigTypes) => {
  return async (dispatch) => {
    const response = await get(`/organization-service/api/academicPrograms/search/rewardConfigTypes`);
    // dispatch({ type: FETCH_PROGRAM, payload: response });

    setRewardConfigTypes(response)
    // setLoading(false)
  };
};

export const saveRewardConfig = (id,rewardConfig,setSavingConfig,setShowModal,setRewardConfigs)=>{
  return async (dispatch)=>{
    post(`/organization-service/api/academicPrograms/${id}/rewardConfigs`,rewardConfig).then(res=>{
      setSavingConfig(false)
      setShowModal(false)
      setRewardConfigs(res.rewardConfigs)
    }).catch(error=>{
      console.log(error)
      setSavingConfig(false)
      // setShowModal(false)

      notification.error({message:error.message})
    });
    
    // dispatch({ type: FETCH_PROGRAM, payload: response });
    
    // setRewardConfigTypes(response)
  }
}

export const createEvent = (calendarEvent, setEventLink,setEventLoading) => {
  return async (dispatch) => {
    const response = await post(`/calendar-service/api/events`,calendarEvent);
    // dispatch({ type: FETCH_PROGRAM, payload: response });
    let schedule = {
      rosterCalendarEventLinks:`${API_BASE_URL}/calendar-service/api/events/${response.id}`
    }
    //const response = await post(`/calendar-service/api/events`,calendarEvent);
    setEventLink(`${API_BASE_URL}/calendar-service/api/events/${response.id}`)
    setEventLoading(false)
  };
}



export const createSchedule = (id,schedule,setProgram,setEventLink,setEventLoading) => {
  return async (dispatch) => {
    //  schedule.academicProgram=`${API_BASE_URL}/organization-service/api/academicPrograms/${id}`;
    const response = await patch(`/organization-service/api/academicPrograms/${id}/schedules`,schedule);
    // dispatch({ type: FETCH_PROGRAM, payload: response });
    setProgram(response)
    //const response = await post(`/calendar-service/api/events`,calendarEvent);
    //setEventLink(`${API_BASE_URL}/calendar-service/api/events/${response.id}`)
    setEventLoading(false)
  };
}

export const createTask = (id,task,setProgram,setTaskLoading) => {
  return async (dispatch) => {
    let response;
    task.isCompleted=false;
    //  schedule.academicProgram=`${API_BASE_URL}/organization-service/api/academicPrograms/${id}`;
    if(task.id){
      response = await put(`/organization-service/api/academicPrograms/${id}/tasks/${task.id}`,task);      
    } else {
      response = await post(`/organization-service/api/academicPrograms/${id}/tasks`,task);
    }
    // dispatch({ type: FETCH_PROGRAM, payload: response });
    setProgram(response)
    //const response = await post(`/calendar-service/api/events`,calendarEvent);
    //setEventLink(`${API_BASE_URL}/calendar-service/api/events/${response.id}`)
    setTaskLoading(false)
  };
}

export const uploadFile = (file,setTask) => {
  return async (dispatch)=>{
    const formData = new FormData();
    formData.append('file', file);

    const response = await post(`/post-service/api/file-uploads`,formData, undefined , 'fileUpload');
    setTask((prev)=>({...prev,fileUrl:`/post-service/api/file-uploads/${response.id}`}))
  }
}


export const subscribe = (program,onSubscribe) => {
  return async (dispatch) => {
    post(`/organization-service/api/academicPrograms/${program.id}/subscribe`)
    .then((res)=>{
      //return res.json();
      dispatch({ type: UPDATE_PROGRAM, payload: res });
      onSubscribe({res,message:"Program subscription successful"})
    }).catch(er=>{
      console.log("Error",er)
      onSubscribe({res:er});
    });
    // dispatch({ type: FETCH_PROGRAM, payload: response });
    
  };
}

export const unsubscribe = (program,onSubscribe) => {
  return async (dispatch) => {
    post(`/organization-service/api/academicPrograms/${program.id}/unsubscribe`)
    .then((res)=>{
      //return res.json();
      dispatch({ type: UPDATE_PROGRAM, payload: res });
      onSubscribe({res,message:"Program unsubscribed successfully"})
    }).catch(er=>{
      onSubscribe({res:er})
    });
    // dispatch({ type: FETCH_PROGRAM, payload: response });
    
  };
}

export const publish = (program,r,onPublished) => {
  return async (dispatch) => {
    let url=`/organization-service/api/academicPrograms/${program.id}/unpublish`
    if(r){
        url =`/organization-service/api/academicPrograms/${program.id}/publish`
    }
    patch(url)
    .then((res)=>{
      //return res.json();
      dispatch({ type: UPDATE_PROGRAM, payload: res });
      onPublished(res)
    }).catch(er=>{
      onPublished(er)
    });
    // dispatch({ type: FETCH_PROGRAM, payload: response });
    
  };
}