import React, { useState, useRef, useEffect } from "react";
import { BiPlus, BiCheck, BiX, BiTrash } from "react-icons/bi";
import AddNoteModal from "./AddNoteModal";
import { Confirm } from "react-st-modal";

const NotesSection = ({ project, handleSaveNote, handleDeleteNote }) => {
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showInlineAdd, setShowInlineAdd] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState("");

  const inputRef = useRef(null);
  const saveButtonRef = useRef(null);

  useEffect(() => {
    if (showInlineAdd && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInlineAdd]);

  const convertToHtml = (text) => {
    const withLineBreaks = text.replace(/\n/g, "<br>");
    return `<p>${withLineBreaks}</p>`;
  };

  const handleInlineAddNote = () => {
    if (newNoteContent.trim()) {
      const htmlContent = convertToHtml(newNoteContent);
      handleSaveNote({ content: htmlContent });
      setNewNoteContent("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleInlineAddNote();
    }
  };

  const handleClickOutside = (e) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      saveButtonRef.current &&
      !saveButtonRef.current.contains(e.target)
    ) {
      setShowInlineAdd(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeleteClick = async (e, noteId) => {
    e.stopPropagation();
    const isConfirmed = await Confirm(
      "Are you sure you want to delete this note?",
      "Confirm Deletion"
    );
    if (isConfirmed) {
      handleDeleteNote(project.id, noteId);
    }
  };

  return (
    <div className="w-full space-y-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-bold text-gray-800">Notes</h3>
        {!project.programId && (
          <button
            onClick={() => setShowNoteModal(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 transition duration-300 flex items-center"
          >
            <BiPlus size={20} className="mr-2" />
            <span>Add Note</span>
          </button>
        )}
      </div>
      <div className="bg-white rounded-lg shadow-md border border-gray-200">
        {showInlineAdd ? (
          <div className="p-4 bg-gray-100 border-b border-gray-200 rounded-t-lg">
            <div className="flex items-start">
              <textarea
                ref={inputRef}
                value={newNoteContent}
                onChange={(e) => setNewNoteContent(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Enter quick note"
                className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent h-24 resize-none shadow-sm"
              />
              <div className="flex flex-col ml-2">
                <button
                  ref={saveButtonRef}
                  onClick={handleInlineAddNote}
                  className="px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 mb-2 shadow-sm"
                >
                  <BiCheck size={20} />
                </button>
                <button
                  onClick={() => setShowInlineAdd(false)}
                  className="px-3 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300 shadow-sm"
                >
                  <BiX size={20} />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!project.programId && (
              <div
                className="p-4 bg-gray-50 border-b border-gray-200 rounded-t-lg flex items-center text-blue-600 hover:text-blue-800 cursor-pointer transition-colors duration-200"
                onClick={() => setShowInlineAdd(true)}
              >
                <BiPlus size={20} className="mr-2" />
                <span className="text-sm font-medium">Quick Add Note</span>
              </div>
            )}
          </>
        )}
        <div className="space-y-4 p-4">
          {project?.notes.length > 0 ? (
            project.notes.map((note, index) => (
              <div
                key={index}
                className="bg-white rounded shadow-sm p-4 border border-gray-200 relative group"
              >
                <div
                  className="text-base text-gray-800 pr-8"
                  dangerouslySetInnerHTML={{ __html: note.content }}
                />
                {project.adminId === null && (
                  <button
                    onClick={(e) => handleDeleteClick(e, note.id)}
                    className="absolute top-2 right-2 p-1 text-red-500"
                  >
                    <BiTrash size={18} />
                  </button>
                )}
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500">No Notes added</div>
          )}
        </div>
      </div>
      <AddNoteModal
        visible={showNoteModal}
        onClose={() => setShowNoteModal(false)}
        onSave={handleSaveNote}
      />
    </div>
  );
};

export default NotesSection;
