const initialState = { posts: [] };
const postsReducer = (state = initialState, action) => {
  // const postsReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_POST":
      console.log("Adding post", action.payload, state);
      let id = state.length + 1;
      action.payload.id = id;
      return { posts: [...state.posts, action.payload.message] };
    case "FETCH_POSTS":
      return { posts: [...action.payload] };
    case "FETCH_EARLIER_POSTS":
      return { posts: [...action.payload, ...state.posts] };
    case "EDIT_POST":
      let tempArr = [...state.posts];
      state.posts.map((post, index) => {
        if (post.id == action.id) {
          tempArr[index] = action.payload;
        }
      });
      console.log("post reducer", tempArr);
      return {
        posts: [...tempArr],
      };
    case "LIST_POSTS":
      return state;
    case "DELETE_POST":
      let tempArr2 = [...state.posts];
      tempArr2 = tempArr2.filter((post) => post.id !== action.payload);
      return { ...state, posts: [...tempArr2] };
    default:
      return state;
  }
};

module.exports = postsReducer;
