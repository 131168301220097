import React, { useEffect, useRef, useState } from "react";
import {
  BiArrowBack,
  BiPlus,
  BiLoader,
  BiCalendar,
  BiCheck,
  BiX,
  BiImage,
  BiTrash,
  BiImageAdd,
} from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import preppyDog from "../../assets/preppyDog.png";
import CreateScheduleModal from "./CreateScheduleModal";
import EditableContent from "../EditableContent";
import Post from "./Post";
import AuthImage from "../AuthImage/AuthImage";
import ProgramTask from "../../page/AcademicPrograms/ProgramTask";
import AddLabelModal from "./AddLabelModal";
import AddLinkModal from "./AddLinkModal";
import Modal from "../../components/Planner/Modal";
import { RiImageEditLine } from "react-icons/ri";
import { del, put } from "../../util/APIUtils";
import {
  addNotes,
  addTodos,
  addWebLinks,
  createSchedule,
  deleteNote,
  deleteTodo,
  updateTodo,
} from "../../actions/userProjectActions";
import ProjectSchedule from "./ProjectSchedule";
import AddTodoModal from "./AddTodoModal";
import AddNoteModal from "./AddNoteModal";
import TodosSection from "./TodosSection";
import NotesSection from "./NotesSection";
import WebLinksSection from "./WebLinksSection";
import { message, Tooltip } from "antd";
import { Confirm } from "react-st-modal";
import moment from "moment";
import { uploadFile } from "../../actions/chatActions";
import { MdOutlineNewLabel } from "react-icons/md";

const ProjectItems = ({ project, fetchProjects }) => {
  console.log("USEEFFECT CALLED: ", 0 + 1);
  const tabs = [
    { id: "posts", label: "Posts" },
    { id: "schedules", label: "Schedules" },
    // { id: "tasks", label: "Tasks" },
    { id: "weblinks", label: "Web Links" },
    { id: "todos", label: "Todos" },
    { id: "notes", label: "Notes" },
  ];
  if (project.programId) {
    tabs.push({ id: "chats", label: "Program Chat" });
  }

  const { id, "*": activeTabParam } = useParams();

  const [showLabelModal, setShowLabelModal] = useState(false);
  const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false);
  const [labels, setLabels] = useState(project.labels);
  const [activeTab, setActiveTab] = useState("posts");
  const actionMenu = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [schedulesUpdated, setSchedulesUpdated] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [projectData, setProjectData] = useState();
  const [scheduleData, setScheduleData] = useState({
    scheduleName: "",
  });

  const isScheduleNameEmpty = scheduleData.scheduleName.trim() === "";

  const [showAddLinkModal, setShowAddLinkModal] = useState(false);
  const [showTodoModal, setShowTodoModal] = useState(false);
  const [ShowAddNoteModal, setShowAddNoteModal] = useState(false);

  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setActiveTab(activeTabParam || "posts");
  }, [activeTabParam]);

  useEffect(() => {
    setProjectData(project);
  }, [project]);

  console.log("project dat", project);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      await dispatch(uploadFile(file, handleImageUploadResponse));
    }
  };

  const handleImageUploadResponse = async (response) => {
    const updatedProjectData = {
      ...projectData,
      projectImage: `/post-service/api/file-uploads/${response.id}`,
    };
    setProjectData(updatedProjectData);
    uploadImage(updatedProjectData);
  };

  const uploadImage = async (updatedProjectData) => {
    try {
      await put(
        `/userproject-service/api/user-projects/${projectData?.id}`,
        updatedProjectData
      );

      message.success("Image updated successfully.");
      fetchProjects();
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Failed to update image.");
    }
  };

  const addNewLink = (e) => {
    e.preventDefault();
    setShowAddLinkModal(true);
    setShowMenu(false);
  };

  const addNewNote = (e) => {
    e.preventDefault();
    setShowAddNoteModal(true);
    setShowMenu(false);
  };

  const addNewTodo = (e) => {
    e.preventDefault();
    setShowTodoModal(true);
    setShowMenu(false);
  };

  const handleDeleteProject = async () => {
    const isConfirmed = await Confirm(
      "Are you sure you want to delete this project?",
      "Confirm Deletion"
    );

    if (isConfirmed) {
      try {
        await del(`/userproject-service/api/user-projects/${project?.id}`);

        message.success("Project deleted successfully.");
        navigate("/program");
      } catch (error) {
        message.error("Error deleting project:", error);
      }
    } else {
      console.log("User cancelled the deletion.");
    }
  };

  const formatDate = (dueDate) => {
    const date = new Date(dueDate);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  };

  const handleSaveLinks = async (newLinks) => {
    try {
      await dispatch(addWebLinks(project.id, newLinks));
      fetchProjects();
    } catch (error) {
      console.error("Error saving links:", error);
    }
  };

  const handleSaveTodo = async (newTodos) => {
    try {
      await dispatch(addTodos(project.id, newTodos));
      fetchProjects();
    } catch (error) {
      console.error("Error saving Todos: ", error);
    }
  };

  const handleUpdateTodo = async (updatedTodo) => {
    try {
      await dispatch(updateTodo(project.id, updatedTodo));
      fetchProjects();
    } catch (error) {
      console.error("Error updating Todo: ", error);
    }
  };

  const handleSaveNote = async (newNotes) => {
    try {
      await dispatch(addNotes(project.id, newNotes));
      fetchProjects();
    } catch (error) {
      console.error("Error saving Notes: ", error);
    }
  };

  const handleDeleteTodo = async (projectId, todoId) => {
    try {
      await dispatch(deleteTodo(projectId, todoId));
      message.success("Todo deleted successfully.");
      fetchProjects();
    } catch (error) {
      console.error("Error deleting Todo: ", error);
      message.error("Failed to delete todo.");
    }
  };

  const handleDeleteNote = async (projectId, noteId) => {
    try {
      await dispatch(deleteNote(projectId, noteId));
      message.success("Note deleted successfully.");
      fetchProjects();
    } catch (error) {
      console.error("Error deleting Note: ", error);
      message.error("Failed to delte note.");
    }
  };

  const addSchedule = (e) => {
    e.preventDefault();
    setShowCreateScheduleModal(true);
    setShowMenu(false);
  };

  const handleSchedule = async (e) => {
    e.preventDefault();
    if (isScheduleNameEmpty) return;

    try {
      const response = await dispatch(
        createSchedule(project.id, scheduleData.scheduleName)
      );
      setShowCreateScheduleModal(false);
      setCurrentSchedule(response.data);
      fetchProjects();
      setScheduleData({ scheduleName: "" });
    } catch (error) {
      console.error("Error saving schedule:", error);
    }
  };

  const handleEventAdded = (newEvent) => {
    setSchedulesUpdated(true);
  };

  const tabChange = (tabId) => {
    // setActiveTab(tabId);

    navigate(`/program/${id}/${tabId}`);
  };

  const addPost = (e) => {
    e.preventDefault();
    // Implement add post functionality
    setShowMenu(false);
  };

  const addLabel = (e) => {
    e.preventDefault();
    setShowLabelModal(true);
    setShowMenu(false);
  };

  const handleAddLabel = (labelName) => {
    const newLabel = { label: labelName };
    put(`/userproject-service/api/user-projects/${project?.id}`, {
      ...project,
      labels: [...project?.labels, newLabel],
    })
      .then((response) => {
        fetchProjects();
        setLabels([...labels, newLabel]);
      })
      .catch((error) => {
        console.error("Error adding label:", error);
      });
  };
  useEffect(() => {
    setLabels(project.labels);
  }, [project.labels]);
  useEffect(() => {
    if (schedulesUpdated) {
      fetchProjects();
      setSchedulesUpdated(false);
    }
  }, [schedulesUpdated]);
  return (
    <div
      className="py-4 rounded-lg mx-3 px-12 bg-white overflow-auto"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <AddLabelModal
        showModal={showLabelModal}
        onCloseModal={() => setShowLabelModal(false)}
        handleAddLabel={handleAddLabel}
        projectId={project?.id}
        fetchProjects={fetchProjects}
      />
      <span className="my-6 py-6">
        <BiArrowBack
          size={20}
          onClick={() => navigate("/program")}
          className="cursor-pointer"
        />
      </span>
      <CreateScheduleModal
        // showModal={showModal}
        // onCloseModal={onCloseModal}
        handleSchedule={handleSchedule}
        scheduleData={scheduleData}
        setScheduleData={setScheduleData}
      />
      <div style={{ position: "relative" }}>
        <div className="flex flex-wrap gap-2 my-2 max-w-full">
          {project?.labels &&
            project.labels.map((labelObj, index) => (
              <span
                key={index}
                className="py-1 px-3 rounded-md text-[.8rem] text-white whitespace-nowrap"
                style={{
                  backgroundColor: labelObj.colorCode || "#1A9AA2",
                  maxWidth: "calc(100% - 0.5rem)",
                }}
              >
                {labelObj.label}
              </span>
            ))}
        </div>
        {project.disabledReason && (
          <span className="absolute right-0 top-0 justify-end bg-[#ff7700] border rounded text-white p-2">
            {project.disabledReason}
          </span>
        )}
        <h4 className="font-[600] capitalize text-[1.5rem] mt-4">
          {project?.programId || project?.projectName === "Miscellaneous" ? (
            <div>{project?.projectName}</div>
          ) : (
            <EditableContent
              text={project?.projectName}
              dataKey={"projectName"}
              data={project}
              style="font-[600] text-[1.5rem] mt-4"
              isRichtext={false}
            />
          )}
        </h4>
        {project?.projectImage && (
          <span className="my-2 flex">
            <AuthImage maxWidth={"100%"} imageurl={project?.projectImage} />
          </span>
        )}
        {project?.projectDescription && (
          <>
            {project?.programId || project?.projectName === "Miscellaneous" ? (
              <p
                className="font-[500] text-[1.3rem]"
                dangerouslySetInnerHTML={{
                  __html: project?.projectDescription,
                }}
              ></p>
            ) : (
              <EditableContent
                text={project?.projectDescription}
                dataKey={"projectDescription"}
                data={project}
                style="font-[500] text-[1.3rem]"
                isRichtext={true}
              />
            )}
          </>
        )}
        {!project?.programId && project?.projectName != "Miscellaneous" && (
          <span style={{ position: "absolute", right: 10, top: 0 }}>
            <div className="relative inline-flex gap-x-2 text-left">
              {project?.projectImage?.length > 0 && (
                <Tooltip title="Change Image">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <RiImageEditLine size={20} />
                  </button>
                </Tooltip>
              )}
              {!project?.projectImage?.length > 0 && (
                <Tooltip title="Add Image">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <BiImageAdd size={20} />
                  </button>
                </Tooltip>
              )}

              {/* Hidden file input for image upload */}
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />

              <Tooltip title="Delete Project">
                <button
                  type="button"
                  className={`inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 `}
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                  onClick={handleDeleteProject}
                >
                  <BiTrash size={20} />
                </button>
              </Tooltip>
              <Tooltip title="Add Label">
                <button
                  type="button"
                  className={`inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 `}
                  id="menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                  onClick={addLabel}
                >
                  <MdOutlineNewLabel size={20} />
                </button>
              </Tooltip>
            </div>
          </span>
        )}
      </div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          {tabs.map((tab, index) => (
            <li className="me-2" key={`tab-${index}`}>
              <span
                onClick={() => tabChange(tab.id)}
                className={`inline-block cursor-pointer p-4 border-b-2  rounded-t-lg ${
                  activeTab === tab.id
                    ? "text-blue-600  border-blue-600  active dark:text-blue-500 dark:border-blue-500"
                    : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                }`}
              >
                {tab.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex items-center my-3 justify-between">
        {/* <button onClick={()=>setShowModal(true)}  className='bg-[#5F33E1] rounded-lg py-2 text-white px-4'>+ Create Schedule</button> */}
      </div>
      {activeTab === "posts" && (
        <div style={{ marginTop: 10 }} className="bg-lightgrey">
          <h3 className="text-2xl font-bold text-gray-800">Posts</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4 posts">
            {project?.posts && project.posts.length > 0 ? (
              project.posts.map((post, index) => (
                <Post key={`post-${index}`} postId={post.postId} />
              ))
            ) : (
              <div className="p-3 text-center col-span-3 text-gray-600/50">
                {" "}
                No posts added
              </div>
            )}
          </div>
        </div>
      )}
      {activeTab === "schedules" && (
        <div style={{ marginTop: 10 }} className="bg-lightgrey">
          <div className="w-full flex flex-col">
            <div className="flow-root flex items-center ">
              <h3 className="text-2xl font-bold text-gray-800">Schedules</h3>

              {!project.programId && (
                <div className="float-right h-fit min-h-full flex justify-end">
                  <button
                    onClick={addSchedule}
                    className="p-2 bg-blue-600 text-white rounded mr-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ verticalAlign: "middle" }}
                      title="Add schedule"
                    >
                      <BiPlus size={20} />
                    </span>
                    <span>Add schedule</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginBottom: 10, backgroundColor: "#f6f6f6" }}>
            {project.schedules && project.schedules.length > 0 ? (
              project.schedules
                .sort(
                  (a, b) =>
                    moment(a.createdDate, "DD-MM-YYYY") -
                    moment(b.createdDate, "DD-MM-YYYY") // Ascending order
                )
                .map((schedule, i) => (
                  <div
                    className="pl-4 border bg-white my-2"
                    key={`projectSchedule-${i}`}
                  >
                    <ProjectSchedule
                      schedule={schedule}
                      projectId={project.id}
                      onEventAdded={handleEventAdded}
                      programId={project.programId}
                    />
                  </div>
                ))
            ) : (
              <div className="pl-10 justify-center">No schedules added</div>
            )}
          </div>
        </div>
      )}
      {activeTab === "tasks" && (
        <div style={{ marginTop: 10 }} className="bg-lightgrey">
          <div className="w-full flex flex-col ">
            <div className="flow-root flex items-center">
              <h3 className="text-2xl font-bold text-gray-800">Tasks</h3>

              {!project.programId && (
                <div className="float-right h-fit min-h-full flex justify-end">
                  <button
                    onClick={() => {
                      /*addTask*/
                    }}
                    className="p-2 bg-blue-600 text-white rounded"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <span
                      style={{ verticalAlign: "middle" }}
                      title="Add schedule"
                    >
                      <BiPlus size={20} />{" "}
                    </span>
                    <span>Add task</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginBottom: 10, backgroundColor: "#f6f6f6" }}>
            {project?.toDos.length > 0 ? (
              project.toDos.map((task, i) => (
                <div className="pl-4 border bg-white my-2">
                  <ProgramTask task={task} key={`task-${i}`} />
                </div>
              ))
            ) : (
              <div className="pl-10 justify-center">No tasks added</div>
            )}
          </div>
        </div>
      )}

      {activeTab === "chats" && (
        <div style={{ marginTop: 10 }} className="bg-lightgrey">
          {/* <div>
                                <h3>Tasks</h3>
                                <button onClick={addTask} className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">Add Task</button>
                            </div> */}
          <div className="w-full flex flex-col ">
            <div className="flow-root flex items-center">
              <h3 className="text-2xl font-bold text-gray-800">Program chat</h3>

              {/* <div className="float-right h-fit min-h-full flex justify-end"><button onClick={addTask} className="p-2 bg-blue-600 text-white rounded" style={{display: "flex", justifyContent: "center",alignItems:'center'}}> <span style={{verticalAlign:'middle'}} title='Add schedule'><BiPlus size={20}/> </span><span>Add task</span></button> </div> */}
            </div>
          </div>
          {project?.chat ? (
            <div style={{ marginBottom: 10 }}>
              <Link
                to={`/forum/${project.chat.chatId}`}
                style={{ color: "#1e88e5" }}
              >
                Go to Chat
              </Link>
            </div>
          ) : (
            <div> No chat associated with this program</div>
          )}
        </div>
      )}
      <AddLinkModal
        visible={showAddLinkModal}
        onClose={() => setShowAddLinkModal(false)}
        onSave={handleSaveLinks}
      />
      {activeTab === "weblinks" && (
        <WebLinksSection project={project} handleSaveLinks={handleSaveLinks} />
      )}
      <AddTodoModal
        visible={showTodoModal}
        onClose={() => setShowTodoModal(false)}
        onSave={handleSaveTodo}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
      />
      {activeTab === "todos" && (
        <TodosSection
          project={project}
          handleSaveTodo={handleSaveTodo}
          handleUpdateTodo={handleUpdateTodo}
          handleDeleteTodo={handleDeleteTodo}
        />
      )}

      <AddNoteModal
        visible={ShowAddNoteModal}
        onClose={() => setShowAddNoteModal(false)}
        onSave={handleSaveNote}
      />
      {activeTab === "notes" && (
        <NotesSection
          project={project}
          handleSaveNote={handleSaveNote}
          handleDeleteNote={handleDeleteNote}
        />
      )}
      <Modal
        showModal={showCreateScheduleModal}
        setShowModal={setShowCreateScheduleModal}
      >
        <div className="p-6 max-w-full bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between mb-2">
            <img
              src={preppyDog}
              alt="preppy-dog"
              className="w-48 object-contain h-36"
            />
            <h3 className="text-xl mr-40 font-semibold text-gray-800 flex-grow text-center">
              CREATE SCHEDULE
            </h3>
          </div>
          <form onSubmit={handleSchedule} className="space-y-6">
            <div>
              <label className="block text-gray-700 mb-1">Name</label>
              <input
                type="text"
                name="scheduleName"
                value={scheduleData.scheduleName}
                onChange={(e) =>
                  setScheduleData({
                    ...scheduleData,
                    scheduleName: e.target.value,
                  })
                }
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex mt-6">
              <button
                type="submit"
                disabled={isScheduleNameEmpty}
                className={`px-6 py-2 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                  isScheduleNameEmpty
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                Save Schedule
              </button>
              <button
                type="button"
                onClick={() => setShowCreateScheduleModal(false)}
                className="ml-4 px-6 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectItems;
