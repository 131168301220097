import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import { X, Calendar, Clock, Tag, Trash2, Edit } from "lucide-react";
import preppyDog from "../../assets/preppyDog.png";
import moment from "moment";
import { RECURRENCE_TYPES } from "../../constantsNew";
import { useDispatch, useSelector } from "react-redux";
import { del, put } from "../../util/APIUtils";
import { FaSave, FaSpinner } from "react-icons/fa";
import { Modal } from "react-responsive-modal";
import { message } from "antd";
import DatePicker from "react-datepicker";
import { DELETE_SCHEDULE_EVENT } from "../../actions/userProjectActions";
import { selectProject } from "../../actions/userProjectActions";
import CustomColorDropdown from "../Layout/CustomColorDropdown";

import { addMinutes, isToday } from "date-fns";
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const SchedulesEventCard = ({
  event,
  isOpen,
  onClose,
  scheduleId,
  projectId,
  eventId,
}) => {
  const modalRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState({});

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [recurrence, setRecurrence] = useState("");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [color, setColor] = useState("");
  const [summary, setSummary] = useState("");
  const [newEvent, setNewEvent] = useState({
    title: "",
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    recurrenceType: "NONE",
    daysOfWeek: [],
    eventEndDate: "",
    eventType: "REMINDER",
  });
  const colorScheme = useSelector((state) => state.calendar.colorScheme);
  const currentTime = new Date();
  const minStartTimeToday = addMinutes(currentTime, 30);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    setNewEvent(event);
  }, [event]);

  const validateTimes = () => {
    const newErrors = {};
    if (!startTime) {
      newErrors.startTime = "Start time is required";
    }
    if (!endTime) {
      newErrors.endTime = "End time is required";
    } else if (startTime && endTime <= addMinutes(startTime, 30)) {
      newErrors.endTime =
        "End time must be at least 30 minutes after start time";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    let updatedEvent = { ...newEvent };
    let eventModified = false;

    // Ensure endDate is not before startDate
    if (
      newEvent?.endDate &&
      newEvent?.startDate &&
      newEvent?.endDate < newEvent?.startDate
    ) {
      updatedEvent.endDate = newEvent?.startDate;
      eventModified = true;
    }

    // Ensure endTime is at least 30 minutes after startTime
    if (
      newEvent?.endTime &&
      newEvent?.startTime &&
      newEvent?.endTime <= addMinutes(newEvent?.startTime, 30)
    ) {
      updatedEvent.endTime = addMinutes(newEvent?.startTime, 30);
      eventModified = true;
    }

    // Ensure eventEndDate is not before startDate
    if (
      newEvent?.eventEndDate &&
      newEvent?.startDate &&
      newEvent?.eventEndDate < newEvent?.startDate
    ) {
      updatedEvent.eventEndDate = newEvent?.startDate;
      eventModified = true;
    }

    if (eventModified) {
      setNewEvent(updatedEvent);
    }
  }, [newEvent?.startDate, newEvent?.startTime]);

  // useEffect(() => {
  //   let updatedEvent = { ...newEvent };
  //   const date = newEvent?.startDate
  //     ? moment(newEvent?.startDate).toDate()
  //     : new Date();

  //   const updatedStartTime = moment(newEvent?.startTime)
  //     .set({
  //       year: date.getFullYear(),
  //       month: date.getMonth(),
  //       date: date.getDate(),
  //     })
  //     .toDate();

  //   const updatedEndTime = moment(newEvent?.endTime)
  //     .set({
  //       year: date.getFullYear(),
  //       month: date.getMonth(),
  //       date: date.getDate(),
  //     })
  //     .toDate();

  //   updatedEvent.startTime = updatedStartTime;
  //   updatedEvent.endTime = updatedEndTime;

  //   setNewEvent(updatedEvent);
  // }, [newEvent?.startDate]);

  useEffect(() => {
    if (
      newEvent?.startDate &&
      isToday(newEvent?.startDate) &&
      newEvent?.startTime < minStartTimeToday
    ) {
      setNewEvent((prev) => ({
        ...prev,
        startTime: minStartTimeToday,
      }));
    }
  }, [newEvent?.startDate]);

  useEffect(() => {
    if (
      newEvent?.eventEndDate &&
      newEvent?.endDate &&
      newEvent?.eventEndDate < newEvent?.endDate
    ) {
      setNewEvent((prev) => ({
        ...prev,
        eventEndDate: newEvent?.endDate,
      }));
    }
  }, [newEvent?.endDate]);

  const handleDateChange = (date) => {
    setNewEvent((prev) => {
      const updatedStartTime = prev.startTime
        ? moment(prev.startTime)
            .set({
              year: date.getFullYear(),
              month: date.getMonth(),
              date: date.getDate(),
            })
            .toDate()
        : null;

      const updatedEndTime = prev.endTime
        ? moment(prev.endTime)
            .set({
              year: date.getFullYear(),
              month: date.getMonth(),
              date: date.getDate(),
            })
            .toDate()
        : null;

      return {
        ...prev,
        startDate: date,
        startTime: updatedStartTime || prev.startTime,
        endTime: updatedEndTime || prev.endTime,
      };
    });
  };

  const handleTimeChange = (time, type) => {
    setNewEvent((prev) => {
      const date = prev.startDate
        ? moment(prev.startDate).toDate()
        : new Date();
      const updatedTime = moment(time)
        .set({
          year: date.getFullYear(),
          month: date.getMonth(),
          date: date.getDate(),
        })
        .toDate();

      return {
        ...prev,
        [type]: updatedTime,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setNewEvent((prev) => ({
        ...prev,
        daysOfWeek: checked
          ? [...prev.daysOfWeek, value]
          : prev.daysOfWeek.filter((day) => day !== value),
      }));
    } else {
      setNewEvent((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleSave = useCallback(async () => {
    setLoading(true); // Set loading to true when saving starts

    try {
      const response = await put(
        `/userproject-service/api/user-projects/${projectId}/schedules/${scheduleId}/events/${eventId}`,
        newEvent
      );

      if (response) {
        message.success("Event saved successfully!");
        dispatch(selectProject(response));
      } else {
        throw new Error("Failed to save event");
      }
      setIsEditing(false);
      onClose();
    } catch (error) {
      message.error(`Error saving event: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [dispatch, projectId, scheduleId, eventId, newEvent, onClose]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    if (event) {
      setTitle(event.title);
      setStart(event.startDate);
      setEnd(event.endDate);
      setColor(event.colorCode);
      setSummary(event.summary);
    }
  }, [event, onClose]);

  const { timeLeft, label, statusLabel } = useMemo(() => {
    const now = moment();
    const eventStart = moment(event?.startDate);
    // If endDate is null, use lastPlannedDate as fallback
    const eventEnd = moment(event?.endDate || event?.lastPlannedDate);

    let timeLeft, label, statusLabel;

    if (now.isBefore(eventStart)) {
      const duration = moment.duration(eventStart.diff(now));
      statusLabel = "Starts in";
      if (duration.asHours() < 24) {
        timeLeft = Math.ceil(duration.asHours());
        label = "Hours";
      } else {
        timeLeft = Math.ceil(duration.asDays());
        label = "Days";
      }
    } else if (now.isBefore(eventEnd)) {
      const duration = moment.duration(eventEnd.diff(now));
      statusLabel = "Ends in";
      if (duration.asHours() < 24) {
        timeLeft = Math.ceil(duration.asHours());
        label = "Hours";
      } else {
        timeLeft = Math.ceil(duration.asDays());
        label = "Days";
      }
    } else {
      const duration = moment.duration(now.diff(eventEnd));
      statusLabel = "Ended";
      if (duration.asHours() < 24) {
        timeLeft = Math.ceil(duration.asHours());
        label = "Hours Ago";
      } else {
        timeLeft = Math.ceil(duration.asDays());
        label = "Days Ago";
      }
    }
    return { timeLeft, label, statusLabel };
  }, [event?.startDate, event?.endDate, event?.lastPlannedDate]);

  if (!event || !isOpen) return null;

  // Format time function
  const formatDateTime = (date, time) => {
    if (!date) return "Not specified";
    const dateObj = moment(date);
    if (time) {
      dateObj.set({
        hour: parseInt(time.split(":")[0]),
        minute: parseInt(time.split(":")[1]),
      });
      return dateObj.format("D MMM, h:mm A");
    }
    return dateObj.format("D MMM, YYYY");
  };

  const confirmDelete = async () => {
    setLoading(true); // Set loading to true when deletion starts
    try {
      const response = await del(
        `/userproject-service/api/user-projects/${projectId}/schedules/${scheduleId}/events/${eventId}`
      );
      dispatch({
        type: DELETE_SCHEDULE_EVENT,
        payload: {
          projectId: projectId,
          scheduleId: scheduleId,
          eventId: eventId,
        },
      });
      dispatch(selectProject(response));
      onClose();
    } catch (error) {
      message.error("Error deleting event:", error);
    } finally {
      setLoading(false); // Set loading to false when deletion finishes
      setOpenDeleteModal(false);
    }
  };

  const cancelDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Modal
        classNames={{ modal: "rounded-lg w-[800px] " }}
        open={isOpen}
        onClose={onClose}
        center
        showCloseIcon={false}
      >
        {/* Header - Revised layout */}
        <div className="p-6 border-b">
          <div className="flex items-center justify-between">
            <div className="flex-1 flex items-center">
              <img
                src={preppyDog}
                alt="preppy-dog"
                className="w-24 h-24 object-contain"
              />
            </div>
            <h2 className="text-3xl font-semibold text-gray-800">
              {isEditing ? "Edit Event" : "Event Details"}
            </h2>
            <div className="flex-1 flex items-center justify-end gap-3">
              <button
                onClick={handleEdit}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <Edit className="h-5 w-5 text-gray-600" />
              </button>
              <button
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <Trash2 className="h-5 w-5 text-red-500" />
              </button>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="h-5 w-5 text-gray-600" />
              </button>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="p-6">
          <div className="grid grid-cols-2 gap-8">
            {/* Left Column */}
            <div className="space-y-6">
              <div>
                {isEditing ? (
                  <input
                    type="text"
                    name="title"
                    value={newEvent?.title}
                    onChange={handleChange}
                    // className="w-full p-2 text-2xl font-semibold text-gray-700 border rounded"
                    className="w-full text-2xl font-semibold text-gray-800 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                  />
                ) : (
                  <h3 className="text-2xl font-semibold text-gray-800">
                    {event?.title}
                  </h3>
                )}
                <p className="text-gray-600">
                  {isEditing ? (
                    <>
                      <label className="block text-gray-700 mb-1">
                        Summary:
                      </label>
                      <textarea
                        type="text"
                        name="summary"
                        value={newEvent?.summary}
                        onChange={handleChange}
                        // className="w-full p-2 text-2xl font-semibold text-gray-700 border rounded"
                        className="w-full p-2 text-gray-700 border rounded resize-none focus:border-blue-500 outline-none"
                        rows={6}
                      />
                    </>
                  ) : (
                    <p className="text-gray-700">
                      {event?.summary ?? "No Description Provided"}
                    </p>
                  )}
                </p>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              {/* Time Status */}
              <div className="bg-blue-50 p-4 rounded-lg">
                <label className="block text-sm text-gray-600 mb-2">
                  {statusLabel}
                </label>
                <div className="flex items-center gap-3">
                  <span className="text-2xl font-semibold text-blue-600">
                    {timeLeft}
                  </span>
                  <span className="text-blue-600">{label}</span>
                </div>
              </div>

              {/* Event Type */}
              <div>
                <label className="block text-sm text-gray-600 mb-2">
                  Recurrence Type
                </label>
                <div className="inline-block bg-gray-100 px-4 py-2 rounded-lg">
                  {isEditing ? (
                    <select
                      name="recurrenceType"
                      value={newEvent?.recurrenceType}
                      onChange={handleChange}
                      className="inline-block h-4 mr-2 text-gray-600"
                    >
                      <option value="NONE">None</option>
                      <option value="DAILY">Daily</option>
                      <option value="WEEKLY">Weekly</option>
                      <option value="MONTHLY">Monthly</option>
                      <option value="YEARLY">Yearly</option>
                      <option value="DAYS_OF_WEEK">Days of Week</option>
                    </select>
                  ) : (
                    <>
                      <Tag className="inline-block w-4 h-4 mr-2 text-gray-600" />
                      <span className="text-gray-700">
                        {RECURRENCE_TYPES[event.recurrenceType] ||
                          "General Event"}
                      </span>
                    </>
                  )}
                </div>
                <div>
                  {newEvent.recurrenceType === "DAYS_OF_WEEK" && (
                    <div>
                      <label className="block text-gray-700 mb-1">
                        Select Days:
                      </label>
                      <div className="flex flex-wrap items-center text-center">
                        {daysOfWeek.map((day) => (
                          <div
                            key={day}
                            className="flex items-center space-x-2 mx-3 mb-2"
                          >
                            <input
                              type="checkbox"
                              value={day}
                              checked={newEvent.daysOfWeek.includes(day)}
                              onChange={handleChange}
                              className="form-checkbox h-4 w-4 text-blue-600"
                            />
                            <span>{day}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Times */}
              <div className="space-y-4">
                <div className="mb-4">
                  <label className="block text-gray-700 mb-1">
                    Start Date:
                  </label>
                  {isEditing ? (
                    <DatePicker
                      showIcon
                      selected={
                        newEvent?.startDate && isToday(newEvent?.startDate)
                          ? minStartTimeToday
                          : null
                      }
                      onChange={(date) =>
                        setNewEvent((prev) => ({ ...prev, startDate: date }))
                      }
                      dateFormat="dd-MM-yyyy"
                      wrapperClassName="w-full"
                      className="w-[100%] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                      minDate={new Date()}
                      isClearable
                    />
                  ) : (
                    <p className="bg-gray-200 text-sm px-4 py-3 rounded-lg">
                      {event.startDate
                        ? moment(event.startDate).format("D MMM")
                        : "Not Selected"}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 mb-1">
                    Start Time:
                  </label>
                  {isEditing ? (
                    <DatePicker
                      showIcon
                      selected={moment(newEvent?.startTime).toDate()}
                      onChange={(date) =>
                        setNewEvent((prev) => ({ ...prev, startTime: date }))
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={
                        newEvent?.startDate && isToday(newEvent?.startDate)
                          ? minStartTimeToday
                          : new Date(0, 0, 0, 0, 0) // earliest possible time
                      }
                      maxTime={new Date(0, 0, 0, 23, 45)} // latest possible time
                      wrapperClassName="w-full"
                      className={`w-full px-3 py-2 border ${
                        errors.startTime ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 ${
                        errors.startTime
                          ? "focus:ring-red-400"
                          : "focus:ring-blue-400"
                      }`}
                      isClearable
                      // disabled={!newEvent.startDate}
                    />
                  ) : (
                    <p className="bg-gray-200 text-sm px-4 py-3 rounded-lg">
                      {moment(event.startTime).format("h:mm A")}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 mb-1">End Time:</label>
                  {isEditing ? (
                    <DatePicker
                      showIcon
                      selected={moment(newEvent?.endTime).toDate()}
                      onChange={(date) =>
                        setNewEvent((prev) => ({ ...prev, endTime: date }))
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={addMinutes(newEvent?.startTime, 30)} // End time must be 30 mins after start time
                      maxTime={new Date(0, 0, 0, 23, 45)}
                      wrapperClassName="w-full"
                      className={`w-full px-3 py-2 border ${
                        errors.endTime ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-2 ${
                        errors.endTime
                          ? "focus:ring-red-400"
                          : "focus:ring-blue-400"
                      }`}
                      isClearable
                      // disabled={!newEvent.startDate}
                    />
                  ) : (
                    <p className="bg-red-100 text-red-500 text-sm px-4 py-3 rounded-lg">
                      {moment(newEvent?.endTime).format("h:mm A")}
                    </p>
                  )}
                </div>
              </div>

              {newEvent?.recurrenceType &&
                newEvent?.recurrenceType != "NONE" && (
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-1">
                      Event End Date:
                    </label>
                    {isEditing ? (
                      <DatePicker
                        showIcon
                        selected={
                          newEvent?.eventEndDate
                            ? moment(newEvent.eventEndDate).toDate()
                            : null
                        }
                        onChange={(date) =>
                          setNewEvent((prev) => ({
                            ...prev,
                            eventEndDate: date,
                          }))
                        }
                        dateFormat="yyyy-MM-dd"
                        wrapperClassName="w-full"
                        className="w-[100%] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        minDate={
                          newEvent?.endDate || newEvent?.startDate || new Date()
                        }
                      />
                    ) : (
                      <p className="bg-red-100 text-red-500 text-sm px-4 py-3 rounded-lg">
                        {moment(newEvent?.eventEndDate).format("D MMM, h:mm A")}
                      </p>
                    )}
                  </div>
                )}

              {isEditing && (
                <div className="flex flex-col gap-4 mt-4">
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={handleCancel}
                      className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                    >
                      <FaSave className="inline-block mr-2" />
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openDeleteModal}
        onClose={cancelDelete}
        center
        styles={{
          modal: {
            width: "500px",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          },
          closeButton: {
            outline: "none",
            cursor: "pointer",
          },
        }}
      >
        <h2 className="text-lg font-semibold mb-4 text-center">Delete Event</h2>
        <p className="text-gray-600 text-center mb-6">
          Are you sure you want to delete this event?
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={cancelDelete}
            className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={confirmDelete}
            className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
            disabled={loading}
          >
            {loading ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Deleting...
              </>
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SchedulesEventCard;
