import {
  CREATE_PROJECT,
  ADD_LABELS,
  ADD_WEB_LINKS,
  CREATE_EVENT,
  CREATE_SCHEDULE,
  SELECT_PROJECT,
  DELETE_TODO,
  DELETE_NOTE,
  DELETE_SCHEDULE_EVENT,
} from "../actions/userProjectActions";

const initialState = {
  projects: [],
  selectedProject: {},
};

const userProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECT:
      return { ...state, projects: [...state.projects, action.payload] };
    case ADD_LABELS:
      return {
        ...state,
        selectedProject: action.payload,
      };
    case ADD_WEB_LINKS:
      console.log("actiontest6", action.payload);
      return {
        ...state,
        selectedProject: state.selectedProject.map((project) =>
          project.id === action.payload.projectId
            ? {
                ...project,
                webLinks: [...project.webLinks, ...action.payload.links],
              }
            : project
        ),
      };
    case CREATE_SCHEDULE:
      return {
        ...state,
        selectedProject: state.selectedProject.map((project) =>
          project.id === action.payload.projectId
            ? {
                ...project,
                schedules: [...project.schedules, action.payload.schedule],
              }
            : project
        ),
      };

    case SELECT_PROJECT:
      console.log("actiontest", action.payload);
      return {
        ...state,
        selectedProject: action.payload,
      };

    case DELETE_TODO:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          toDos: state.selectedProject.toDos.filter(
            (todo) => todo.id !== action.payload.todoId
          ),
        },
      };

    case DELETE_NOTE:
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          notes: state.selectedProject.notes.filter(
            (note) => note.id !== action.payload.noteId
          ),
        },
      };

    case DELETE_SCHEDULE_EVENT:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.projectId
            ? {
                ...project,
                schedules: project.schedules.map((schedule) =>
                  schedule.id === action.payload.scheduleId
                    ? {
                        ...schedule,
                        events: schedule.events.filter(
                          (event) => event.id !== action.payload.eventId
                        ),
                      }
                    : schedule
                ),
              }
            : project
        ),
      };

    default:
      return state;
  }
};

export default userProjectReducer;
