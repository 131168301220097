import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Input, Space } from "antd";
import preppyDog from "../../assets/preppyDog.png";

const AddLinkModal = ({ visible, onClose, onSave }) => {
  const [form] = Form.useForm();
  const [links, setLinks] = useState([{ url: "", description: "" }]);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setIsValid(true),
      () => setIsValid(false)
    );
  }, [links, form]);

  const addLink = () => {
    setLinks([...links, { url: "", description: "" }]);
  };

  const removeLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
    form.setFieldsValue({ links: newLinks });
  };

  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave(links);
      form.resetFields();
      setLinks([{ url: "", description: "" }]);
      onClose();
    });
  };

  const urlPattern =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

  return (
    <Modal
      visible={visible}
      title={
        <div className="flex items-center">
          <img
            src={preppyDog}
            alt="preppy-dog"
            className="w-36 object-contain h-24 mr-4"
          />
          <span className="text-lg font-medium leading-6 text-gray-900">
            Add New Links
          </span>
        </div>
      }
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSave}
          disabled={!isValid}
        >
          Save
        </Button>,
      ]}
      bodyStyle={{ maxHeight: "60vh", overflow: "auto" }}
    >
      <Form form={form} layout="vertical" name="addLinksForm">
        {links.map((link, index) => (
          <Space
            key={index}
            style={{ display: "flex", marginBottom: 8 }}
            align="baseline"
          >
            <Form.Item
              name={["links", index, "url"]}
              label="URL"
              rules={[
                { required: true, message: "Please input the URL!" },
                { pattern: urlPattern, message: "Please enter a valid URL!" },
              ]}
            >
              <Input
                placeholder="https://example.com"
                onChange={(e) => {
                  const newLinks = [...links];
                  newLinks[index].url = e.target.value;
                  setLinks(newLinks);
                }}
              />
            </Form.Item>
            <Form.Item
              name={["links", index, "description"]}
              label="Description"
              rules={[
                { required: true, message: "Please input the description!" },
              ]}
            >
              <Input
                placeholder="Link description"
                onChange={(e) => {
                  const newLinks = [...links];
                  newLinks[index].description = e.target.value;
                  setLinks(newLinks);
                }}
              />
            </Form.Item>
            {links.length > 1 && (
              <Button onClick={() => removeLink(index)}>Remove</Button>
            )}
          </Space>
        ))}
        <Form.Item>
          <Button onClick={addLink}>Add Another Link</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLinkModal;
