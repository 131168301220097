import React, { useState, useEffect } from "react";
import { IoMdClose, IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { addLabels } from "../../actions/userProjectActions";
import preppyDog from "../../assets/preppyDog.png";

const AddLabelModal = ({
  showModal,
  onCloseModal,
  projectId,
  fetchProjects,
}) => {
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [currentLabel, setCurrentLabel] = useState({
    name: "",
    color: "#1A9AA2",
  });

  const colorScheme = useSelector((state) => state.calendar.colorScheme);

  useEffect(() => {
    if (Object.values(colorScheme).length > 0) {
      setCurrentLabel((prev) => ({
        ...prev,
        color: Object.values(colorScheme)[0],
      }));
    }
  }, [colorScheme]);

  const addLabel = () => {
    if (currentLabel.name.trim()) {
      setLabels([...labels, { ...currentLabel }]);
      setCurrentLabel({ name: "", color: "#1A9AA2" });
    }
  };

  const removeLabel = (index) => {
    setLabels(labels.filter((_, i) => i !== index));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addLabels(projectId, labels));
    setLabels([]);
    fetchProjects();
    onCloseModal();
  };

  if (!showModal) return null;

  return (
    <div className="fixed z-50 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-[50%] shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4 flex items-center justify-center">
          <img
            src={preppyDog}
            alt="preppy-dog"
            className="w-36 object-contain h-24 mr-4"
          />
          <span className="flex-grow text-center mr-52">Add Labels</span>
        </h3>
        <form onSubmit={onSubmit}>
          <div className="mb-4 flex flex-wrap gap-2">
            {labels.map((label, index) => (
              <div
                key={index}
                className="flex items-center bg-gray-100 rounded-full pr-2"
              >
                <span
                  className="px-2 py-1 rounded-full text-white text-sm flex items-center mr-1"
                  style={{ backgroundColor: label.color }}
                >
                  {label.name}
                </span>
                <button
                  type="button"
                  className="text-gray-500 hover:text-red-500 focus:outline-none"
                  onClick={() => removeLabel(index)}
                >
                  <IoMdClose />
                </button>
              </div>
            ))}
          </div>
          <div className="mb-4 flex items-center space-x-2">
            <input
              type="text"
              value={currentLabel.name}
              onChange={(e) =>
                setCurrentLabel({ ...currentLabel, name: e.target.value })
              }
              placeholder="Enter label name"
              className="flex-grow px-3 py-2 border rounded-md"
            />
            <div className="flex flex-wrap gap-2">
              {Object.entries(colorScheme).map(([colorCode, colorValue]) => (
                <button
                  key={colorCode}
                  type="button"
                  className={`w-6 h-6 rounded-full ${
                    colorValue === currentLabel.color
                      ? "ring-2 ring-offset-2 ring-gray-500"
                      : ""
                  }`}
                  style={{ backgroundColor: colorValue }}
                  onClick={() =>
                    setCurrentLabel((prev) => ({ ...prev, color: colorValue }))
                  }
                />
              ))}
            </div>
            <button
              type="button"
              onClick={addLabel}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none"
            >
              <IoMdAdd />
            </button>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              type="button"
              onClick={onCloseModal}
              className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={labels.length === 0}
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 ${
                labels.length === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Save Labels
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLabelModal;
