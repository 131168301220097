import React from "react";

const TypingIndicator = () => {
  return (
    <div className="flex items-center gap-1 mt-1">
      <div className="text-gray-500 text-[.7rem] font-[500]">
        <div className="flex gap-1">
          <span
            className="w-2 h-2 bg-[#5051F9] rounded-full animate-bounce"
            style={{ animationDelay: "0ms" }}
          />
          <span
            className="w-2 h-2 bg-[#5051F9] rounded-full animate-bounce"
            style={{ animationDelay: "150ms" }}
          />
          <span
            className="w-2 h-2 bg-[#5051F9] rounded-full animate-bounce"
            style={{ animationDelay: "300ms" }}
          />
        </div>
      </div>
    </div>
  );
};

export default TypingIndicator;
