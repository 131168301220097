import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Confirm } from "react-st-modal";
import { FaTimes, FaPlus } from "react-icons/fa";
import { post } from "../../util/APIUtils";
import { useDispatch } from "react-redux";
import { autoCompleteLabel } from "../../actions/postActions";
import { notification } from "antd";
import { FaSpinner } from "react-icons/fa6";
export const EDIT_POST = "EDIT_POST";

const LabelModal = ({ open, onClose, labelData,setAutoScroll }) => {
  const dispatch = useDispatch();
  const [newLabel, setNewLabel] = useState();
  const [currentLabels, setCurrentLabels] = useState([]);
  const [loading,setLoading]=useState(false)
  const [labelLoading,setLabelLoading]=useState(false)
  const [showLabels,setShowLabels] = useState(false)
  const [postLabels,setPostLabels] = useState([]) 
  console.log("LabelData",labelData)
  const handleAddLabel = () => {
    console.log("ADD===>",newLabel)
    if (newLabel?.trim() !== "") {
      console.log("Adding==>",newLabel,currentLabels)
      setCurrentLabels([...currentLabels, { label: newLabel }]);
      setNewLabel("");
    }
  };

  useEffect(()=>{
    if(postLabels && postLabels.length>0){
      setShowLabels(true)
    }
  },[postLabels])
  useEffect(()=>{
    if(newLabel && newLabel.length>=2){
      setLabelLoading(true)
      setAutoScroll(false)
      dispatch(autoCompleteLabel(newLabel,setPostLabels,setLabelLoading,setNewLabel,postLabels,currentLabels))
    } else if(newLabel && newLabel.length < 2){
      setShowLabels(false);
      setPostLabels([])
    } 
    else if(newLabel && newLabel.length==0){
      setShowLabels(false);
      setPostLabels([])
    } 
    // else if(!newLabel){
    //   setShowLabels(false);
    //   setPostLabels([])
    // }
  },[newLabel])

  const selectLabel = (l) => {
    console.log("L===>",l)
    if(l.match){
      return notification.error({message: "Label already added"});
    }
      setNewLabel("");
      setShowLabels(false)
      setCurrentLabels([...currentLabels, { ...l }]);
      
    
  };
 
  // const onBlur = ()=>{
  //   setShowLabels(false)
  // }

  const onFocus = ()=>{
    if(postLabels && postLabels.length>0){
      setShowLabels(true)
    }
  }
  useEffect(() => {
    if (labelData && labelData.labels) {
      setCurrentLabels([...labelData.labels]);
    }
  }, [labelData]);

  const handleDeleteLabel = (labelToDelete) => {
    setCurrentLabels(currentLabels.filter((label) => label !== labelToDelete));
  };

  const handleSave = async () => {
    setLoading(true)
    // const reqBody = currentLabels.map((lb) => ({ label: lb.label }));

    // const result = await Confirm(
    //   "Do you want to save the changes?",
    //   "Save Labels"
    // );
    // if (result) {
      try {
        const response=await post(`/post-service/api/posts/${labelData.id}/labels`, currentLabels);
        dispatch({
          type: EDIT_POST,
          id: labelData.id,
          payload: {
            // ...labelData,
            // labels: [...currentLabels],
            ...response
          },
        });
        setPostLabels([])
        onClose();
      } catch (error) {
        console.error("Error adding Label:", error);
      }
      setLoading(false)
    // }
  };

  const closeModal = ()=>{
    setCurrentLabels([]);
    setPostLabels([]);
    onClose();
  }

  return (
    <Modal classNames={{modal:"rounded-lg w-[800px] "}} open={open} onClose={closeModal} center>
      <div className="w-[600px] mx-auto p-8">
        <h2 className="text-2xl font-bold mb-4 text-start text-gray-700">
          Manage Labels
        </h2>
        <div className="flex flex-wrap gap-2 my-4 max-h-40 overflow-y-auto">
          {currentLabels.map((label, index) => (
            <div
              key={index}
              className="flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-700"
            >
              <span>{label?.label}</span>
              <FaTimes
                className="ml-2 cursor-pointer text-gray-400"
                onClick={() => handleDeleteLabel(label)}
              />
            </div>
          ))}
        </div>
        <div className="flex items-center mt-4" style={{position:'relative'}}>
          <input
            type="text"
            value={newLabel}
            onChange={(e) => {

                console.log(newLabel, e)
                
                setNewLabel(e.target.value)
              }
            }
            // onBlur={onBlur}
            onFocus={onFocus}
            placeholder="Add new label"
            className="flex-grow border rounded-l px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {showLabels && postLabels?.length>0 &&
          <div className="px-2" style={{position:'absolute',top: 45,left: 0,width:'100%' ,zIndex:1000,background:'white',border:'1px solid #eeeeee',borderRadius:3}}>
            <ul>
              {postLabels && postLabels.map((l,i)=>(
                <li key={`postLabel-${i}`} onClick={()=>selectLabel(l)} disabled={l.match}>{l.label}</li>
              ))}
              
              
            </ul>
          </div>
          }
          <button
            onClick={handleAddLabel}
            className="bg-blue-500 text-white rounded-r px-4 py-3.5 hover:bg-blue-600"
          >
            <FaPlus />
          </button>
        </div>
        <div className="flex justify-end mt-6">
          <button
            disabled={loading}
            onClick={onClose}
            className="bg-gray-300 rounded px-4 py-2 mr-2 text-gray-700 hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={loading}

            className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
          >
            <span style={{display:'inline-flex'}}>{loading && <FaSpinner />}</span><span style={{display:'inline-flex'}}>Save</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LabelModal;
