import React, { useEffect, useRef } from "react";
import AuthImage from "../AuthImage";
import DefaultUserIcon from "../../assets/default-user-icon.png";

const ParticipantsList = ({ participants, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-white rounded-lg p-4 w-96 max-h-[80vh] overflow-y-auto"
      >
        <h3 className="text-lg font-semibold mb-4">Participants</h3>
        {participants.map((participant, index) => (
          <div key={index} className="flex items-center mb-3">
            <div className="w-[50px] h-[50px] rounded-full overflow-hidden mr-3">
              <AuthImage
                imageurl={participant.user.avatar || DefaultUserIcon}
                className="w-full h-full object-cover"
                maxWidth="50px"
                maxHeight="50px"
              />
            </div>
            <span>{`${participant.user.firstName} ${participant.user.lastName}`}</span>
          </div>
        ))}
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ParticipantsList;
