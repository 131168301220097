import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import LoginBg from "../assets/loginBg.png";
import LoginForm from "../components/Login/LoginForm";
import { signUpUsingGoogle } from "../actions/userActions";
import { login } from "../util/APIUtils";
import { ACCESS_TOKEN, USER } from "../constantsNew";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (recaptchaToken) => {
    try {
      if (!recaptchaToken) {
        notification.error({
          message: "Verification Failed",
          description: "Security verification failed. Please try again.",
          duration: 3,
        });
        return;
      }

      const response = await login({
        email,
        password,
        captchaValue: recaptchaToken,
      });

      console.log("Signed in response ====>", response);
      localStorage.setItem(ACCESS_TOKEN, response.accessToken);
      localStorage.setItem(USER, JSON.stringify(response.user));

      notification.success({
        message: "Login Successful",
        description: "You're successfully logged in!",
        duration: 1,
      });

      const timer = setTimeout(() => {
        navigate("/");
        navigate(0);
      }, 1000);
    } catch (error) {
      notification.error({
        message: "Login Failed",
        description:
          (error && error.message) ||
          "Oops! Something went wrong. Please try again!",
        duration: 3,
      });
    }
  };

  const handleGoogleLogin = () => {
    dispatch(signUpUsingGoogle());
  };

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <div
      style={{ backgroundImage: `url(${LoginBg})` }}
      className="flex items-center h-[100vh] bg-cover justify-center"
    >
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
        <LoginForm
          handleGoogleLogin={handleGoogleLogin}
          email={email}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          setEmail={setEmail}
          handleLogin={handleLogin}
          password={password}
          setPassword={setPassword}
        />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default Login;
