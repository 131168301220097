import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createEvent } from "../../actions/userProjectActions";
import Modal from "../Planner/Modal";
import { ProgramCalendarEvent } from "../CalendarEvent";
import preppyDog from "../../assets/preppyDog.png";
import DatePicker from "react-datepicker";
import { BiPlus } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { addMinutes, isToday } from "date-fns";
import { FaSpinner } from "react-icons/fa";
import { Modal as ResponsiveModal } from "react-responsive-modal";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const ProjectSchedule = ({ schedule, projectId, onEventAdded, programId }) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [savingEvent, setSavingEvent] = useState(false);
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: "",
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    recurrenceType: "NONE",
    daysOfWeek: [],
    eventEndDate: "",
    eventType: "REMINDER",
  });
  const [errors, setErrors] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dispatch = useDispatch();

  const currentTime = new Date();
  const minStartTimeToday = addMinutes(currentTime, 30);

  const validateTimes = () => {
    const newErrors = {};
    if (!startTime) {
      newErrors.startTime = "Start time is required";
    }
    if (!endTime) {
      newErrors.endTime = "End time is required";
    } else if (startTime && endTime <= addMinutes(startTime, 30)) {
      newErrors.endTime =
        "End time must be at least 30 minutes after start time";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (
      schedule &&
      schedule.rosterCalendarEvents &&
      schedule.rosterCalendarEvents.length > 0
    ) {
      let calendarEvents = schedule.rosterCalendarEvents.sort(
        (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
      );
      setEvents(calendarEvents);
    } else {
      setEvents([]); // Ensure events are cleared if there are none
    }
  }, [schedule]);

  useEffect(() => {
    let updatedEvent = { ...newEvent };
    let eventModified = false;

    // Ensure endDate is not before startDate
    if (
      newEvent.endDate &&
      newEvent.startDate &&
      newEvent.endDate < newEvent.startDate
    ) {
      updatedEvent.endDate = newEvent.startDate;
      eventModified = true;
    }

    // Ensure endTime is at least 30 minutes after startTime
    if (
      newEvent.endTime &&
      newEvent.startTime &&
      newEvent.endTime <= addMinutes(newEvent.startTime, 30)
    ) {
      updatedEvent.endTime = addMinutes(newEvent.startTime, 30);
      eventModified = true;
    }

    // Ensure eventEndDate is not before startDate
    if (
      newEvent.eventEndDate &&
      newEvent.startDate &&
      newEvent.eventEndDate < newEvent.startDate
    ) {
      updatedEvent.eventEndDate = newEvent.startDate;
      eventModified = true;
    }

    if (eventModified) {
      setNewEvent(updatedEvent);
    }
  }, [newEvent.startDate, newEvent.startTime]);

  useEffect(() => {
    if (
      newEvent.startDate &&
      isToday(newEvent.startDate) &&
      newEvent.startTime < minStartTimeToday
    ) {
      setNewEvent((prev) => ({
        ...prev,
        startTime: minStartTimeToday,
      }));
    }
  }, [newEvent.startDate]);

  useEffect(() => {
    if (
      newEvent.eventEndDate &&
      newEvent.endDate &&
      newEvent.eventEndDate < newEvent.endDate
    ) {
      setNewEvent((prev) => ({
        ...prev,
        eventEndDate: newEvent.endDate,
      }));
    }
  }, [newEvent.endDate]);

  const toggleModal = () => setShowModal(!showModal);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setNewEvent((prev) => ({
        ...prev,
        daysOfWeek: checked
          ? [...prev.daysOfWeek, value]
          : prev.daysOfWeek.filter((day) => day !== value),
      }));
    } else {
      setNewEvent((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validate = () => {
    let formErrors = {};
    if (!newEvent.title) {
      formErrors.title = "Title is required";
    }
    if (!newEvent.startTime) {
      console.log("Enter Start time");
      formErrors.startTime = "This field is required";
    }
    if (!newEvent.endTime) {
      formErrors.endTime = "This field is required";
    }
    return formErrors;
  };

  const confirmCreateEvent = async () => {
    setSavingEvent(true);
    dispatch(
      createEvent(projectId, schedule.id, {
        ...newEvent,
        endDate: newEvent.startDate,
      })
    )
      .then((createdEvent) => {
        setSavingEvent(false);
        setShowModal(false);
        setNewEvent({
          title: "",
          startDate: null,
          endDate: null,
          startTime: null,
          eventEndDate: null,
          endTime: null,
          recurrenceType: "NONE",
          daysOfWeek: [],
          eventType: "REMINDER",
        });
        setOpenConfirmModal(false);
      })
      .catch((error) => {
        setSavingEvent(false);
        console.error("Failed to create event:", error);
      });
  };

  const updateEvent = () => {
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      if (
        newEvent?.recurrenceType &&
        newEvent.recurrenceType != "NONE" &&
        !newEvent.eventEndDate
      ) {
        console.log("Confirm Modal");
        setOpenConfirmModal(true);
      } else {
        confirmCreateEvent();
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleStartTimeChange = (date) => {
    setStartTime(date);
    if (errors.startTime || errors.endTime) {
      validateTimes(); // Revalidate on change
    }
  };

  const handleEndTimeChange = (date) => {
    setEndTime(date);
    if (errors.endTime) {
      validateTimes(); // Revalidate on change
    }
  };

  return (
    <div className="mb-4 w-[100%]">
      <div className="mb-2 mr-2">
        <div
          className="font-semibold p-2 my-2 rounded"
          style={{ backgroundColor: "#f4f4f4" }}
        >
          {schedule?.scheduleName}
        </div>
        <div>
          {events && events.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-2">
              {events.map((e, i) => (
                <ProgramCalendarEvent
                  event={e}
                  key={`schedule-event-${i}`}
                  scheduleId={schedule.id}
                  projectId={projectId}
                  eventId={e.id}
                />
              ))}
            </div>
          ) : (
            <div className="p-10 justify-center text-center border">
              No events added
            </div>
          )}
          {!programId && (
            <button
              onClick={toggleModal}
              className="p-2 bg-blue-600 text-white rounded my-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ verticalAlign: "middle" }} title="Create Event">
                <BiPlus size={16} />
              </span>
              <span>Add event</span>
            </button>
          )}
        </div>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <div className="p-6 max-w-full bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between mb-2">
            <img
              src={preppyDog}
              alt="preppy-dog"
              className="w-48 object-contain h-36"
            />
            <h2 className="text-xl mr-40 font-semibold text-gray-800 flex-grow text-center">
              CREATE EVENT
            </h2>
          </div>
          <form className="space-y-6">
            <div>
              <label className="block text-gray-700 mb-1">Title:</label>
              <input
                type="text"
                name="title"
                value={newEvent.title}
                onChange={handleChange}
                className={`w-full px-3 py-2 border ${
                  errors.title ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 ${
                  errors.title ? "focus:ring-red-400" : "focus:ring-blue-400"
                }`}
              />
              {errors.title && (
                <p className="text-red-500 text-sm">{errors.title}</p>
              )}
            </div>

            <div>
              <label className="block text-gray-700 mb-1">Event Type:</label>
              <select
                name="eventType"
                value={newEvent.eventType}
                onChange={handleChange}
                className=" w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                <option value="REMINDER">Reminder</option>
                <option value="DEADLINE">Deadline</option>
                <option value="SCHEDULED_EVENT">Scheduled Event</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 mb-1">
                Recurrence Type:
              </label>
              <select
                name="recurrenceType"
                value={newEvent.recurrenceType}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                <option value="NONE">None</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
                <option value="YEARLY">Yearly</option>
                <option value="DAYS_OF_WEEK">Days of Week</option>
              </select>
            </div>
            {newEvent.recurrenceType === "DAYS_OF_WEEK" && (
              <div>
                <label className="block text-gray-700 mb-1">Select Days:</label>
                <div className="flex flex-row  align-middle text-center items-center">
                  {daysOfWeek.map((day) => (
                    <div
                      key={day}
                      className="flex items-center space-x-2 mx-3 align-middle text-center"
                    >
                      <input
                        type="checkbox"
                        value={day}
                        checked={newEvent.daysOfWeek.includes(day)}
                        onChange={handleChange}
                        className="form-checkbox h-4 w-4 text-blue-600"
                      />
                      <span>{day}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-gray-700 mb-1">Start Date:</label>
                <DatePicker
                  showIcon
                  selected={newEvent.startDate}
                  onChange={(date) =>
                    setNewEvent((prev) => ({ ...prev, startDate: date }))
                  }
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="w-full"
                  className="w-[100%] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  minDate={new Date()}
                  isClearable
                />
              </div>
              <div className="flex-1">
                <label className="block text-gray-700 mb-1">Start Time:</label>
                <DatePicker
                  showIcon
                  selected={newEvent.startTime}
                  onChange={(date) =>
                    setNewEvent((prev) => ({ ...prev, startTime: date }))
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  minTime={
                    newEvent.startDate && isToday(newEvent.startDate)
                      ? minStartTimeToday
                      : new Date(0, 0, 0, 0, 0) // earliest possible time
                  }
                  maxTime={new Date(0, 0, 0, 23, 45)} // latest possible time
                  wrapperClassName="w-full"
                  className={`w-full px-3 py-2 border ${
                    errors.startTime ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 ${
                    errors.startTime
                      ? "focus:ring-red-400"
                      : "focus:ring-blue-400"
                  }`}
                  isClearable
                />
                {errors.startTime && (
                  <p className="text-red-500 text-sm">{errors.startTime}</p>
                )}
              </div>
              {/* <div className="flex-1">
                <label className="block text-gray-700 mb-1">End Date:</label>
                <DatePicker
                  showIcon
                  selected={newEvent.endDate}
                  onChange={(date) =>
                    setNewEvent((prev) => ({ ...prev, endDate: date }))
                  }
                  dateFormat="Pp"
                  wrapperClassName="w-full"
                  className="w-[100%] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  minDate={newEvent.startDate || new Date()}
                />
              </div> */}
              <div className="flex-1">
                <label className="block text-gray-700 mb-1">End Time:</label>
                <DatePicker
                  showIcon
                  selected={newEvent.endTime}
                  onChange={(date) =>
                    setNewEvent((prev) => ({ ...prev, endTime: date }))
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  minTime={addMinutes(newEvent.startTime, 30)} // End time must be 30 mins after start time
                  maxTime={new Date(0, 0, 0, 23, 45)}
                  wrapperClassName="w-full"
                  className={`w-full px-3 py-2 border ${
                    errors.endTime ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 ${
                    errors.endTime
                      ? "focus:ring-red-400"
                      : "focus:ring-blue-400"
                  }`}
                  isClearable
                />
                {errors.endTime && (
                  <p className="text-red-500 text-sm">{errors.endTime}</p>
                )}
              </div>
            </div>
            {newEvent?.recurrenceType && newEvent.recurrenceType != "NONE" && (
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label className="block text-gray-700 mb-1">
                    {" "}
                    Event End Date:
                  </label>
                  <DatePicker
                    showIcon
                    selected={newEvent.eventEndDate}
                    onChange={(date) =>
                      setNewEvent((prev) => ({ ...prev, eventEndDate: date }))
                    }
                    dateFormat="yyyy-MM-dd"
                    wrapperClassName="w-full"
                    className="w-[100%] px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                    minDate={
                      newEvent.endDate || newEvent.startDate || new Date()
                    }
                  />
                </div>
              </div>
            )}

            <div className="flex justify-center mt-6">
              <button
                type="button"
                className="px-6 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={updateEvent}
                disabled={savingEvent}
              >
                {savingEvent && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
                Save Event
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ResponsiveModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        center
        styles={{
          modal: {
            width: "500px",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          },
          closeButton: {
            outline: "none",
            cursor: "pointer",
          },
        }}
      >
        <h2 className="text-lg font-semibold mb-4 text-center">
          Confirm Create Event
        </h2>
        <p className="text-gray-600 text-center mb-6">
          Are you sure you want to create this recurring event without setting
          event end date?
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={() => setOpenConfirmModal(false)}
            className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={confirmCreateEvent}
            className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
            disabled={savingEvent}
          >
            {savingEvent ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Creating Event...
              </>
            ) : (
              "Create Event"
            )}
          </button>
        </div>
      </ResponsiveModal>
    </div>
  );
};

export default ProjectSchedule;
