import React from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
const OTPValidate = ({ code, setCode, handleCodeChange, submitCode }) => {
  return (
    <div className="bg-white w-[600px] rounded-[20px] px-12 py-[2.5rem]">
      <div>
        <h3 className="text-[32px] text-center font-[600]">
          Create an Account
        </h3>
        <p className="my-1 text-gray-500 text-center text-[18px]">
          Create a account to continue
        </p>
      </div>
      <div className="mt-6 flex items-center justify-center">
        <OtpInput
          value={code}
          onChange={handleCodeChange}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          renderSeparator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            border: "1px solid #000",

            borderRadius: "8px",
            width: "54px",
            height: "54px",
            fontSize: "12px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue",
          }}
          focusStyle={{
            border: "1px solid #000",
            outline: "none",
          }}
        />
      </div>
      <div className="mt-12">
        <button
          onClick={submitCode}
          className="bg-[#4880FF] py-3 text-white rounded-[8px] w-[100%]"
        >
          Validate
        </button>
        <p className="mt-3">
          Already have a Account?{" "}
          <Link to="/login">
            {" "}
            <span className="text-[#4880FF] font-[500] underline">
              Login
            </span>{" "}
          </Link>{" "}
        </p>
      </div>
    </div>
  );
};

export default OTPValidate;
