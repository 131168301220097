import React, { useState, useRef, useEffect } from "react";

const CustomColorDropdown = ({ colorScheme, newEvent, setNewEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (colorName) => {
    setNewEvent((prev) => ({ ...prev, colorCode: colorName }));
    setIsOpen(false);
  };

  return (
    <div className="relative w-full">
      <button
        ref={buttonRef}
        type="button"
        className="w-full px-3 py-2 border border-gray-300 rounded-md bg-white flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <span>
          {Object.keys(colorScheme).find(
            (key) => colorScheme[key] === newEvent.colorCode
          ) || "Pick Color"}
        </span>
        <span>&#9662;</span>
      </button>

      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute z-10 bottom-full left-0 right-0 mb-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto"
        >
          {Object.entries(colorScheme).map(([colorCode, colorName]) => (
            <div
              key={colorCode}
              className="cursor-pointer px-3 py-2 flex items-center hover:bg-gray-100"
              onClick={() => handleSelect(colorName)}
            >
              <span
                className="inline-block w-4 h-4 mr-2 rounded-full"
                style={{ backgroundColor: colorName }}
              ></span>
              {colorCode}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomColorDropdown;
