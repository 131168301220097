import { useSelector } from "react-redux";

import { CLEAR_ERRORS} from "../constants/userConstants"
import { CHANGE_BOT_SUCCESS } from "../constants/botConstants";



export const botReducer = (state = { chatId:"" }, action) => {

    switch (action.type) {
      case CHANGE_BOT_SUCCESS:
        return {
          ...state,
          chatId:"32lciWKi2vcvgvV6vjYmgEZdWf62" + action.payload.users
        }
      default:
        return state;
    }
  };