import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Confirm } from "react-st-modal";
import { FaTimes, FaPlus } from "react-icons/fa";
import { post, get, patch } from "../../util/APIUtils"; // Assuming get is a function to make GET requests
import { useDispatch } from "react-redux";
export const EDIT_POST = "EDIT_POST";

const ProjectModal = ({ open, onClose, postData }) => {
  const dispatch = useDispatch();
  const [newLabel, setNewLabel] = useState("");
  const [currentLabels, setCurrentLabels] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prevProject, setPrevProject] = useState();

  useEffect(() => {
    if (postData?.userProject && postData.userProject.projectId) {
      setNewLabel(postData.userProject.projectId);
      setPrevProject(postData.userProject.projectId);
    }
  }, [postData]);
  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await get("/userproject-service/api/user-projects");
      setProjectOptions(response.content.length > 0 ? response.content : []);
      console.log(response);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setProjectOptions([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  const handleSave = async () => {
    // const result = await Confirm(
    //   "Do you want to save the changes?"
    //   // "Save"
    // );
    // if (result) {
    try {
      await post(`/userproject-service/api/posts/${postData.id}`, {
        previousProjectId: prevProject,
        newProjectId: newLabel,
      });
      await patch(`/post-service/api/posts/${postData.id}`, {
        projectId: newLabel,
        projectName: projectOptions.find((project) => project.id === newLabel)
          .projectName,
      });
      // dispatch({
      //   type: EDIT_POST,
      //   id: postData.id,
      //   payload: {
      //     ...postData,
      //     labels: [...currentLabels],
      //   },
      // });
      onClose();
    } catch (error) {
      console.error("Error adding Label:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center className="w-[600px]">
      <div className="w-[600px] mx-auto p-8">
        <h2 className="text-2xl font-bold mb-4 text-start text-gray-700">
          Change Project
        </h2>
        <div className="flex items-center mt-4">
          {loading ? (
            <div className="flex-grow border rounded-l px-3 py-2 text-gray-700 flex justify-center items-center">
              <span>Loading...</span>
            </div>
          ) : (
            <select
              value={newLabel}
              onChange={(e) => setNewLabel(e.target.value)}
              className="flex-grow border rounded-l px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a project</option>
              {projectOptions.length === 0 ? (
                <option value="" disabled>
                  No projects available
                </option>
              ) : (
                projectOptions.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.projectName}
                  </option>
                ))
              )}
            </select>
          )}
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="bg-gray-300 rounded px-4 py-2 mr-2 text-gray-700 hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectModal;
