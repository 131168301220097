import React, { useEffect, useRef, useState } from "react";
import { EVENT_TYPES } from "../../constantsNew";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  markNotificationRead,
} from "../../actions/userActions";
import { Bell, X, ChevronDown } from "lucide-react"; // Added for better icons
const moment = require("moment-timezone");

const NotificationItem = ({
  key,
  id,
  userName,
  message,
  createdDate,
  notificationType,
  userId,
  entityId,
  notifiedUserId,
  isUnread,
  showEventDetails,
  markAsRead = () => {
    console.log("Default");
  },
}) => {
  const timezone = useSelector((state) => state.users.userTimezone);
  var utcCutoff = moment.utc(createdDate, "YYYY-MM-DD HH:mm:ss.SSS");
  var displayTime = timezone
    ? utcCutoff.clone().tz(timezone.timeZone)
    : utcCutoff.clone().tz("Asia/Kolkata");

  return (
    <div
      key={key}
      className={`group flex items-start p-4 transition-all duration-200 border-l-4 hover:border-l-blue-500 ${
        isUnread ? "border-l-blue-500 bg-blue-50" : "border-l-transparent"
      }`}
      onClick={() => {
        markAsRead(id);
        (notificationType === "REMINDER" ||
          notificationType === "SCHEDULED_EVENT" ||
          notificationType === "DEADLINE") &&
          showEventDetails &&
          showEventDetails(entityId);
      }}
    >
      <div className="flex-grow space-y-2">
        <p className="text-sm text-gray-800 leading-relaxed">
          You have an upcoming event{" "}
          <span className="font-bold">{message}</span>
        </p>
        <div className="flex items-center justify-between">
          <p className="text-xs text-gray-500">
            {displayTime?.format("DD MMM YYYY, hh:mm a")}
          </p>
          <span className="px-2 py-1 text-xs font-medium rounded-full bg-blue-100 text-blue-700">
            {EVENT_TYPES[notificationType]}
          </span>
        </div>
      </div>
      {isUnread && (
        <div className="w-2 h-2 mt-2 ml-3 bg-blue-500 rounded-full flex-shrink-0"></div>
      )}
    </div>
  );
};

const Notification = ({
  notifications,
  notificationPage,
  onClose,
  showEventDetails,
}) => {
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [notificationsForDisplay, setNotificationsForDisplay] = useState([]);
  const notificationRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showUnreadOnly) {
      setNotificationsForDisplay([...notifications]);
    }
  }, []);

  useEffect(() => {
    if (showUnreadOnly) {
      let ns = notifications.filter((n) => n.isUnread);
      setNotificationsForDisplay([...ns]);
    } else {
      setNotificationsForDisplay([...notifications]);
    }
  }, [showUnreadOnly, notifications]);

  const handlePagination = () => {
    dispatch(fetchNotifications(notificationPage));
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  const markAsRead = (id) => {
    dispatch(markNotificationRead(id));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={notificationRef}
      className="bg-white rounded-xl shadow-xl w-96 max-h-[80vh] overflow-hidden divide-y divide-gray-100"
    >
      <div className="px-4 py-3 flex justify-between items-center bg-white sticky top-0 z-10 border-b border-gray-100">
        <div className="flex items-center space-x-2">
          <Bell className="w-5 h-5 text-blue-500" />
          <h2 className="text-lg font-semibold text-gray-800">Notifications</h2>
        </div>
        <div className="flex items-center space-x-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-4 h-4 rounded text-blue-500 focus:ring-blue-500"
              checked={showUnreadOnly}
              onChange={() => setShowUnreadOnly(!showUnreadOnly)}
            />
            <span className="text-sm text-gray-600">Unread only</span>
          </label>
          <button
            onClick={onClose}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
      </div>

      <div className="px-4 py-2 bg-gray-50">
        <h3 className="text-xs font-semibold text-gray-500 tracking-wider">
          TODAY
        </h3>
      </div>

      <div className="overflow-y-auto max-h-[calc(80vh-8rem)] divide-y divide-gray-100">
        {notificationsForDisplay?.length === 0 ? (
          <div className="p-8 text-center text-gray-500">
            No notifications available
          </div>
        ) : (
          notificationsForDisplay.map((notification, index) => (
            <NotificationItem
              key={index}
              {...notification}
              markAsRead={markAsRead}
              showEventDetails={showEventDetails}
            />
          ))
        )}
      </div>

      <button
        onClick={handlePagination}
        className="w-full px-4 py-3 flex items-center justify-center space-x-2 text-sm text-gray-600 hover:bg-gray-50 transition-colors"
      >
        <span>View older notifications</span>
        <ChevronDown className="w-4 h-4" />
      </button>
    </div>
  );
};

export default Notification;
