import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import postsReducer from "./reducers/postsReducer";
import userReducer from "./reducers/userReducer";
import { chatReducer } from "./reducers/chatReducer";
import { botReducer } from "./reducers/botReducer";
import { calendarReducer } from "./reducers/calendarReducer";
import { myFilesReducer } from "./reducers/myFilesReducer";
import { notificationReducer} from "./reducers/notificationReducer"
import thunk from "redux-thunk";
import { programScheduleReducer } from "./reducers/programScheduleReducer";
import { academicProgramReducer } from "./reducers/academicProgramReducer";
import { websocketMessageReducer } from "./reducers/websocketMessageReducer";
import userProjectReducer from "./reducers/userProjectReducer";

const rootReducer = combineReducers({
  posts: postsReducer,
  users: userReducer,
  chat: chatReducer,
  bot: botReducer,
  calendar: calendarReducer,
  myFiles: myFilesReducer,
  academicPrograms: academicProgramReducer,
  programSchedules: programScheduleReducer,
  websocketMessages: websocketMessageReducer,
  projects: userProjectReducer,
  myNotifications: notificationReducer
});

// Setup Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
