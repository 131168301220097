import React from 'react'
import Sidebar from '../../components/Layout/Sidebar'
import TopBar from '../../components/Layout/TopBar'
import AcademicProgram from '../../components/AcademicProgram'


const AcademicPrograms = (props) => {
  return (
    
      <div className='h-[85vh] overflow-auto' >
        <AcademicProgram {...props}/>
     </div>
    
  )
}

export default AcademicPrograms