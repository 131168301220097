import React from 'react'
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import  {
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import { Progress } from "@material-tailwind/react";
const RewardTop = () => {
    const { currentUser } = useSelector(state => state.users);
    // const currentUser = users?.currentUser;
  return (
    <div className='grid mx-3 grid-cols-5 items-start grid-flow-col' >
        <div className='col-span-2' >
            <h3 className='text-[3.5rem]  font-[600]' >Hey, <span className='text-[#5051F9]' > {currentUser?.displayName}</span></h3>
            <p className='text-[#424141] my-1' >Finish tasks to get amazing rewards!!</p>
        </div>
        <div className='col-span-3'>
            <h3 className='text-[#424141] my-1'>Current Mission</h3>
            <div>
            <Swiper
          spaceBetween={12}
          className=""
          draggable={true}
          breakpoints={{
            576: {
              width: 576,
              slidesPerView: 8,
            },
            780: {
              width: 780,
              slidesPerView:7,
            },
          
            1440:{
              width:1440,
              slidesPerView:6
            }
          }}
          autoplay={{
            delay: 800,
            disableOnInteraction:false,
          }}
          loop={true}
          modules={[Autoplay]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
        <SwiperSlide>
            <div className='bg-white  py-3 px-3 rounded-lg shadow-lg' >
            <div className='bg-[#FFF4E6] w-[110px] py-1 px-2 rounded-xl text-[#FCB351] fot-[600] text-[.8rem]' >Get 500 Points</div>
            <div className=' w-[150px]' >
            <h3 className='text-[1.2rem] font-[500] my-2' >Weekly loyalty streak</h3>
            </div>
            <div className='flex items-center justify-between' >
            {/* <Progress className="my-4  w-[150px]" size="lg" color="purple"  value={7/7*100} /> */}
              <div class="w-[75%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div class="bg-blue-600 h-2.5 rounded-full" style={{width: `100%`}}></div>
              </div>
            <p className='font-[600] text-[.9rem]' >7 of 7</p>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white  py-3 px-3 rounded-lg shadow-lg' >
            <div className='bg-[#FFF4E6] w-[110px] py-1 px-2 rounded-xl text-[#FCB351] fot-[600] text-[.8rem]' >Get 500 Points</div>
            <div className=' w-[150px]' >
            <h3 className='text-[1.2rem] font-[500] my-2' >Weekly loyalty streak</h3>
            </div>
            <div className='flex items-center justify-between' >
            {/* <Progress className="my-4  w-[150px]" size="lg" color="purple"  value={7/7*100} /> */}
            <div class="w-[75%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div class="bg-blue-600 h-2.5 rounded-full" style={{width: `${7/7*100}%`}}></div>
            </div>
            <p className='font-[600] text-[.9rem]' >7 of 7</p>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white  py-3 px-3 rounded-lg shadow-lg' >
            <div className='bg-[#FFF4E6] w-[110px] py-1 px-2 rounded-xl text-[#FCB351] fot-[600] text-[.8rem]' >Get 500 Points</div>
            <div className=' w-[150px]' >
            <h3 className='text-[1.2rem] font-[500] my-2' >Weekly loyalty streak</h3>
            </div>
            <div className='flex items-center justify-between' >
            <div class="w-[75%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div class="bg-blue-600 h-2.5 rounded-full" style={{width: `${3/7*100}%`}}></div>
            </div>
            <p className='font-[600] text-[.9rem]' >3 of 7</p>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white  py-3 px-3 rounded-lg shadow-lg' >
            <div className='bg-[#FFF4E6] w-[110px] py-1 px-2 rounded-xl text-[#FCB351] fot-[600] text-[.8rem]' >Get 500 Points</div>
            <div className=' w-[150px]' >
            <h3 className='text-[1.2rem] font-[500] my-2' >Weekly loyalty streak</h3>
            </div>
            <div className='flex items-center justify-between' >
            {/* <Progress className="my-4  w-[150px]" size="lg" color="purple"  value={5/7*100} /> */}
            <div class="w-[75%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div class="bg-blue-600 h-2.5 rounded-full" style={{width: `${5/7*100}%`}}></div>
            </div>
            <p className='font-[600] text-[.9rem]' >5 of 7</p>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className='bg-white  py-3 px-3 rounded-lg shadow-lg' >
            <div className='bg-[#FFF4E6] w-[110px] py-1 px-2 rounded-xl text-[#FCB351] fot-[600] text-[.8rem]' >Get 500 Points</div>
            <div className=' w-[150px]' >
            <h3 className='text-[1.2rem] font-[500] my-2' >Weekly loyalty streak</h3>
            </div>
            <div className='flex items-center justify-between' >
            {/* <Progress className="my-4  w-[150px]" size="lg" color="purple"  value={4/7*100} /> */}
            <div class="w-[75%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div class="bg-blue-600 h-2.5 rounded-full" style={{width: `${4/7*100}%`}}></div>
            </div>
            <p className='font-[600] text-[.9rem]' >4 of 7</p>
            </div>
            </div>
        </SwiperSlide>
         </Swiper>
            </div>
        </div>
    </div>
  )
}

export default RewardTop