import React, { useEffect, useState } from "react";
import {
  FaFile,
  FaFileImage,
  FaFileAudio,
  FaFileVideo,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileArchive,
  FaFileCode,
  FaFileCsv,
  FaFileAlt,
  FaFont,
} from "react-icons/fa";
import { IoDocumentText, IoDocument, IoDocumentAttach } from "react-icons/io5";

const iconMap = {
  // Images
  // "image/jpeg": FaFileImage,
  // "image/png": FaFileImage,
  // "image/gif": FaFileImage,
  // "image/bmp": FaFileImage,
  // "image/webp": FaFileImage,
  // "image/svg+xml": FaFileImage,
  // "image/tiff": FaFileImage,
  // "image/vnd.microsoft.icon": FaFileImage,
  // "image/x-icon": FaFileImage,
  // "image/heic": FaFileImage,
  // "image/heif": FaFileImage,

  // Audio
  // "audio/mpeg": FaFileAudio,
  // "audio/wav": FaFileAudio,
  // "audio/ogg": FaFileAudio,
  // "audio/aac": FaFileAudio,
  // "audio/midi": FaFileAudio,
  // "audio/x-midi": FaFileAudio,
  // "audio/webm": FaFileAudio,
  // "audio/3gpp": FaFileAudio,
  // "audio/3gpp2": FaFileAudio,
  // "audio/x-aiff": FaFileAudio,
  // "audio/x-flac": FaFileAudio,

  // Video
  "video/mp4": FaFileVideo,
  "video/mpeg": FaFileVideo,
  "video/ogg": FaFileVideo,
  "video/webm": FaFileVideo,
  "video/x-msvideo": FaFileVideo,
  "video/3gpp": FaFileVideo,
  "video/3gpp2": FaFileVideo,
  "video/x-ms-wmv": FaFileVideo,
  "video/x-flv": FaFileVideo,

  // Documents
  "application/pdf": FaFilePdf,
  "application/msword": FaFileWord,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    FaFileWord,
  "application/vnd.ms-excel": FaFileExcel,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    FaFileExcel,
  "application/vnd.ms-powerpoint": FaFilePowerpoint,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    FaFilePowerpoint,
  "application/vnd.ms-outlook": IoDocumentAttach,
  "application/vnd.ms-project": IoDocumentAttach,
  "application/epub+zip": IoDocument,

  // Text
  "text/plain": IoDocumentText,
  "text/csv": FaFileCsv,
  "text/html": FaFileCode,
  "text/css": FaFileCode,
  "text/javascript": FaFileCode,
  "text/xml": FaFileCode,
  "text/markdown": IoDocumentText,
  "text/x-python": FaFileCode,
  "text/x-java-source": FaFileCode,

  // Archives
  "application/zip": FaFileArchive,
  "application/x-tar": FaFileArchive,
  "application/gzip": FaFileArchive,
  "application/x-7z-compressed": FaFileArchive,
  "application/x-rar-compressed": FaFileArchive,

  // Application
  "application/json": FaFileCode,
  "application/octet-stream": FaFileAlt,
  "application/rtf": IoDocument,
  "application/vnd.oasis.opendocument.text": IoDocument,
  "application/vnd.oasis.opendocument.spreadsheet": IoDocument,
  "application/x-sh": FaFileCode,
  "application/x-csh": FaFileCode,
  "application/x-httpd-php": FaFileCode,
  "application/vnd.android.package-archive": FaFileAlt,
  "application/x-shockwave-flash": FaFileAlt,
  "application/x-bzip": FaFileArchive,
  "application/x-bzip2": FaFileArchive,

  // Fonts
  "font/otf": FaFont,
  "font/ttf": FaFont,
  "font/woff": FaFont,
  "font/woff2": FaFont,

  // Other
  "application/vnd.ms-fontobject": FaFont,
};

const FileIcon = ({ fileType, fileName, apiUrl }) => {
  const [base64String, setBase64String] = useState("");
  const [hovered, setHovered] = useState(false);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const IconComponent = iconMap[fileType] || FaFile;

  const fetchFileData = async () => {
    try {
      const response = await fetch(apiUrl, { responseType: "arraybuffer" });
      const arrayBuffer = await response.arrayBuffer();
      const base64String = arrayBufferToBase64(arrayBuffer);
      setBase64String(base64String);
      if (response.ok) {
        setDownloadAvailable(true);
      }
    } catch (error) {
      setDownloadAvailable(false);
      console.error("Error fetching file data:", error);
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleDownload = () => {
    if (!base64String) {
      fetchFileData();
    } else {
      const link = document.createElement("a");
      link.href = `data:${fileType};base64,${base64String}`;
      link.download = fileName;
      link.click();
    }
  };

  useEffect(() => {
    fetchFileData();
  }, [apiUrl]);

  return (
    <div
      className="relative flex flex-col items-center p-1 rounded shadow-sm max-w-[200px] min-w-[150px] cursor-pointer"
      onClick={downloadAvailable ? handleDownload : null}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <IconComponent
        className={`text-white-300 ${hovered ? "blur-sm" : ""}`}
        size={48}
      />
      <span
        className={`text-white-700 text-center truncate w-full ${
          hovered ? "blur-sm" : ""
        }`}
        title={fileName}
      >
        {fileName}
      </span>
      {hovered && (
        <div
          className={`absolute inset-0 flex items-center justify-center text-center bg-black ${
            downloadAvailable
              ? "bg-opacity-50 text-white"
              : "bg-opacity-70 text-red-700"
          } text-sm rounded-md px-1`}
        >
          {downloadAvailable ? `Click to download` : `Download Unavailable!`}
        </div>
      )}
    </div>
  );
};

export default FileIcon;
