import React, { useEffect, useState } from "react";
import { FaPlus, FaSpinner, FaTimes } from "react-icons/fa";
import SearchIcon from "../../assets/icons/search.svg";
import { Input, Select, Skeleton } from "antd";
import Modal from "react-responsive-modal";
import { get, post } from "../../util/APIUtils";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
const { Option } = Select;

export const OrganizationSettings = () => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [noUserFound, setNoUserFound] = useState(false);
  const [organisationTypes, setOrganisationTypes] = useState([]);
  const [form, setForm] = useState({
    organisationType: "",
    organisationName: "",
    address: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.users);
  console.log("zzz--->>>", currentUser);
  const cancelDelete = () => {
    setOpenCreateModal(false);
  };

  const CreateOrganisationRequest = async () => {
    try {
      const response = await post(`/stridecal-backend/api/workflow-requests`, {
        requestorId: currentUser.id,
        requestorName: `${currentUser.firstName} ${currentUser.lastName}`,
        requestType: "CreateOrganization",
        requestDescription: JSON.stringify(form),
        isApproved: false,
      });
      if (response) {
        setOrganisationTypes(response);
      }
    } catch (error) {
      console.log("Error Fetching OrganisationTypes");
    }
  };

  //   {
  //   "id": "string",
  //   "requestorId": "string",
  //   "requestorName": "string",
  //   "requestType": "CreateOrganization",
  //   "requestDescription": "string",
  //   // "isApproved": true,
  //   // "approverId": "string",
  //   // "approvalStatusChangeDate": "2024-11-12T14:47:19.176Z",
  //   // "createdDate": "2024-11-12T14:47:19.176Z",
  //   // "lastUpdatedDate": "2024-11-12T14:47:19.176Z"
  // }

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        if (inputValue) {
          // Simulate no user found logic
          setNoUserFound(true);
        }
      }, 3000);
    }
  }, [loading, inputValue]);

  useEffect(() => {
    fetchOrganisationTypes();
  }, []);

  const fetchOrganisationTypes = async () => {
    try {
      const response = await get(
        `/organization-service/api/organizations/search/types`
      );
      if (response) {
        setOrganisationTypes(response);
      }
    } catch (error) {
      console.log("Error Fetching OrganisationTypes");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setLoading(true);
    setNoUserFound(false);
  };

  const handleClear = () => {
    setInputValue("");
    setNoUserFound(false);
  };

  const handleFieldChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const [skeletonCount, setSkeletonCount] = useState(0);

  useEffect(() => {
    const calculateSkeletonCount = () => {
      const screenHeight = window.innerHeight;
      const skeletonHeight = 200;
      const skeletonsPerRow = 3;
      const rows = Math.ceil(screenHeight / skeletonHeight);
      setSkeletonCount(rows * skeletonsPerRow);
    };
    calculateSkeletonCount();
    window.addEventListener("resize", calculateSkeletonCount);
    return () => window.removeEventListener("resize", calculateSkeletonCount);
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!form.organisationType) errors.organisationType = "Type is required.";
    if (!form.organisationName) errors.organisationName = "Name is required.";
    if (!form.address) errors.address = "Address is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      setSaveLoading(true);
      CreateOrganisationRequest().finally(() => setSaveLoading(false));
      setOpenCreateModal(false);
    }
  };

  return (
    <div className="bg-white h-[78vh] overflow-y-scroll rounded-lg col-span-1 py-6 px-6">
      <h4 className="font-[600] text-start text-[1.3rem]">
        Organization Settings
      </h4>
      <div className="grid grid-cols-2 my-2 mt-4 justify-between w-full">
        <div className="relative col-span-1 flex items-center rounded-lg bg-[#FAFAFA]">
          <img className="w-[15px] ml-3" src={SearchIcon} alt="search-icon" />
          <input
            value={inputValue}
            onChange={handleInputChange}
            className="py-3 px-4 flex-grow outline-none  ml-2 bg-[#FAFAFA]"
            placeholder="Search Organization"
          />

          {inputValue && (
            <button onClick={handleClear} className="ml-2 text-gray-500 pr-3">
              <FaTimes />
            </button>
          )}
        </div>
        <div className="flex col-span-1 justify-end py-3 px-12 ">
          <button
            onClick={() => setOpenCreateModal(true)}
            className="flex items-center gap-1 justify-center text-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            <FaPlus /> Create Organisation
          </button>
        </div>
      </div>
      {loading ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 p-4">
          {Array.from({ length: skeletonCount }).map((_, index) => (
            <div key={index} className="w-full h-[200px] flex items-center">
              <Skeleton.Avatar
                active
                size="large"
                shape="circle"
                className="mr-4"
              />
              <Skeleton active className="flex-grow h-full" />
            </div>
          ))}
        </div>
      ) : noUserFound && inputValue ? (
        <div className="text-center text-gray-500 mt-4">
          No Organization found.
        </div>
      ) : null}

      <Modal
        open={openCreateModal}
        onClose={cancelDelete}
        center
        styles={{
          modal: {
            width: "900px",
            padding: "40px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          },
          closeButton: {
            outline: "none",
            cursor: "pointer",
          },
        }}
      >
        <h2 className="text-lg font-semibold mb-4 text-center">
          Create Organisation
        </h2>
        <div className="flex flex-row gap-2 px-4 py-2 rounded-lg w-full  p-2 items-center justify-center  mb-2">
          <label className="whitespace-nowrap text-sm">
            Organisation Type:
          </label>
          <Select
            name="organisationType"
            value={form.organisationType}
            onChange={(value) => handleFieldChange("organisationType", value)}
            className="mr-2 w-full"
          >
            {organisationTypes.map((type, index) => (
              <Option key={index} value={type}>
                {type}
              </Option>
            ))}
          </Select>
          {formErrors.organisationType && (
            <div className="text-red-500 text-sm">
              {formErrors.organisationType}
            </div>
          )}
        </div>

        <div className="flex flex-row gap-2 px-4 py-2 rounded-lg w-full  p-2 items-center justify-center mb-2">
          <label className="whitespace-nowrap text-sm">
            Organisation Name:
          </label>
          <Input
            name="organisationName"
            value={form.organisationName}
            onChange={(e) =>
              handleFieldChange("organisationName", e.target.value)
            }
            className="mr-2 w-full"
          />
          {formErrors.organisationName && (
            <div className="text-red-500 text-sm">
              {formErrors.organisationName}
            </div>
          )}
        </div>

        <div className="flex flex-row gap-2 px-4 py-2 rounded-lg w-full  p-2 items-start justify-center  mb-2">
          <label className="whitespace-nowrap text-sm">Address:</label>
          <TextArea
            name="address"
            value={form.address}
            onChange={(e) => handleFieldChange("address", e.target.value)}
            className="mr-2 w-full"
          />
          {formErrors.address && (
            <div className="text-red-500 text-sm">{formErrors.address}</div>
          )}
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={cancelDelete}
            className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
            disabled={saveLoading}
          >
            {saveLoading ? (
              <>
                <FaSpinner className="animate-spin mr-2" />
                Saving...
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Modal>
    </div>
  );
};
