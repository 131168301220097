import React, { useEffect, useState } from "react";
import { get } from "../../util/APIUtils";

const BottomLeft = () => {
  const [taskSummary, setTaskSummary] = useState({ today: [], lastWeek: [] });

  const fetchTaskSummary = async () => {
    const response = await get(
      `/userproject-service/api/user-projects/taskSummaries`
    );
    setTaskSummary({ today: response.today, week: response.lastWeek });
  };

  useEffect(() => {
    fetchTaskSummary();
  }, []);

  return (
    <div className="bg-white p-4 rounded-lg">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Task Summary Overview
      </h2>
      <div className="col-span-1 grid grid-cols-2 grid-flow-row">
        <div className="bg-gradient-to-r from-[#DF43AA] to-[#F372C7] mr-2 py-2 px-6 rounded-lg transition transform duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#F372C7] hover:to-[#F372C7] hover:scale-105">
          <h3 className="font-[600] text-[1.3rem]">Total Tasks</h3>
          <div className="flex items-end justify-between">
            <div className="flex flex-row gap-0 items-end">
              <h3 className="font-[600] text-[4.5rem]">
                {taskSummary?.today?.[0]?.total ?? 0}
              </h3>
              <span className="pl-1 mb-6">Today</span>
            </div>
            <div className="mb-3">
              <p className="text-right">
                {taskSummary?.lastWeek?.[0]?.total ?? 0} more
              </p>
              <p className="text-right">from last Week</p>
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-[#F3BB4B] to-[#f7db8a] mr-2 py-2 px-6 rounded-lg transition transform duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#f7db8a] hover:to-[#f7db8a] hover:scale-105">
          <h3 className="font-[600] text-[1.3rem]">Tasks Planned</h3>
          <div className="flex items-end justify-between">
            <div className="flex flex-row gap-0 items-end">
              <h3 className="font-[600] text-[4.5rem]">
                {taskSummary?.today?.[0]?.planned ?? 0}
              </h3>
              <span className="pl-1 mb-6">Today</span>
            </div>
            <div className="mb-3">
              <p className="text-right">
                {taskSummary?.lastWeek?.[0]?.planned ?? 0} more
              </p>
              <p className="text-right">from last Week</p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#c04b4b] to-[#E38686] mr-2 my-2 py-2 px-6 rounded-lg transition transform duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#E38686] hover:to-[#E38686] hover:scale-105">
          <h3 className="font-[600] text-[1.3rem]">Tasks Completed</h3>
          <div className="flex items-end justify-between">
            <div className="flex flex-row gap-0 items-end">
              <h3 className="font-[600] text-[4.5rem]">
                {taskSummary?.today?.[0]?.completed ?? 0}
              </h3>
              <span className="pl-1 mb-6">Today</span>
            </div>
            <div className="mb-3">
              <p className="text-right">
                {taskSummary?.lastWeek?.[0]?.completed ?? 0} more
              </p>
              <p className="text-right">from last Week</p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#4bc0c0] to-[#80E1F5] mr-2 my-2 py-2 px-6 rounded-lg transition transform duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#80E1F5] hover:to-[#80E1F5] hover:scale-105">
          <h3 className="font-[600] text-[1.3rem]">Tasks In Progress</h3>
          <div className="flex items-end justify-between">
            <div className="flex flex-row gap-0 items-end">
              <h3 className="font-[600] text-[4.5rem]">
                {taskSummary?.today?.[0]?.pending ?? 0}
              </h3>
              <span className="pl-1 mb-6">Today</span>
            </div>
            <div className="mb-3">
              <p className="text-right">
                {taskSummary?.lastWeek?.[0]?.pending ?? 0} more
              </p>
              <p className="text-right">from last Week</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomLeft;
