import { FETCH_PROGRAMS, SAVE_PROGRAM, UPDATE_PROGRAM, UPDATE_PROGRAM_SCHEDULE,DELETE_PROGRAM } from '../actions/academicProgramActions';

const initialState = {
  programs: [],
  pageable: {
    pageNumber: 0,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0
  }
};

export const academicProgramReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROGRAMS:
      return {
        ...state,
        programs: action.payload.content,
        pageable: {
          pageNumber: action.payload.pageable.pageNumber,
          pageSize: action.payload.pageable.pageSize,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements
        }
      };
    case SAVE_PROGRAM:
      // let progs = {...state.programs};
      // progs.push(action.payload)
      return {
        ...state,
        programs: [...state.programs, action.payload]
      };
    case UPDATE_PROGRAM:
      return {
        ...state,
        programs: state.programs.map(program => program.id === action.payload.id ? action.payload : program)
      };
    case DELETE_PROGRAM:
      return {
        ...state,
        programs: state.programs.filter(program => program.id !== action.payload)
      };
    case UPDATE_PROGRAM_SCHEDULE:
      let programsTemp = state.programs.map(program => {
        if(program.id == action.payload.programId){
          program.schedules.map(schedule=> {
            if(schedule.id==action.payload.schedule.id){
              return action.payload.schedule;
            }
          })
          return program;
        }
      })
      return {
        ...state,
        programs: programsTemp
      }
    default:
      return state;
  }
};