import React from "react";
import { FiEdit } from "react-icons/fi";
import AuthImage from "../../components/AuthImage/AuthImage";
const ProgramTask = ({task,key, editTask,market}) => {


    return (<div className="mb-4 w-[100%]" key={key}>
    
    <div className="mb-2 mr-2 relative">
      <div className='font-semibold p-2 my-2 rounded' style={{backgroundColor:'#f4f4f4'}}>{task.title}</div>
      <div>
        
        {task.fileUrl &&
        <div><AuthImage  imageurl={task.fileUrl} className="w-[100px] h-48 object-contain"/></div>
        }
        <p dangerouslySetInnerHTML={{__html: task.description}}></p>
      </div>
      {!market && <div className="absolute right-3 top-3 cursor-pointer" onClick={()=>editTask(task)} ><FiEdit /></div>}
    </div>
    </div>
    );
    
}

export default ProgramTask;