import { SET_CURRENT_USER, SET_AUTHENTICATED } from "../actions/postActions";
import { FETCH_CHATS, CLEAR_SEARCHED_USERS } from "../actions/chatActions";
import {
  UPDATE_USER_AVATAR,
  UPDATE_USER_PROFILE,
  FETCH_TIMEZONES_REQUEST,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_TIMEZONES_FAILURE,
  SET_USER_TIMEZONE,
  SET_USER_TIMEZONE_ERROR,
} from "../actions/userActions";

const initialState = {
  currentUser: null,
  authenticated: false,
  searchedUsers: [],
  timezones: [],
  loadingTimezones: false,
  error: null,
  userTimezone: null,
  timezoneError: null,
};

// export const UPDATE_USER_AVATAR = "UPDATE_USER_AVATAR";
// export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
// export const FETCH_TIMEZONES_REQUEST = "FETCH_TIMEZONES_REQUEST";
// export const FETCH_TIMEZONES_SUCCESS = "FETCH_TIMEZONES_SUCCESS";
// export const FETCH_TIMEZONES_FAILURE = "FETCH_TIMEZONES_FAILURE";

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload,
      };
    case FETCH_CHATS:
      return {
        ...state,
        searchedUsers: action.payload,
      };
    case CLEAR_SEARCHED_USERS:
      return {
        ...state,
        searchedUsers: [],
      };
    case UPDATE_USER_AVATAR:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          avatar: action.payload.avatar,
        },
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case FETCH_TIMEZONES_REQUEST:
      return {
        ...state,
        loadingTimezones: true,
        error: null,
      };
    case FETCH_TIMEZONES_SUCCESS:
      return {
        ...state,
        loadingTimezones: false,
        timezones: action.payload,
      };
    case FETCH_TIMEZONES_FAILURE:
      return {
        ...state,
        loadingTimezones: false,
        error: action.payload,
      };
    case SET_USER_TIMEZONE:
      return {
        ...state,
        userTimezone: action.payload,
        timezoneError: null,
      };
    case SET_USER_TIMEZONE_ERROR:
      return {
        ...state,
        timezoneError: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
