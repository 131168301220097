import React, { Component } from 'react';
import './Signup.css';
import { Link, Navigate } from 'react-router-dom'
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL } from '../../constantsNew';
import { signup } from '../../util/APIUtils';
import fbLogo from '../../assets/img/fb-logo.png';
import googleLogo from '../../assets/img/google-logo.png';
import githubLogo from '../../assets/img/github-logo.png';
import { Alert } from 'react-st-modal';
import LoginBg from "../../assets/loginBg.png"

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onboardStatus:false,
            id : ''
        }
        }
    render() {
               
        

        return (
            <div className="signup-container">
                <div className="signup-content">
                    <h1 className="signup-title">Signup with Social Network</h1>
                    <SocialSignup />
                    <div className="or-separator">
                        <span className="or-text">OR</span>
                    </div>
                    <SignupForm {...this.props} />
                    <span className="login-link">Already have an account? <Link to="/login">Login!</Link></span>
                </div>
            </div>
        );
    }
}


class SocialSignup extends Component {
    render() {
        return (
            <div className="social-signup">
                <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
                    <img src={googleLogo} alt="Google" /> Sign up with Google</a>
                <a className="btn btn-block social-btn facebook" href={FACEBOOK_AUTH_URL}>
                    <img src={fbLogo} alt="Facebook" /> Sign up with Facebook</a>
                <a className="btn btn-block social-btn github" href={GITHUB_AUTH_URL}>
                    <img src={githubLogo} alt="Github" /> Sign up with Github</a>
            </div>
        );
    }
}

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            matchingPassword: '',
            socialProvider: 'LOCAL'
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.navigateToLogin = this.navigateToLogin.bind(this);
    }

   

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();   
        // const signUpRequest = Object.assign({}, this.state);

        // signup(signUpRequest)
        // .then(response => {
        //     Alert("You're successfully registered. Please login to continue!");
        //    this.props.onboardStatus = true;
        //    console.log = response
           
        // }).catch(error => {
        //     Alert((error && error.message) || 'Oops! Something went wrong. Please try again!');            
        // });
    }

    render() {
        return (
<form onSubmit={this.handleSubmit} className="flex flex-col items-center justify-center bg-gray-100">
    <div className="w-full max-w-xs">
        <div className="mb-4">
            <input type="text" name="displayName" 
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" 
                placeholder="Name"
                value={this.state.displayName} onChange={this.handleInputChange} required />
        </div>
        <div className="mb-4">
            <input type="email" name="email" 
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" 
                placeholder="Email"
                value={this.state.email} onChange={this.handleInputChange} required />
        </div>
        <div className="mb-4">
            <input type="password" name="password" 
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" 
                placeholder="Password"
                value={this.state.password} onChange={this.handleInputChange} required />
        </div>
        <div className="mb-4">
            <input type="password" name="matchingPassword" 
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" 
                placeholder="Confirm Password"
                value={this.state.matchingPassword} onChange={this.handleInputChange} required />
        </div>
        <div className="mb-6">
            <button type="submit" className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline">
                Sign Up
            </button>
        </div>
    </div>
</form>
               

        );
    }
}



class OnboardingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            matchingPassword: '',
            socialProvider: 'LOCAL'
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.navigateToLogin = this.navigateToLogin.bind(this);
    }

    navigateToOnboarding() {
        this.props.navigate("/onboarding");
      
    }
  
    
      // Call the handleFile function directly with the file
      handleChange(event) {
        const file = event.target.files[0];
      
      };

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();   
        const signUpRequest = Object.assign({}, this.state);

        signup(signUpRequest)
        .then(response => {
            Alert("You're successfully registered. Please login to continue!");
            
           
        }).catch(error => {
            Alert((error && error.message) || 'Oops! Something went wrong. Please try again!');            
        });
    }

    render() {
        return (
<div  style={{backgroundImage:`url(${LoginBg})`}} className='flex items-center h-[100vh] bg-cover justify-center ' >
        <div className='px-12 py-6 w-[800px] bg-white rounded-lg' >
            <h4 className='font-[600] text-start text-[1.3rem]' >Your Information</h4>
            <div>
                <div className='grid grid-cols-11 mt-4 mb-2 items-center justify-start gap-1' >
                    <h5 className='col-span-2' >Personal Details</h5>
                    <hr className='bg-gray-400 col-span-9 w-full' />
                </div>
                <div className='grid grid-cols-5 mt-6 gap-8 grid-flow-col' >
                    {/* <div className='col-span-1 flex items-center justify-start flex-col gap-3 ' >
                        <div onClick={handleClick} className='bg-gray-200 cursor-pointer flex items-center justify-center  w-[70px] h-[70px] rounded-full' >
                            <img src={CameraIcon} />
                        </div>
                        <p className='text-[#4379EE]' >Upload Image</p>
                        <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}} // Make the file input element invisible
        accept="audio/*, video/*, image/*" // Optionally, restrict file types
      />
                    </div> */}
                    <div className='col-span-2' >
                        <div className='' >
                            <label className='mb-2' >First Name</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your First Name' />
                        </div>
                        <div className='mt-6' >
                            <label className='mb-2' >Your Email</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your Email' />
                        </div>
                        <div className='mt-6' >
                            <label className='mb-2' >Date of Birth</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your birthdate' />
                        </div>
                    </div>
                    <div className='col-span-2' >
                        <div className='' >
                            <label className='mb-2' >Last Name</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your last name' />
                        </div>
                        <div className='mt-6' >
                            <label className='mb-2' >Phone Number</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your phone number' />
                        </div>
                        <div className='mt-6' >
                            <label className='mb-2' >Gender</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your birthdate' />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='grid grid-cols-11 mt-4 mb-2 items-center justify-start gap-1' >
                    <h5 className='col-span-3' >EDUCATIONAL DETAILS</h5>
                    <hr className='bg-gray-400 col-span-8 w-full' />
                </div>
                <div className='grid grid-cols-5 mt-2 gap-8 grid-flow-col' >
                    <div className='col-span-1' ></div>
                    <div className='col-span-2' >
                        <div className='' >
                            <label className='mb-2' >First Name</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your First Name' />
                        </div>
                       
                    </div>
                    <div className='col-span-2' >
                        <div className='' >
                            <label className='mb-2' >Last Name</label>
                            <input className='py-3 px-4 rounded-lg border-gray-300 border-[1px]  mt-1 w-full bg-gray-100' type='text' placeholder='Enter your last name' />
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className='flex mt-6 items-center justify-between'>
            <div className='text-[0.6rem]' >
                <h3>By signing up you are agreeing to the <span className='text-[#5A8CFF]' >Terms & Conditions</span> </h3>
                <p>You will shortly receive an email once your account is validated by your admin.</p>
                </div>
                <button className='py-3 text-[0.8rem] px-16 rounded-lg bg-[#4880FF] text-white' >Sign Up</button>
            </div>
        </div>
    </div>
        );
    }
}

export default Signup