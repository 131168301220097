import React, { useEffect } from "react";
import UserList from "./UserList";
import Chats from "./Chats";
import ChattingArea from "./ChattingArea";
import ProfileRight from "./ProfileRight";
import { useSelector } from "react-redux";
import WebsocketService from "../../common/WebsocketService";

const ChatArea = () => {
  const currentUserId = useSelector((state) => state.users.currentUser?.id);

  // useEffect(() => {
  //   if (currentUserId) {
  //     WebsocketService.subscribe(`/topic/chats/${currentUserId}`, (message) => {
  //       console.log("Chat Message==>", message);
  //     });
  //   }
  // }, [currentUserId]);

  return (
    <div className="grid py-3 px-3 grid-cols-4 grid-flow-col  bg-[#E8E8FF]">
      <UserList />
      <div className="col-span-2">
        <ChattingArea />
      </div>
      <div className="bg-white h-full rounded-lg col-span-1 py-6 px-3">
        <ProfileRight />
      </div>
    </div>
  );
};

export default ChatArea;
