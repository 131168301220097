import React, { useEffect, useState } from "react";
import { fetchPostById } from "../../actions/postActions";
import { useDispatch } from "react-redux";
import AuthImage from "../AuthImage/AuthImage";
import WaveformPlayer from "../LiveNotes/WaveformPlayer";
import { API_BASE_URL } from "../../constantsNew";

const Post = ({ postId, ...restProps }) => {
  const dispatch = useDispatch();
  const [post, setPost] = useState();

  useEffect(() => {
    if (postId) {
      dispatch(fetchPostById(postId, setPost));
    }
  }, [postId]);

  if (!post) {
    return <div className="p-4 text-center">Loading...</div>;
  }

  return (
    <div
      className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 overflow-hidden"
      {...restProps}
    >
      {post.fileUpload && (
        <div className="mb-4">
          {post.fileUpload.fileType.includes("image") ? (
            <AuthImage
              imageurl={`/post-service/api/file-uploads/${post.fileUpload.id}`}
              className="w-full h-auto rounded-lg"
            />
          ) : post.fileUpload.fileType.includes("audio") ? (
            <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
              <WaveformPlayer
                message={{
                  fileUpload: {
                    ...post.fileUpload,
                    id: post.fileUpload.id,
                  },
                }}
                autoPlay={false}
                showPlayPauseButton={true}
              />
            </div>
          ) : (
            <p className="text-red-500">
              Unsupported file type: {post.fileUpload.fileType}
            </p>
          )}
        </div>
      )}
      <p className="text-gray-700 dark:text-gray-300">{post.textContent}</p>
    </div>
  );
};

export default Post;
