import React from "react";

const Card = ({ title, icon: Icon, description, onClick }) => {
  return (
    <div
      className="bg-white rounded-xl  p-4 h-full cursor-pointer transition-colors duration-300"
      onClick={onClick}
    >
      <div className="flex flex-col items-center text-center group">
        <Icon className="w-12 h-12 mb-4 text-gray-600 group-hover:text-[#5F33E1] transition-colors duration-300" />
        <h4 className="text-lg font-semibold mb-2 group-hover:text-[#5F33E1] transition-colors duration-300">
          {title}
        </h4>
        <p className="text-sm text-gray-600 group-hover:text-[#5F33E1] transition-colors duration-300">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Card;
