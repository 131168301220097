import React from "react";
import { Check, X } from "lucide-react";

const EditMessageComponent = ({
  editingText,
  setEditingText,
  handleEditSubmit,
  handleEditCancel,
  messageId,
}) => {
  return (
    <div className="flex justify-end w-full">
      <div className="flex flex-col gap-2 max-w-[80%]">
        <textarea
          value={editingText}
          onChange={(e) => setEditingText(e.target.value)}
          className="w-full min-h-[100px] p-4 bg-[#5051F9] text-white rounded-t-xl rounded-l-xl resize-none focus:outline-none focus:ring-2 focus:ring-blue-400"
          placeholder="Edit your message..."
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={() => handleEditSubmit(messageId)}
            className="p-2 hover:bg-[#4040e6] bg-[#5051F9] rounded-full transition-colors duration-200"
          >
            <Check className="w-5 h-5 text-white" />
          </button>
          <button
            onClick={handleEditCancel}
            className="p-2 hover:bg-gray-200 bg-gray-100 rounded-full transition-colors duration-200"
          >
            <X className="w-5 h-5 text-gray-600" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditMessageComponent;
