import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useSearchParams,
  Outlet,
} from "react-router-dom";
import "./App.css";
import Login from "./page/Login";
import CreateAccount from "./page/CreateAccount";
import LiveNotes from "./page/LiveNotes";
import Dashboard from "./page/Dashboard";
import Planner from "./page/Planner";
import Forum from "./page/Forum";
import Program from "./page/Program";
import Reward from "./page/Reward";
import LoadingIndicator from "./components/LoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getCurrentUserDetails } from "./util/APIUtils";
import { setCurrentUser, setAuthenticated } from "./actions/postActions";
import { ACCESS_TOKEN } from "./constantsNew";
import { Alert } from "react-st-modal";
import Signup from "./page/signup/Signup";
import Settings from "./page/settings/settings";
import TeachersDashboard from "./page/TeachersDashboard";

import MyContent from "./page/MyContent";
import OnboardingPage from "./page/OnboardingScreen";
import SetupModal from "./components/Login/SetupModal";
import ProjectDetails from "./page/ProjectDetails";
import AcademicPrograms from "./page/AcademicPrograms/AcademicPrograms";
import ProgramSchedules from "./page/ProgramSchedules";
import {
  fetchUserTimezone,
  fetchNotifications,
  fetchUnreadNotificationCount,
} from "./actions/userActions"; // New import

import AcademicProgramsDetails from "./page/AcademicPrograms/AcademicProgramDetails";
import WebsocketService from "./common/WebsocketService";
import { FETCH_TRANSCRIPTION_JOB_STATUS } from "./constants/userConstants";
import {
  NEW_NOTIFICATIONS,
  NEW_NOTIFICATIONS_ARRAY,
  REFRESH_CHAT,
  TYPING_STATUS,
  TYPING_STATUS_CLEAR,
} from "./actions/chatActions";

import Sidebar from "./components/Layout/Sidebar";
import TopBar from "./components/Layout/TopBar";
import DashboardArea from "./components/Dashboard/DashboardArea";
import ChatArea from "./components/Forum/ChatArea";
import { notification } from "antd";
import Administration from "./page/Administration/administration";

// =======
// import CreateAcademicProgram from './page/CreateAcademicProgram';

const App = () => {
  const [searchParams] = useSearchParams();
  console.log("searchParams", searchParams);
  const token = searchParams.get("token");
  // console.log("Token===>",token)
  const currentUser = useSelector((state) => state.users.currentUser);
  const [state, setState] = useState({
    authenticated: false,
    currentUser: null,
    loading: true,
  });
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    localStorage.getItem("collapsed")
      ? localStorage.getItem("collapsed") === "true"
      : false
  );
  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        setState({
          currentUser: response,
          authenticated: true,
          loading: false,
        });
        dispatch(setCurrentUser(response));
        dispatch(setAuthenticated(true));
        dispatch(fetchUserTimezone(response.userName));
        dispatch(fetchNotifications());
        dispatch(fetchUnreadNotificationCount());
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        // Redirect to login if fetching user fails
        navigate("/login");
      });
  };

  useEffect(() => {
    if (token) {
      console.log("Token===>", token);
      localStorage.setItem(ACCESS_TOKEN, token);
      // console.log(JSON.stringify(response));
      Alert("You're successfully logged in!");
      const timer = setTimeout(() => {
        navigate("/livenotes");
        navigate(0);
      }, 500);
      //loadCurrentlyLoggedInUser();
    }
  }, [token]);

  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      WebsocketService.connect(() => {
        WebsocketService.subscribe(
          `/topic/add/post/${currentUser.email}`,
          (message) => {
            //const data = response.body;//JSON.parse(response.body);
            console.log("Message received via websocket=====>", message);
            if (message?.command == "TranscriptionJobCompleted") {
              dispatch({
                type: FETCH_TRANSCRIPTION_JOB_STATUS,
                payload: message,
              });
            } else {
              //setMessages(prev => [...prev, message]);
            }
            //setMessages(prevMessages => [...prevMessages, message]);
          }
        );
        WebsocketService.subscribe(
          `/topic/chat/${currentUser.id}`,
          (message) => {
            //const data = response.body;//JSON.parse(response.body);
            console.log("Message received via websocket=====>", message);
            if (message.type === "TypingStatus") {
              dispatch({ type: TYPING_STATUS, payload: message });
            } else if (message.type === "TypingStatusClear") {
              dispatch({ type: TYPING_STATUS_CLEAR, payload: message });
            } else {
              dispatch({
                type: REFRESH_CHAT,
                payload: { chatId: message.chat.id, message },
              });
            }
            //setMessages(prevMessages => [...prevMessages, message]);
          }
        );
        WebsocketService.subscribe(
          `/topic/notifications/${currentUser.email}`,
          (message) => {
            //const data = response.body;//JSON.parse(response.body);
            console.log("Message received via websocket=====>", message);
            if (message instanceof Array) {
              notification.info({
                message: `You have ${message.length} new Reminders. Check your notifications`,
                duration: 0,
              });
              dispatch({ type: NEW_NOTIFICATIONS_ARRAY, payload: message });
            } else {
              notification.info({
                message: "You have new unread notifications",
              });
              dispatch({ type: NEW_NOTIFICATIONS, payload: message });
            }

            // if(message.type==='TypingStatus'){
            //   dispatch({type: TYPING_STATUS, payload: message})
            // }
            // else if(message.type==='TypingStatusClear'){
            //   dispatch({type: TYPING_STATUS_CLEAR, payload: message})
            // } else {
            // dispatch({type: REFRESH_CHAT, payload: {chatId:message.chat.id , message}})
            // }
            //setMessages(prevMessages => [...prevMessages, message]);
          }
        );
      });
    }
    return () => {
      WebsocketService.disconnect();
    };
  }, [currentUser]);
  // useEffect(() => {
  //   if (!state.loading && !state.authenticated) {
  //     navigate('/login');
  //   }
  // }, [state.loading, state.authenticated, navigate]);

  if (state.loading) {
    return <LoadingIndicator />;
  }

  // useEffect(()=>{

  // },[])
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    localStorage.setItem("collapsed", !sidebarCollapsed);
  };
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<CreateAccount />} />
        <Route
          path="/onboarding"
          element={state.authenticated ? <OnboardingPage /> : <Login />}
        />
        <Route
          element={
            <div className="mainContainer">
              <Sidebar
                sidebarCollapsed={sidebarCollapsed}
                toggleSidebar={toggleSidebar}
              />
              <div
                className="rightContainer bg-[#E8E8FF]"
                style={{ width: sidebarCollapsed && "calc(100vw - 50px)" }}
              >
                <div className="col-span-4 ">
                  <TopBar />
                  <div style={{ height: "calc(100vh - 100px)" }}>
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <Route
            path="/"
            element={state.authenticated ? <LiveNotes /> : <Login />}
          />
          <Route
            path="/livenotes"
            element={state?.authenticated ? <LiveNotes /> : <Login />}
          />
          <Route
            path="/dashboard"
            element={state?.authenticated ? <DashboardArea /> : <Login />}
          />
          <Route
            path="/teachersDashboard"
            element={state?.authenticated ? <DashboardArea /> : <Login />}
          />
          <Route
            path="/planner"
            element={state?.authenticated ? <Planner /> : <Login />}
          />
          <Route
            path="/program"
            element={state?.authenticated ? <Program /> : <Login />}
          />
          <Route
            path="/program/:id/*"
            element={state?.authenticated ? <ProjectDetails /> : <Login />}
          >
            <Route path="posts" element={<ProjectDetails />} />
            <Route path="schedules" element={<ProjectDetails />} />
            <Route path="tasks" element={<ProjectDetails />} />
            <Route path="chats" element={<ProjectDetails />} />
            <Route path="weblinks" element={<ProjectDetails />} />
            <Route path="todos" element={<ProjectDetails />} />
            <Route path="notes" element={<ProjectDetails />} />
          </Route>
          <Route
            path="/forum"
            element={state?.authenticated ? <ChatArea /> : <Login />}
          />
          <Route
            path="/forum/:chatId"
            element={state?.authenticated ? <ChatArea /> : <Login />}
          />
          <Route
            path="/reward"
            element={state?.authenticated ? <Reward /> : <Login />}
          />
          <Route
            path="/settings"
            element={state?.authenticated ? <Settings /> : <Login />}
          />
          <Route
            path="/administration"
            element={state?.authenticated ? <Administration /> : <Login />}
          />
          <Route
            path="/acad-programs"
            element={state?.authenticated ? <AcademicPrograms /> : <Login />}
          />
          <Route
            path="/acad-programs/:id"
            element={
              state?.authenticated ? <AcademicProgramsDetails /> : <Login />
            }
          />
          <Route
            path="/marketplace"
            element={
              state?.authenticated ? (
                <AcademicPrograms market={true} />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/marketplace/:id"
            element={
              state?.authenticated ? (
                <AcademicProgramsDetails market={true} />
              ) : (
                <Login />
              )
            }
          />
        </Route>

        {/* <Route path="/create-academic-program" element={state?.authenticated ? <CreateAcademicProgram /> : <Login />} /> */}

        {/* <Route path="/forum" element={state?.authenticated ? <Forum /> : <Login />} /> */}
        {/* <Route path="/mycontent" element={state?.authenticated ? <MyContent /> : <Login />} /> */}

        {/* <Route path="/acad-programs/:id" element={state?.authenticated ? <AcademicProgramsDetails /> : <Login />} /> */}
        {/* <Route path="/acad-program-schedules" element={state?.authenticated ? <ProgramSchedules /> : <Login />} /> */}
      </Routes>
    </div>
  );
};

export default App;
