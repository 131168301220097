import React, { useState } from "react";
import { User, Globe, Shield, Bell, Lock } from "lucide-react";
import Card from "./Card";
import UserProfile from "./UserProfile";
import SecuritySettings from "./SecuritySettings";
import NotificationPreferences from "./NotificationPreferences";
import TimezoneSettings from "./TimezoneSettings";
import PrivacySettings from "./PrivacySettings";
import { OrganizationSettings } from "./OrganizationSettings";

const Settings = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const cards = [
    {
      title: "User Profile",
      component: UserProfile,
      icon: User,
      description: "Manage your personal information",
    },
    {
      title: "Timezone Settings",
      component: TimezoneSettings,
      icon: Globe,
      description: "Set your preferred timezone",
    },
    {
      title: "Security Settings",
      component: SecuritySettings,
      icon: Shield,
      description: "Configure account security options",
    },
    {
      title: "Notification Preferences",
      component: NotificationPreferences,
      icon: Bell,
      description: "Customize your notification settings",
    },
    {
      title: "Privacy Settings",
      component: PrivacySettings,
      icon: Lock,
      description: "Control your privacy preferences",
    },
    {
      title: "Join an Organization",
      component: OrganizationSettings,
      icon: Lock,
      description: "Join or Manage Organizations",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {selectedCard ? (
        <div className="w-full">
          <button
            className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setSelectedCard(null)}
          >
            Back to Settings
          </button>
          <selectedCard.component />
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold mb-6 text-center">Settings</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {cards.map((card, index) => (
              <Card
                key={index}
                title={card.title}
                icon={card.icon}
                description={card.description}
                onClick={() => setSelectedCard(card)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
