import React, { useRef, useState, useEffect, useCallback } from "react";
import DateTimeArea from "../components/LiveNotes/DateTimeArea";

import { useDispatch, useSelector } from "react-redux";
import { chatWithAi, del, get, getCurrentUser, post } from "../util/APIUtils";
import {
  addPost,
  fetchEarlierPostAction,
  fetchPostsAction,
  fetchProjectDetailsAction,
  fetchTranscriptionDataAction,
  getCurrentUserDetailsAction,
  sendActivityAction,
  setAuthenticated,
  setCurrentUser,
  uploadFileAndTranscribe,
} from "../actions/postActions";
import "./LiveNotes.css";
import BotIcon from "../assets/botIcon.png";
import Modal from "../components/Planner/Modal";
import { fetchEvents, createEvent } from "../actions/calendarActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FileUploader } from "../components/LiveNotes/FileUploadButton";
import { ACCESS_TOKEN, API_BASE_URL } from "../constantsNew";
import { FaDisplay } from "react-icons/fa6";
import SendArrow from "../assets/icons/send.svg";
import VoiceAnimationIcon from "../assets/voiceanimation.png";
import SetupModal from "../components/Login/SetupModal";
import Micmain from "../assets/mic.png";
import TextIcon from "../assets/text.svg";
import { ReactMediaRecorder } from "react-media-recorder";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import WaveSurfer from "wavesurfer.js";
import { Confirm } from "react-st-modal";
import { Bars, TailSpin, ThreeDots } from "react-loader-spinner";
import WaveformPlayer from "../components/LiveNotes/WaveformPlayer";
import moment from "moment-timezone";
import { FaEllipsisH, FaTrash, FaPlus } from "react-icons/fa";
import { Dropdown, message, Space } from "antd";
import LabelModal from "../components/LiveNotes/labelModal";
import AuthImage from "../components/AuthImage/AuthImage";
import FileIcon from "../components/FileIconRenderer/FileIconRenderer";
import ProjectModal from "../components/LiveNotes/projectModal";
import { BiPaperPlane } from "react-icons/bi";

const items = (message, onAddLabel, onDelete, onChangeProject) => {
  const menuItems = [
    {
      key: "1",
      label: <a onClick={onAddLabel}>Add/Edit Labels</a>,
      icon: <FaPlus />,
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: <a onClick={onDelete}>Delete Chat</a>,
      icon: <FaTrash />,
    },
  ];

  if (message.postType === "USER_INPUT") {
    menuItems.push(
      {
        type: "divider",
      },
      {
        key: "3",
        label: <a onClick={onChangeProject}>Change Project</a>,
        icon: <FaDisplay />,
      }
    );
  }

  return menuItems;
};

export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_EARLIER_POSTS = "FETCH_EARLIER_POSTS";
export const EDIT_POST = "EDIT_POST";
export const DELETE_POST = "DELETE_POST";

const LiveNotes = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const audioRef = useRef(null);
  const recorderRef = useRef(null);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [mp3Blob, setMp3Blob] = useState(null);
  const messageEl = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [activity, setActivity] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [meetingData, setMeetingData] = useState(true);
  const { events } = useSelector((state) => state.calendar);
  const { posts } = useSelector((state) => {
    return state.posts;
  });
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(0);
  const [endDate, setEndDate] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
    recurrenceType: "NONE",
    daysOfWeek: [],
  });
  const { users } = useSelector((state) => state);
  const { transcriptionJobStatus } = useSelector(
    (state) => state.websocketMessages
  );
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState();
  const [transcribedMessage, setTranscribedMessage] = useState({});
  const [labelModal, setLabelModal] = useState(false);
  const [labelData, setLabelData] = useState();
  const [projectModal, setProjectModal] = useState(false);

  const [view, setView] = useState("day");

  const [initDate, setInitDate] = useState();
  const [finalDate, setFinalDate] = useState();
  const [checkingConflict, setCheckingConflict] = useState(false);
  const [conflictEvent, setConflictEvent] = useState({});
  const [conflictResolving, setConflictResolving] = useState(false);

  const dispatch = useDispatch();
  const currentUser = users?.currentUser;
  const authenticated = users?.authenticated;
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    const adjustEndIfNeeded = (event) => {
      if (event && event.start && event.end) {
        const startDate = moment(event.start);
        const endDate = moment(event.end);

        if (endDate.isBefore(startDate)) {
          const newEndDate = startDate.add(30, "minutes");
          return { ...event, end: newEndDate.toISOString() };
        }
      }
      return event;
    };

    setConflictEvent((prevEvent) => adjustEndIfNeeded(prevEvent));
  }, [newEvent, conflictEvent]);

  const adjustEndIfNeeded = (event) => {
    if (event && event.start && event.end) {
      const startDate = moment(event.start);
      const endDate = moment(event.end);

      if (endDate.isBefore(startDate)) {
        const newEndDate = startDate.add(30, "minutes");
        return { ...event, end: newEndDate.toISOString() };
      }
    }
    return event;
  };

  useEffect(() => {
    if (audioSrc && waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#916BFE",
        progressColor: "#5E33E1",
        barWidth: 2,
        barHeight: 60,
        height: 65,
      });

      wavesurfer.current.load(audioSrc);
      const audioElement = audioRef.current;

      wavesurfer.current.on("ready", () => {
        audioElement.addEventListener("play", () => wavesurfer.current.play());
        audioElement.addEventListener("pause", () =>
          wavesurfer.current.pause()
        );
        audioElement.addEventListener("seeked", () =>
          wavesurfer.current.seekTo(
            audioElement.currentTime / audioElement.duration
          )
        );
        audioElement.addEventListener("timeupdate", () => {
          const currentTime = audioElement.currentTime;
          const duration = audioElement.duration;
          const progress = currentTime / duration;
          wavesurfer.current.seekTo(progress);
        });
      });

      wavesurfer.current.on("finish", () => audioElement.pause());
    }
    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
    };
  }, [audioSrc]);

  const handleRecordingStartStop = () => {
    if (isRecording) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        const url = URL.createObjectURL(blob);
        setAudioSrc(url);
        setMp3Blob(blob);
        setIsRecording(false);
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          recorderRef.current = new RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/mp3",
            recorderType: StereoAudioRecorder,
            desiredSampRate: 16000,
          });
          recorderRef.current.startRecording();
          setIsRecording(true);
        })
        .catch((error) => {
          console.error("Error accessing media devices.", error);
        });
    }
  };

  const isLastBotMessage = (index) => {
    const lastIndex = messages.reduce((acc, message, idx) => {
      return message.postType === "BOT_REPLY" ? idx : acc;
    }, -1);
    return lastIndex === index;
  };

  const handleStopRecording = (mediaBlobUrl, blob) => {
    setAudioSrc(mediaBlobUrl);
    setMp3Blob(blob);
  };

  const handleFileSend = async () => {
    if (!mp3Blob) {
      return;
    }
    setAutoScroll(true);
    setIsLoading(true);

    try {
      // Call the action to upload and transcribe the file
      const { uploadData, transcriptionData } = await uploadFileAndTranscribe(
        mp3Blob
      );

      // Dispatch the addPost action with the necessary data
      dispatch(
        addPost({
          text: "Audio.mp4",
          file: uploadData,
          transcriptionData: {
            transcribeId: transcriptionData.message.split("transcription-")[1],
            transcriptionInProgress: true,
          },
          projectId: projectData.id,
          projectName: projectData.projectName,
          userId: currentUser.id,
          postType: "USER_INPUT",
        })
      );

      setIsActive(false);
    } catch (error) {
      console.error("Error:", error);
      message.error(
        error.message || "Oops! Something went wrong. Please try again!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const [chatLoader, setChatLoader] = useState(false);

  const combineDateAndTime = (date, time) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formatDateForAPI = (dateObj) => {
      console.log("dateCheck", moment(dateObj).format("YYYY-MM-DD HH:mm:ss"));
      return moment(dateObj).format("YYYY-MM-DDTHH:mm:ss");
    };

    const parseAMPMTime = (timeStr) => {
      const timeParts = timeStr.match(/(\d+):(\d+)\s?(AM|PM)/i);
      if (!timeParts) return timeStr;

      let hours = parseInt(timeParts[1], 10);
      const minutes = timeParts[2];
      const period = timeParts[3].toUpperCase();

      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }

      return `${hours.toString().padStart(2, "0")}:${minutes}`;
    };

    if (date && time) {
      const formattedTime = parseAMPMTime(time);
      const combinedDateTime = moment.tz(`${date}T${formattedTime}`, timeZone);
      return formatDateForAPI(combinedDateTime);
    } else if (date) {
      const dateObj = moment.tz(date, timeZone);
      return formatDateForAPI(dateObj);
    } else if (time) {
      const today = moment.tz(timeZone).format("YYYY-MM-DD");
      const formattedTime = parseAMPMTime(time);
      const combinedDateTime = moment.tz(`${today}T${formattedTime}`, timeZone);
      return formatDateForAPI(combinedDateTime);
    }

    return null;
  };

  const sendActivity = async (newActivity) => {
    if (!newActivity.trim() || !authenticated) return;

    setChatLoader(true);

    try {
      const result = await dispatch(
        sendActivityAction(
          newActivity,
          currentUser,
          projectData,
          combineDateAndTime,
          adjustEndIfNeeded
        )
      );

      if (result?.conflictEvent) {
        setConflictEvent(result.conflictEvent);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setChatLoader(false);
      setActivity("");
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox") {
      setNewEvent((prev) => ({
        ...prev,
        daysOfWeek: checked
          ? [...prev.daysOfWeek, value]
          : prev.daysOfWeek.filter((day) => day !== value),
      }));
    } else {
      setNewEvent((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleFile = async (file, fileName) => {
    const formData = new FormData();
    formData.append("file", file, fileName);
    const headers = new Headers();
    if (localStorage.getItem(ACCESS_TOKEN)) {
      headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      );
    }
    setChatLoader(true);
    const response = await fetch(
      API_BASE_URL + "/post-service/api/file-uploads",
      {
        method: "POST",
        headers: headers,
        body: formData,
      }
    );

    if (!response.ok) {
      message.error(
        (response?.error && response?.error.message) ||
          "Oops! Something went wrong. Please try again!"
      );
      throw new Error("File upload failed");
    }
    const data = await response.json();
    dispatch(
      addPost({
        text: file.name,
        file: data,
        projectId: projectData.id,
        projectName: projectData.projectName,
        userId: currentUser.id,
        postType: "USER_INPUT",
      })
    );
    setChatLoader(false);
  };
  const handleSubmit = () => {
    dispatch(createEvent(newEvent, projectData)).then(() => {
      dispatch(fetchEvents());
    });

    setModalShow(false);
    setMeetingData(false);
  };
  const fetchPosts = async () => {
    try {
      const { nextPage, totalPages } = await dispatch(fetchPostsAction(page));
      setPage(nextPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    }
  };

  const fetchEarilerPost = async () => {
    try {
      const { nextPage, totalPages } = await dispatch(
        fetchEarlierPostAction(page)
      );
      setPage(nextPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Failed to fetch earlier posts:", error);
    }
  };

  const getCurrentUserDetails = async () => {
    try {
      const shouldShowModal = await dispatch(
        getCurrentUserDetailsAction(currentUser?.id)
      );
      if (shouldShowModal) {
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error fetching user details.");
    }
  };

  const fetchProjectDetails = async () => {
    try {
      const response = await dispatch(fetchProjectDetailsAction());
      setProjectData(response);
    } catch (error) {
      console.error("Error fetching project details.");
    }
  };

  useEffect(() => {
    if (messages && messages.length > 0 && page === 1 && autoScroll) {
      messageEl.current?.lastElementChild?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messages]);

  useEffect(() => {
    if (posts) {
      setMessages([...posts]);
    }
  }, [posts]);

  useEffect(() => {
    if (!currentUser) {
      getCurrentUser()
        .then((response) => {
          dispatch(setCurrentUser(response));
          dispatch(setAuthenticated(true));
        })
        .catch((error) => {
          console.error(error);
        });
    }

    getCurrentUserDetails();
    fetchPosts();

    fetchProjectDetails();
  }, [currentUser, dispatch]);

  const updateEventsAfterSave = () => {
    dispatch(fetchEvents(initDate, view, finalDate));
  };
  useEffect(() => {
    if (initDate) {
      dispatch(fetchEvents(initDate, view, finalDate));
    }
  }, [initDate, view]);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const fetchTranscriptionData = async (id) => {
    try {
      await dispatch(
        fetchTranscriptionDataAction(id, messages, transcribedMessage)
      );
    } catch (error) {
      console.error("Error fetching transcription data.");
    }
  };

  useEffect(() => {
    if (transcriptionJobStatus && transcriptionJobStatus.jobId) {
      fetchTranscriptionData(transcriptionJobStatus.jobId);
    }
  }, [transcriptionJobStatus]);

  const handleDelete = async (messageId) => {
    const result = await Confirm(
      "Are you sure you want to delete post?",
      "Delete Post"
    );
    if (result) {
      try {
        await del(`/post-service/api/posts/${messageId}`);
        dispatch({ type: DELETE_POST, payload: messageId });
      } catch (error) {
        message.error(
          (error && error.message) ||
            "Oops! we were unable to delete the post. Please try again!"
        );
        console.error("Error deleting post:", error);
      }
    }
  };
  const handleAddLabel = (message) => {
    setLabelData(message);
    setLabelModal(true);
  };

  const handleChangeProject = (message) => {
    setLabelData(message);
    setProjectModal(true);
  };

  const deleteLabel = async (message, label) => {
    console.log("Message==>", message);
    console.log("Label==>", label);

    try {
      const response = await del(
        `/post-service/api/posts/${message.id}/labels/${label.id}`
      );
      dispatch({
        type: EDIT_POST,
        id: message.id,
        payload: {
          // ...labelData,
          // labels: [...currentLabels],
          ...response,
        },
      });
    } catch (error) {
      message.error(
        (error && error.message) ||
          "Oops! we were unable to delete the label. Please try again!"
      );
      console.error("Error adding Label:", error);
    }
  };

  const handleSetView = useCallback((newView) => {
    if (newView === "dayGridMonth") {
      setView("month");
    } else if (newView === "timeGridWeek") {
      setView("week");
    } else if (newView === "timeGridDay") {
      setView("day");
    }
  }, []);

  const handleDatesChange = (date, endDate) => {
    setInitDate(date);
    setFinalDate(endDate);
  };

  return (
    <div className="h-full overflow-y-auto grid mt-4 mx-3 grid-cols-1 lg:grid-cols-4 grid-flow-col">
      <SetupModal
        showModal={showModal}
        setShowModal={setShowModal}
        onCloseModal={onCloseModal}
      />

      <div className=" bg-white py-3 px-2 lg:px-4 col-span-1 lg:col-span-2">
        <div>
          <div className=" flex flex-col rounded-lg bg-white">
            <h1>Chat with StrideCal</h1>
            <div
              ref={messageEl}
              className="chat-container overflow-y-auto"
              style={{ height: "calc(100vh - 200px)" }}
            >
              {totalPages - 1 >= page && (
                <h3
                  className="text-center text-gray-500 cursor-pointer"
                  onClick={fetchEarilerPost}
                >
                  Earlier Post
                </h3>
              )}
              <>
                {messages.length > 0 ? (
                  <>
                    {messages.map((message, index) => {
                      let showBotImage = false;

                      if (message.postType === "BOT_REPLY") {
                        const previousMessage = messages[index - 1];
                        if (
                          !previousMessage ||
                          previousMessage.postType !== "BOT_REPLY"
                        ) {
                          showBotImage = true;
                        }
                      }

                      return (
                        <>
                          {!message.postType && (
                            <p
                              className={`message ${
                                !message.postType ? "user" : "ai"
                              }`}
                            >
                              {message.fileName}
                            </p>
                          )}
                          <div className="flex w-full">
                            {showBotImage ? (
                              <img
                                className="w-[70px] h-[70px]"
                                src={BotIcon}
                              />
                            ) : (
                              <div className="w-[70px] h-[70px]"></div>
                            )}
                            <div className="flex-1">
                              {message.postType === "USER_INPUT" ? (
                                <div className="w-full flex flex-row justify-end">
                                  <Dropdown
                                    placement="bottomRight"
                                    arrow
                                    menu={{
                                      items: items(
                                        message,
                                        () => handleAddLabel(message),
                                        () => handleDelete(message.id),
                                        () => handleChangeProject(message)
                                      ),
                                    }}
                                    className="w-[70px] flex flex-row justify-end top-0 left-0 p-1 cursor-pointer"
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Space>
                                        <FaEllipsisH />
                                      </Space>
                                    </a>
                                  </Dropdown>
                                </div>
                              ) : (
                                <div className="w-full flex flex-row justify-start">
                                  <Dropdown
                                    placement="bottomLeft"
                                    arrow
                                    menu={{
                                      items: items(
                                        message,
                                        () => handleAddLabel(message),
                                        () => handleDelete(message.id)
                                      ),
                                    }}
                                    className=" w-[70px] flex flex-row justify-start top-0 left-0 p-1 cursor-pointer"
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Space>
                                        <FaEllipsisH />
                                      </Space>
                                    </a>
                                  </Dropdown>
                                </div>
                              )}
                              <div
                                className={`container${
                                  message.postType === "USER_INPUT"
                                    ? "user"
                                    : "ai"
                                }`}
                              >
                                <div
                                  key={index}
                                  className={`message ${
                                    message.postType === "USER_INPUT"
                                      ? "user"
                                      : "ai"
                                  }`}
                                  style={{ maxWidth: "70%" }}
                                >
                                  <div className="py-3 text-[0.7rem] lg:text-[1rem]">
                                    {message.fileUpload &&
                                    message.fileUpload.fileType ==
                                      "audio/wav" ? (
                                      <WaveformPlayer
                                        message={message}
                                        setActivity={setActivity}
                                        sendActivity={sendActivity}
                                      />
                                    ) : message.fileUpload &&
                                      message?.fileUpload?.fileType?.includes(
                                        "image"
                                      ) ? (
                                      <AuthImage
                                        imageurl={`/post-service/api/file-uploads/${message.fileUpload.id}`}
                                        className="flex justify-center item-center w-[350px] h-auto rounded-lg m-2"
                                      />
                                    ) : message.fileUpload ? (
                                      <FileIcon
                                        fileType={message.fileUpload.fileType}
                                        fileName={message.fileUpload.fileName}
                                        apiUrl={`${API_BASE_URL}/post-service/api/file-uploads/${message.fileUpload.id}`}
                                      />
                                    ) : (
                                      message.textContent
                                    )}
                                  </div>
                                  {message.postType === "BOT_REPLY" &&
                                    message?.textContent?.includes(
                                      "Did you say:"
                                    ) &&
                                    isLastBotMessage(index) && (
                                      <div className="border-t-[1px] py-3 border-gray-400 flex justify-center">
                                        <button
                                          onClick={() => {
                                            sendActivity(
                                              message.textContent.match(
                                                /Did you say: '(.*?)'/
                                              )[1]
                                            );
                                          }}
                                          className="text-white w-[180px] rounded-lg text-[0.8rem] py-2 px-4 bg-[#fafafa4e]"
                                        >
                                          Yes
                                        </button>
                                      </div>
                                    )}
                                  {message.postType === "BOT_REPLY" &&
                                    message?.textContent?.includes(
                                      "The event you are trying to create conflicts with the event:"
                                    ) &&
                                    conflictEvent &&
                                    Object.keys(conflictEvent).length > 0 &&
                                    isLastBotMessage(index) &&
                                    !conflictResolving && (
                                      <div className="border-t-[1px] py-3 border-gray-400 flex justify-center gap-2">
                                        <button
                                          onClick={() => {
                                            const botReply = {
                                              text: `Created event for ${
                                                conflictEvent?.title
                                              } starting at ${moment(
                                                conflictEvent?.start_date + "Z",
                                                "YYYY-MM-DDTHH:mm:ssZ"
                                              )
                                                .utc()
                                                .local()
                                                .format(
                                                  "Do MMM, YYYY, ddd hh:mm:ss a"
                                                )} .`,
                                              userId: "ai",
                                              postType: "BOT_REPLY",
                                            };
                                            dispatch(addPost(botReply));

                                            dispatch(
                                              createEvent(
                                                conflictEvent,
                                                projectData
                                              )
                                            ).then(() => {
                                              dispatch(fetchEvents());
                                            });
                                          }}
                                          className="text-white w-[180px] rounded-lg text-[0.8rem] py-2 px-4 bg-[#fafafa4e]"
                                        >
                                          Continue
                                        </button>
                                        <button
                                          onClick={() => {
                                            setConflictResolving(true);
                                          }}
                                          className="text-white w-[180px] rounded-lg text-[0.8rem] py-2 px-4 bg-[#fafafa4e]"
                                        >
                                          Update Date
                                        </button>
                                      </div>
                                    )}
                                  {message.postType === "BOT_REPLY" &&
                                    message?.textContent?.includes(
                                      "The event you are trying to create conflicts with the event:"
                                    ) &&
                                    conflictEvent &&
                                    Object.keys(conflictEvent).length > 0 &&
                                    isLastBotMessage(index) &&
                                    conflictResolving && (
                                      <>
                                        <div className="border-t-[1px] py-3 border-gray-400 flex justify-center gap-2">
                                          <div>
                                            <label>Start Date and Time:</label>
                                            <DatePicker
                                              selected={moment(
                                                conflictEvent.start
                                              ).toDate()}
                                              className="liveNotesInputArea"
                                              onChange={(date) => {
                                                if (
                                                  !conflictEvent.end ||
                                                  conflictEvent.end < date
                                                ) {
                                                  setConflictEvent((prev) => ({
                                                    ...prev,
                                                    start: date,
                                                    end: moment(date)
                                                      .add(1, "hour")
                                                      .toDate(),
                                                  }));
                                                } else {
                                                  setConflictEvent((prev) => ({
                                                    ...prev,
                                                    start: date,
                                                  }));
                                                }
                                              }}
                                              showTimeSelect
                                              dateFormat="Pp"
                                            />
                                          </div>
                                          <div>
                                            <label>End Date and Time:</label>
                                            <DatePicker
                                              selected={moment(
                                                conflictEvent.end
                                              ).toDate()}
                                              className="liveNotesInputArea"
                                              onChange={(date) => {
                                                if (
                                                  !conflictEvent.end ||
                                                  conflictEvent.start < date
                                                ) {
                                                  setConflictEvent((prev) => ({
                                                    ...prev,
                                                    end: moment(
                                                      conflictEvent.start
                                                    )
                                                      .add(1, "hour")
                                                      .toDate(),
                                                  }));
                                                } else {
                                                  setConflictEvent((prev) => ({
                                                    ...prev,
                                                    end: date,
                                                  }));
                                                }
                                              }}
                                              showTimeSelect
                                              dateFormat="Pp"
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <button
                                            onClick={() => {
                                              setConflictResolving(false);
                                            }}
                                            className="text-white mx-6 w-[180px] rounded-lg text-[0.8rem] py-2 px-4 bg-[#fafafa4e]"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            onClick={() => {
                                              const botReply = {
                                                text: `Created event for ${
                                                  conflictEvent?.title
                                                } starting at ${moment(
                                                  conflictEvent?.start_date +
                                                    "Z",
                                                  "YYYY-MM-DDTHH:mm:ssZ"
                                                )
                                                  .utc()
                                                  .local()
                                                  .format(
                                                    "Do MMM, YYYY, ddd hh:mm:ss a"
                                                  )} .`,
                                                userId: "ai",
                                                postType: "BOT_REPLY",
                                              };
                                              dispatch(addPost(botReply));

                                              dispatch(
                                                createEvent(
                                                  conflictEvent,
                                                  projectData
                                                )
                                              ).then(() => {
                                                dispatch(fetchEvents());
                                              });
                                              setConflictResolving(false);
                                            }}
                                            className="text-white mx-6 w-[180px] rounded-lg text-[0.8rem] mt-3 py-2 px-4 bg-[#fafafa4e] "
                                          >
                                            Create Event
                                          </button>
                                        </div>
                                      </>
                                    )}
                                </div>
                              </div>
                              <div
                                key={`date-${index}`}
                                className={`${
                                  message.postType === "USER_INPUT"
                                    ? "user date"
                                    : "ai date"
                                }`}
                                style={
                                  message.postType === "USER_INPUT"
                                    ? {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-end",
                                      }
                                    : {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                      }
                                }
                              >
                                {message.labels &&
                                  message.labels.length > 0 && (
                                    <div
                                      className={
                                        message.postType === "BOT_REPLY"
                                          ? "flex flex-wrap gap-2 max-h-40 overflow-y-auto"
                                          : "flex flex-wrap justify-end gap-2 max-h-40 overflow-y-auto"
                                      }
                                    >
                                      {message.labels.map((label) => (
                                        <span className="flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-700">
                                          <span>#{label.label}</span>{" "}
                                          <span
                                            className="px-2 cursor-pointer"
                                            onClick={() =>
                                              deleteLabel(message, label)
                                            }
                                          >
                                            x
                                          </span>
                                        </span>
                                      ))}
                                    </div>
                                  )}
                                <div>
                                  {moment(
                                    message.createdDate + "Z",
                                    "YYYY-MM-DDTHH:mm:ssZ"
                                  )
                                    .utc()
                                    .local()
                                    .format("Do MMM, YYYY, ddd hh:mm:ss a")}
                                </div>
                              </div>
                              {message.transcriptionData &&
                                message.transcriptionData
                                  .transcriptionInProgress && (
                                  <div
                                    style={{
                                      color: "#B6B6B6",
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                      gap: 5,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Bars
                                      height="10"
                                      width="10"
                                      color="#B6B6B6"
                                      ariaLabel="bars-loading"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                      visible={true}
                                    />
                                    Understanding your instructions...
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center text-justify text-[#AFAFAF] px-10 md:px-8">
                    <h2 className="font-bold mb-4 text-center">
                      Hi there, I'm Preppy!
                    </h2>
                    <p className="mb-2">
                      I'm here to help you organize your schedule.
                    </p>
                    <p>
                      You can start by sharing your upcoming events and tasks,
                      and I'll help you plan them out efficiently.
                    </p>
                    <p className="mb-2">Here’s how you can get started:</p>

                    <h3 className="font-bold mt-4 mb-2 text-center">
                      Examples of what you can tell me:
                    </h3>
                    <p className="mb-2">
                      "I have a math class tomorrow at 10:00 AM for 1 hour."
                    </p>
                    <p className="mb-2">
                      "I need to attend a 3-hour workshop on Stress Management
                      for Teens today at 4:00 PM."
                    </p>
                    <p className="mb-2">
                      "Remind me to attend my English class on the 13th at 11:00
                      AM."
                    </p>

                    <h3 className="font-bold mt-4 mb-2 text-center">
                      You can also ask me to:
                    </h3>
                    <p className="mb-2">
                      "Summarize my biology project notes."
                    </p>
                    <p className="mb-2">
                      "Track the due dates for my assignments."
                    </p>
                    <p className="mb-2">
                      "Help break down my history project into smaller tasks."
                    </p>
                    <p className="mb-2">
                      "Remind me to prepare for the chemistry test next Monday."
                    </p>
                    <p className="mb-2">
                      "Organize my study schedule for final exams."
                    </p>

                    <p className="mt-6 text-center">
                      Feel free to ask me any other questions or queries you
                      have, and I'll do my best to assist you!
                    </p>
                  </div>
                )}
              </>
            </div>
            {!isActive ? (
              <div className="chat-input">
                {chatLoader ? (
                  <div className="flex justify-center items-center">
                    <TailSpin
                      height="30"
                      width="30"
                      color="#5E33E1"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : checkingConflict ? (
                  <div className="chat-input">
                    <div className="flex justify-center items-center">
                      <TailSpin
                        height="30"
                        width="30"
                        color="#5E33E1"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />{" "}
                      Checking for conflicting events...
                    </div>
                  </div>
                ) : (
                  <>
                    <input
                      type="text"
                      value={activity}
                      onChange={(e) => setActivity(e.target.value)}
                      placeholder="Enter activity"
                      onKeyPress={(e) =>
                        e.key === "Enter" && sendActivity(activity)
                      }
                      disabled={chatLoader}
                      className="flex-1 p-2 border rounded"
                      style={{
                        maxHeight: "4rem",
                        overflowY: "auto",
                      }}
                    />

                    <img
                      src={Micmain}
                      onClick={() => {
                        setIsActive(true);
                      }}
                      className="mx-1 cursor-pointer h-[1.5rem] lg:h-[2rem] mt-1 lg:mt-0 w-[1.5rem] lg:w-[2rem]"
                      style={{ pointerEvents: isLoading ? "none" : "auto" }}
                    />
                    <button
                      onClick={() => sendActivity(activity)}
                      disabled={isLoading}
                    >
                      <img
                        src={TextIcon}
                        className="mx-1 cursor-pointer w-[0.8rem] lg:w-[1.1rem]"
                        style={{
                          pointerEvents: isLoading ? "none" : "auto",
                        }}
                      />
                    </button>
                    <FileUploader
                      handleFile={(file) => handleFile(file, file.name)}
                    />
                    {activity && (
                      <button
                        onClick={() => sendActivity(activity)}
                        disabled={isLoading}
                        className="ml-2 p-2 bg-[#577CFF] text-white rounded-full"
                      >
                        <BiPaperPlane />
                      </button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <ReactMediaRecorder
                  audio
                  onStop={handleStopRecording}
                  mediaRecorderOptions={{
                    mimeType: "audio/wav",
                    audio: true,
                    recorderType: RecordRTC.StereoAudioRecorder,
                  }}
                  render={({
                    status,
                    startRecording,
                    stopRecording,
                    mediaBlobUrl,
                  }) => (
                    <>
                      <button
                        onClick={() => {
                          handleRecordingStartStop(
                            startRecording,
                            stopRecording
                          );
                        }}
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          className="mx-1 w-[80px] cursor-pointer "
                          src={VoiceAnimationIcon}
                        />
                        {isRecording ? "Stop Recording" : "Start Recording"}
                      </button>
                      {audioSrc && (
                        <div className="w-full max-w-xl flex items-center justify-center rounded-full p-4">
                          <div className="flex items-center justify-center bg-gray-200 hover:bg-gray-300 rounded-full p-2 mr-4">
                            <audio
                              id="audio-element"
                              ref={audioRef}
                              src={audioSrc}
                              controls
                              className="hidden"
                            />
                            <button
                              onClick={() => audioRef.current.play()}
                              className="p-1"
                            >
                              <svg
                                className="w-8 h-8 text-gray-700"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 4.5v15l12-7.5-12-7.5z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                          <div ref={waveformRef} className="w-full"></div>
                          <button
                            onClick={handleFileSend}
                            className={`ml-4 p-4 bg-gray-200 rounded-full hover:bg-gray-300 ${
                              isLoading ? "cursor-not-allowed" : ""
                            }`}
                          >
                            {isLoading ? (
                              <ThreeDots
                                color="#5E33E1"
                                height={24}
                                width={24}
                              />
                            ) : (
                              <img src={SendArrow} alt="Send" />
                            )}
                          </button>
                        </div>
                      )}
                      <button
                        className="mt-4 p-2 bg-red-500 text-white rounded-lg"
                        onClick={async () => {
                          if (audioSrc) {
                            const result = await Confirm(
                              "You have a recorded audio. Do you want to discard it?",
                              "Discard Recording"
                            );
                            if (result) {
                              setAudioSrc(null);
                              setMp3Blob(null);
                              setIsActive(false);
                            }
                          } else {
                            setIsActive(false);
                          }
                        }}
                      >
                        Close
                      </button>
                    </>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <DateTimeArea
        events={events}
        display={"timeGridDay"}
        view={view}
        handleSetView={handleSetView}
        handleDatesChange={handleDatesChange}
        updateEventsAfterSave={updateEventsAfterSave}
      />
      <Modal showModal={modalShow} setShowModal={setModalShow}>
        <form className="space-y-4">
          <div>
            <label>Title:</label>
            <input
              type="text"
              name="title"
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent((prev) => ({ ...prev, title: e.target.value }))
              }
            />
          </div>
          <div>
            <label>Start Date and Time:</label>
            <DatePicker
              selected={newEvent.start}
              onChange={(date) =>
                setNewEvent((prev) => ({ ...prev, start: date }))
              }
              showTimeSelect
              dateFormat="Pp"
            />
          </div>
          <div>
            <label>End Date and Time:</label>
            <DatePicker
              selected={newEvent.end}
              onChange={(date) =>
                setNewEvent((prev) => ({ ...prev, end: date }))
              }
              showTimeSelect
              dateFormat="Pp"
            />
          </div>
          <div>
            <label>Recurrence Type:</label>
            <select
              name="recurrenceType"
              value={newEvent.recurrenceType}
              onChange={(e) =>
                setNewEvent({ ...newEvent, recurrenceType: e.target.value })
              }
            >
              <option value="">Select...</option>
              <option value="NONE">None</option>
              <option value="DAILY">Daily</option>
              <option value="WEEKLY">Weekly</option>
              <option value="MONTHLY">Monthly</option>
              <option value="YEARLY">Yearly</option>
              <option value="DAYS_OF_WEEK">Days of Week</option>
            </select>
          </div>
          {newEvent.recurrenceType === "DAYS_OF_WEEK" && (
            <div>
              <label>Select Days:</label>
              {daysOfWeek.map((day) => (
                <div key={day}>
                  <input
                    type="checkbox"
                    value={day}
                    checked={newEvent.daysOfWeek.includes(day)}
                    onChange={handleChange}
                  />{" "}
                  {day}
                </div>
              ))}
            </div>
          )}
          <div></div>

          <div>
            <button
              type="button"
              className="button-danger"
              onClick={() => setModalShow(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="button-info"
              onClick={handleSubmit}
            >
              Save Event
            </button>
          </div>
        </form>
      </Modal>
      <LabelModal
        open={labelModal}
        onClose={() => {
          setLabelModal(false);
          setLabelData();
        }}
        labelData={labelData}
        setAutoScroll={setAutoScroll}
      />
      <ProjectModal
        open={projectModal}
        onClose={() => setProjectModal(false)}
        postData={labelData}
      />
    </div>
  );
};

export default LiveNotes;
