import React from "react";
import { Link } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import GoogleImage from "../../assets/google.png";
import { GOOGLE_AUTH_URL } from "../../constantsNew";

const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  showPassword,
  setShowPassword,
  handleGoogleLogin,
}) => {
  const [token, setToken] = React.useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleLogin(token);
    } catch (err) {
      setRefreshReCaptcha(!refreshReCaptcha);
      console.error("Login error:", err);
    }
  };

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handleVerify = React.useCallback((token) => {
    setToken(token);
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
      <div className="bg-white rounded-[20px] px-12 py-[3.3rem]">
        <div>
          <h3 className="text-[32px] font-[600]">Login to Account</h3>
          <p className="my-2 text-gray-500 text-[18px]">
            Please enter your email and password to continue
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mt-12">
            <div className="flex items-start mt-8 justify-start flex-col">
              <label className="mb-3">Email address:</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-[#F1F4F9] rounded-[8px] w-full border-[1px] border-[#D8D8D8] py-3 px-4"
                placeholder="esteban_schiller@gmail.com"
              />
            </div>
            <div className="flex items-start mt-8 justify-start flex-col">
              <div className="flex items-center w-full justify-between mb-3">
                <p className="">Password:</p>
                <p>Forgot Password?</p>
              </div>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-[#F1F4F9] rounded-[8px] w-full border-[1px] outline-none border-[#D8D8D8] py-3 px-4"
                  placeholder="******"
                />
                <button
                  type="button"
                  disabled={!password}
                  onClick={() => setShowPassword(!showPassword)}
                  className="!absolute right-2 top-4"
                >
                  {showPassword ? (
                    <EyeIcon className="w-[15px] text-blue-500" />
                  ) : (
                    <EyeSlashIcon className="w-[15px] text-blue-500" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <GoogleReCaptcha
            onVerify={handleVerify}
            refreshReCaptcha={refreshReCaptcha}
          />

          <div className="mt-8">
            <button
              type="submit"
              className="bg-[#4880FF] py-3 text-white rounded-[8px] w-[100%]"
            >
              Sign In
            </button>
            <a href={GOOGLE_AUTH_URL}>
              <button
                type="button"
                onClick={handleGoogleLogin}
                className="bg-[#ebebeb] flex items-center justify-center gap-3 py-3 text-black rounded-[8px] mt-3 w-[100%]"
              >
                <img src={GoogleImage} alt="Google logo" /> Sign In With Google
              </button>
            </a>
          </div>
        </form>
        <div className="mt-12">
          <p className="mt-3">
            Don't have an account?{" "}
            <Link to="/signup">
              <span className="text-[#4880FF] font-[500] underline">
                Create Account
              </span>
            </Link>
          </p>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default LoginForm;
