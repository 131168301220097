import { NEW_NOTIFICATIONS, NEW_NOTIFICATIONS_ARRAY } from "../actions/chatActions";
import { FETCH_NOTIFICATIONS, FETCH_UNREAD_NOTIFICATION_COUNT, MARK_NOTIFICATION_READ } from "../constants/userConstants";

const initialState = {
    notifications: [],
    notificationPage : {},
    unreadNotificationCount: 0,
  };

  export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_NOTIFICATIONS:
        return { ...state, notifications: [...state.notifications, ...action.payload.content], notificationPage: action.payload.pageable };
      case FETCH_UNREAD_NOTIFICATION_COUNT:
        return {...state, unreadNotificationCount: action.payload};
      case NEW_NOTIFICATIONS:
        return {...state, notifications: [action.payload,...state.notifications]};  
    case NEW_NOTIFICATIONS_ARRAY:
        return {...state, notifications: [...action.payload,...state.notifications]};  
      case MARK_NOTIFICATION_READ:
        let newNotifications = [];
        let newCount = state.unreadNotificationCount-1;
        newNotifications = state.notifications?.map(n => {
            let newN={...n}
            if(newN.id===action.payload.id){
                newN={...action.payload}
            }
            return newN;
            })
            console.log("N===>",newNotifications)
        return {...state,notifications: [...newNotifications],unreadNotificationCount: newCount};
    
      default:
        return state;
    }
  };
  
  