
import {  getFile} from '../../util/APIUtils';
import { API_BASE_URL } from '../../constantsNew';
export const getImage = async (params) => {
  const { imageurl: url, setImage, setIsLoading } = params;
  const request = {
    method: "GET",
    url:`${API_BASE_URL}${url}`,
    responseType: "arraybuffer",
  };
  setIsLoading(true);
  let response = await getFile(request);
  const binaryData = await response.arrayBuffer();
  const base64 = arrayBufferToBase64(binaryData);
  const dataUrl = `data:image/png;base64,${base64}`;
  setImage(dataUrl);
    
  setIsLoading(false);
  
}

function arrayBufferToBase64(buffer) {

  try {
    const CHUNK_SIZE = 0x8000; // Arbitrary chunk size
    const bytes = new Uint8Array(buffer);
    let binary = '';
    let i;

    for (i = 0; i < bytes.length; i += CHUNK_SIZE) {
        binary += String.fromCharCode.apply(null, bytes.subarray(i, i + CHUNK_SIZE));
    }

    return window.btoa(binary);
} catch (e) {
    console.error("Error converting array buffer to base64:", e);
    throw e;
}
  // return btoa(String.fromCharCode(...new Uint8Array(buffer)));
}
