// src/services/webSocketService.js
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { WEBSOCKET_URL, WEBSOCKET_URL_WS } from "../constantsNew";
class WebSocketService {
  constructor() {
    this.client = new Client({
      brokerURL: WEBSOCKET_URL_WS,
      connectHeaders: {
        login: "user",
        passcode: "password",
      },
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      webSocketFactory: function () {
        return new SockJS(WEBSOCKET_URL);
      },
    });

    this.client.onConnect = () => {
      console.log("Connected",this.client);
      if (this.onConnectCallback) {
        this.onConnectCallback();
      }
    };
    this.client.onStompError = (frame) => {
      console.error("Broker reported error: " + frame.headers["message"]);
      console.error("Additional details: " + frame.body);
    };
  }

  connect(onConnectCallback) {
    this.onConnectCallback = onConnectCallback;
    this.client.activate();
  }

  disconnect() {
    this.client.deactivate();
  }

  subscribe(topic, callback) {
    console.log("Subscribing to:", topic);
    if (this.client.connected) {
      this.client.subscribe(topic, (message) => {
        callback(JSON.parse(message.body));
      });
    } else {
      console.error("Cannot subscribe, not connected");
    }
  }

  send(destination, message) {
    if (this.client.connected) {
      this.client.publish({
        destination: destination,
        body: JSON.stringify(message),
      });
    } else {
      console.error("Cannot send message, not connected");
    }
  }
}

export default new WebSocketService();
