import React, { useEffect, useState } from "react";
import { get } from "../../util/APIUtils";

const BottomRight = () => {
  const [loading, setLoading] = useState(false);
  const [projectProgress, setProjectProgress] = useState([]);

  const fetchProjectProgress = async () => {
    setLoading(true);
    const response = await get(
      `/userproject-service/api/user-projects/projectProgress`
    );
    setProjectProgress(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchProjectProgress();
  }, []);

  console.log("rose--->>>", projectProgress);

  return (
    <div className="pt-4 px-6 rounded-lg bg-gradient-to-r from-pink-200 via-pink-300 to-pink-400 shadow-lg">
      <h3 className="font-bold text-2xl text-gray-800 mb-4">
        Projects Progress
      </h3>
      <div className="h-[350px] overflow-y-auto grid grid-cols-1 lg:grid-cols-2 gap-4">
        {projectProgress?.map((proj, index) => (
          <div className="flex items-center my-2 bg-white px-4 py-3 rounded-xl shadow-md transition-shadow duration-200 hover:shadow-xl">
            {/* Task Number */}
            {/* <div className="flex-shrink-0 flex items-center justify-center rounded-full w-12 h-12 bg-pink-100 text-pink-600 text-lg font-semibold">
              {index + 1}
            </div> */}

            {/* Task Details */}
            <div className="flex-grow ml-4">
              <h6 className="font-semibold text-lg text-gray-700 ">
                {proj.projectName}
              </h6>
              {/* <p className="text-sm text-gray-500 mt-1">
                {proj.deadline || "No deadline set"}
              </p> */}
            </div>

            {/* Circular Progress Border */}
            <div
              className="relative flex items-center justify-center"
              style={{
                width: "50px",
                height: "50px",
                background: `conic-gradient(#5051F9 ${
                  proj.progress * 3.6
                }deg, #EDECFE 0deg)`,
                borderRadius: "50%",
              }}
            >
              {/* Inner Circle to Create Border-Only Effect */}
              <div className="flex items-center justify-center bg-white w-10 h-10 rounded-full">
                <span className="font-semibold text-sm text-gray-600">
                  {proj.progress}%
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomRight;
