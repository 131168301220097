// reducers/myFilesReducer.js
import { FETCH_FILES, UPLOAD_FILE, UPDATE_FILE, DELETE_FILE } from '../actions/myFilesActions';

const initialState = {
  files: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
};

export const myFilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FILES:
      return {
        ...state,
        files: action.payload.content,
        totalItems: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.number,
      };
    case UPLOAD_FILE:
      return { ...state, files: [...state.files, action.payload] };
    case UPDATE_FILE:
      return {
        ...state,
        files: state.files.map(file => file.id === action.payload.id ? action.payload : file),
      };
    case DELETE_FILE:
      return { ...state, files: state.files.filter(file => file.id !== action.payload) };
    default:
      return state;
  }
};
