import React, { useEffect, useState } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import CreateAccountForm from "../components/Login/CreateAccountForm";
import LoginBg from "../assets/loginBg.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onboard, sendOTP, signup, verifyOTP } from "../util/APIUtils";
import SignupEmail from "../components/Login/SignupEmail";
import OTPValidate from "../components/Login/OTPValidate";
import { message } from "antd";

const OTPValidateWithCaptcha = ({
  code,
  submitCode,
  setCode,
  handleCodeChange,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmitWithCaptcha = async () => {
    if (!executeRecaptcha) {
      message.error("reCAPTCHA not initialized");
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha("submit_otp");
      if (!recaptchaToken) {
        message.error("Security verification failed. Please try again.");
        return;
      }

      // Pass the token to submitCode
      submitCode(recaptchaToken);
    } catch (error) {
      message.error("Security verification failed. Please try again.");
    }
  };

  return (
    <OTPValidate
      code={code}
      submitCode={handleSubmitWithCaptcha}
      setCode={setCode}
      handleCodeChange={handleCodeChange}
    />
  );
};

const CreateAccount = () => {
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector((state) => state);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSendOtp = () => {
    if (email.length === 0 || !emailRegex.test(email)) {
      message.error("Enter valid email");
    } else {
      sendOTP({ email })
        .then((response) => {
          setStep(2);
        })
        .catch((error) => {
          message.error(
            (error && error.message) ||
              "Oops! Something went wrong. Please try again!"
          );
        });
    }
  };

  const handleCodeChange = (e) => {
    setCode(e);
  };

  const submitCode = async (recaptchaToken) => {
    if (code.length === 0) {
      message.error("Enter valid OTP!");
      return;
    }

    const data = {
      email: email,
      otp: code,
      captchaValue: recaptchaToken,
    };

    try {
      const response = await verifyOTP(data);
      setStep(3);
    } catch (error) {
      message.error((error && error.message) || "Wrong OTP entered!");
    }
  };

  const handleRegister = async () => {
    if (name.length === 0) {
      message.error("Enter Valid Name!");
    } else if (email.length === 0 || !emailRegex.test(email)) {
      message.error("Enter Valid Email!");
    } else if (password.length === 0) {
      message.error("Enter Valid Password!");
    } else {
      const signUpRequest = {
        displayName: name,
        email: email,
        password: password,
        matchingPassword: password,
        socialProvider: "LOCAL",
      };

      try {
        const response = await signup(signUpRequest);
        navigate("/login");
      } catch (error) {
        message.error(
          (error && error.message) ||
            "Oops! Something went wrong. Please try again!"
        );
      }
    }
  };

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <div
      style={{ backgroundImage: `url(${LoginBg})` }}
      className="flex items-center h-[100vh] bg-cover justify-center"
    >
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
        {step === 1 && (
          <SignupEmail
            email={email}
            setEmail={setEmail}
            handleSendOtp={handleSendOtp}
          />
        )}
        {step === 2 && (
          <OTPValidateWithCaptcha
            code={code}
            submitCode={submitCode}
            setCode={setCode}
            handleCodeChange={handleCodeChange}
          />
        )}
        {step === 3 && (
          <CreateAccountForm
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            showConfirmPassword={showConfirmPassword}
            setShowConfirmPassword={setShowConfirmPassword}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
            handleRegister={handleRegister}
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        )}
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default CreateAccount;
