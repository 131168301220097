import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import PreppyDog from "../../assets/preppyDog.png"
import { Link } from "react-router-dom";
export default function SetupModal({showModal,setShowModal,onCloseModal}) {
 
 
  return (
    <>
      
      <Modal classNames={{modal:"rounded-lg w-[900px] "}}  open={showModal} onClose={onCloseModal} center>
          <div className="flex items-center justify-center flex-col" >
            <img  src={PreppyDog} />
            <p className="my-6 text-center px-12" >Your profile is almost ready. Finish your profile so we can <br /> track your progress accurately.</p>
            <div className="flex items-center mb-8 justify-center gap-6" >
              <Link to="/onboarding" ><button  className="bg-[#4880FF] outline-none border-none text-white rounded-lg px-8 py-3" >Complete my profile</button></Link>  
                <button  onClick={onCloseModal} className="outline-none border-none px-8 py-3" >Thanks, I’ll do it later</button>
            </div>
          </div>
        </Modal>
    </>
  );
}