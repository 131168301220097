import React from "react";
import { Bell } from "lucide-react";

const NotificationPreferences = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Notification Preferences</h2>
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center mb-4">
          <Bell className="w-8 h-8 text-blue-500 mr-4" />
          <h3 className="text-xl font-semibold">Manage Your Notifications</h3>
        </div>
        <p className="text-gray-600 mb-4">
          Customize how and when you receive notifications.
        </p>
        <p>Notification preferences coming sooon!!</p>
      </div>
    </div>
  );
};

export default NotificationPreferences;
