import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ACCESS_TOKEN, API_BASE_URL } from "../constantsNew";
import { fetchProjectData, selectProject } from "../actions/userProjectActions";
import ProjectItems from "../components/Projects/ProjectItems";
import { message } from "antd";

const ProjectDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.projects);
  console.log("actiontest3", project, project.selectedProject);

  const fetchProject = () => {
    const headers = new Headers({
      "Content-Type": "application/json",
    });
    if (localStorage.getItem(ACCESS_TOKEN)) {
      headers.append(
        "Authorization",
        "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      );
    }

    fetch(API_BASE_URL + `/userproject-service/api/user-projects/${id}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("actiontest1");
        dispatch(selectProject(data));
        console.log(data);
      })
      .catch((error) => {
        message.error(
          (error && error.message) ||
            "Oops! Unable to fetch project details. Please try again!"
        );
        console.error("Error fetching project:", error);
      });
  };

  useEffect(() => {
    dispatch(fetchProjectData(id));
  }, [id]);

  return (
    <div>
      <ProjectItems
        project={project.selectedProject}
        fetchProjects={fetchProject}
      />
    </div>
  );
};

export default ProjectDetails;
