// actions/myFilesActions.js
import { get, post, put, del, custom_request } from '../util/APIUtils'; // Assume these are API helper methods
import { API_BASE_URL } from '../constantsNew';

export const FETCH_FILES = 'FETCH_FILES';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPDATE_FILE = 'UPDATE_FILE';
export const DELETE_FILE = 'DELETE_FILE';

export const fetchFiles = (page = 0, size = 10) => {
  return async (dispatch) => {
    const response = await get(`/api/myfiles?page=${page}&size=${size}`);
    dispatch({ type: FETCH_FILES, payload: response });
  };
};

export const uploadFile = (fileData) => {
  return async (dispatch) => {
    const response = await post('/api/myfiles/upload', fileData);
    dispatch({ type: UPLOAD_FILE, payload: response });
  };
};

export const updateFile = (id, fileData) => {
  return async (dispatch) => {
    const response = await put(`/api/myfiles/${id}`, fileData);
    dispatch({ type: UPDATE_FILE, payload: response });
  };
};

export const deleteFile = (id) => {
  return async (dispatch) => {
    await del(`/api/myfiles/${id}`);
    dispatch({ type: DELETE_FILE, payload: id });
  };
};

export const downloadFile = (fileKey) => {

    return async () => {
      const options = {
        url: API_BASE_URL+'/api/myfiles/download',
        method: 'POST',
        body: JSON.stringify(fileKey),
        responseType: 'blob',
      };
      const response = await custom_request(options);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileKey);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    };
  };
  