import React, { useEffect, useState } from "react";
import { Table, Button, Pagination } from "antd";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import "tailwindcss/tailwind.css";
import { get } from "../../util/APIUtils";
import { useSelector } from "react-redux";
import { toBeRequired } from "@testing-library/jest-dom/matchers";

const dummyData = Array.from({ length: 50 }, (_, index) => ({
  key: index,
  name: `User ${index + 1}`,
  email: `user${index + 1}@example.com`,
  requestDate: new Date().toLocaleDateString(),
  requestType: [
    "Create Organization xyz",
    "Upgrade Account to Organiztion Admin",
    "Delete xyz",
  ][index % 3],
}));

const columns = [
  {
    title: "User Name",
    dataIndex: "name",
    key: "name",
    render: (text) => (
      <span className="font-semibold text-gray-700">{text}</span>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text) => <span className="text-gray-600">{text}</span>,
  },
  {
    title: "Request Date",
    dataIndex: "requestDate",
    key: "requestDate",
  },
  {
    title: "Request Type",
    dataIndex: "requestType",
    key: "requestType",
    render: (text) => (
      <span className="px-2 py-1 text-sm font-medium text-blue-700 bg-blue-100 rounded-full">
        {text}
      </span>
    ),
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, record) => (
      <div className="flex space-x-2">
        <Button
          type="primary"
          icon={<AiOutlineCheck />}
          // className="bg-green-300 hover:bg-green-600 border-none text-white flex items-center"
          onClick={() => handleApprove(record)}
        >
          Approve
        </Button>
        <Button
          danger
          icon={<AiOutlineClose />}
          // className="bg-red-100 hover:bg-red-600 border-none text-white flex items-center"
          onClick={() => handleDisapprove(record)}
        >
          Disapprove
        </Button>
      </div>
    ),
  },
];

const handleApprove = (record) => {
  console.log("Approved:", record);
};

const handleDisapprove = (record) => {
  console.log("Disapproved:", record);
};

const Approvals = () => {
  const { currentUser } = useSelector((state) => state.users);
  const [wfReqList, setWfReqList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (currentUser.id) {
      fetchWfRequests(currentUser.id);
    }
  }, [currentUser]);

  const fetchWfRequests = async (reqId) => {
    try {
      const response = await get(
        `/stridecal-backend/api/workflow-requests/${reqId}`
      );
      if (response) {
        setWfReqList(response);
      }
    } catch (error) {
      console.log("Error Fetching OrganisationTypes");
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const paginatedData = dummyData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="container h-[78vh] mx-auto p-6 bg-white rounded-lg shadow-lg overflow-y-auto">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">
        Request Approvals
      </h2>
      <Table
        columns={columns}
        dataSource={paginatedData}
        pagination={false}
        rowClassName="bg-gray-50 hover:bg-gray-100 transition duration-200 ease-in-out"
        className="mb-4"
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={dummyData.length}
        onChange={handlePageChange}
        showSizeChanger
        pageSizeOptions={["5", "10", "20", "50"]}
        className="flex justify-end"
      />
    </div>
  );
};

export default Approvals;
