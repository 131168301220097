import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPrograms,
  saveProgram,
  updateProgram,
  deleteProgram,
  subscribe,
  publish,
  unsubscribe,
} from "../../actions/academicProgramActions";
import { Alert } from "react-st-modal";
import Modal from "../../components/Planner/Modal";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ContentImg from "../../assets/imagePlaceholder.png";
import { notification, message, Form } from "antd";
import ProgramModal from "./ProgramModal";

const AcademicProgram = (props) => {
  const [form] = Form.useForm();
  const { market } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { programs, pageable } = useSelector((state) => state.academicPrograms);
  const currentUser = useSelector((state) => state.users.currentUser);
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [program, setProgram] = useState({
    id: "",
    programName: "",
    description: "",
    duration: "",
    level: "",
    mode: "",
    type: "",
    department: "",
    faculty: "",
    university: "",
    country: "",
    city: "",
    website: "",
    email: "",
    phone: "",
    address: "",
    postalCode: "",
    state: "",
    countryId: "",
    cityId: "",
    departmentId: "",
    facultyId: "",
    universityId: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    createdBy: "",
    updatedBy: "",
    deletedBy: "",
    status: "",
    isDeleted: false,
    isPublished: false,
    isFeatured: false,
    isVerified: false,
    isApproved: false,
    isRejected: false,
    isPending: false,
    isDraft: false,
    isArchived: false,
    isBlocked: false,
    isSpam: false,
    isFlagged: false,
    isReported: false,
    isClosed: false,
    isBanned: false,
    isHidden: false,
    isPublic: false,
    isPrivate: false,
    isProtected: false,
    isPaid: false,
    isFree: false,
    isFeaturedHome: false,
    isFeaturedCategory: false,
    isFeaturedSubCategory: false,
    isFeaturedPage: false,
    isFeaturedPost: false,
    isFeaturedEvent: false,
    isFeaturedCourse: false,
    isFeaturedInternship: false,
    schedules: [],
  });
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  useEffect(() => {
    dispatch(fetchPrograms(market));
    // dispatch(fetchSchedules());
  }, [dispatch, market]);

  useEffect(() => {
    if (showCreateProgramModal == false) {
      setCurrentStep(0);
      form.resetFields();
      setProgram({
        id: "",
        programName: "",
        description: "",
        duration: "",
        level: "",
        mode: "",
        type: "",
        department: "",
        faculty: "",
        university: "",
        country: "",
        city: "",
        website: "",
        email: "",
        phone: undefined,
        address: "",
        postalCode: "",
        state: "",
        countryId: "",
        cityId: "",
        departmentId: "",
        facultyId: "",
        universityId: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
        createdBy: "",
        updatedBy: "",
        deletedBy: "",
        status: "",
        isDeleted: false,
        isPublished: false,
        isFeatured: false,
        isVerified: false,
        isApproved: false,
        isRejected: false,
        isPending: false,
        isDraft: false,
        isArchived: false,
        isBlocked: false,
        isSpam: false,
        isFlagged: false,
        isReported: false,
        isClosed: false,
        isBanned: false,
        isHidden: false,
        isPublic: false,
        isPrivate: false,
        isProtected: false,
        isPaid: false,
        isFree: false,
        isFeaturedHome: false,
        isFeaturedCategory: false,
        isFeaturedSubCategory: false,
        isFeaturedPage: false,
        isFeaturedPost: false,
        isFeaturedEvent: false,
        isFeaturedCourse: false,
        isFeaturedInternship: false,
        schedules: [],
      });
    }
  }, [showCreateProgramModal]);

  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await getBase64(file);
        return {
          default: base64,
        };
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
  }, []);

  const handleEditorChange = (data) => {
    setProgram((prevState) => ({
      ...prevState,
      description: data,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProgram((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const createProgram = () => {
    setProgram({});
    setShowCreateProgramModal(true);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (program.id) {
      dispatch(updateProgram(program.id, program)).then(() => {
        message.success("Program updated successfully");
        setShowCreateProgramModal(false);
        // setCurrentStep(0);
        dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
        // form.resetFields();
      });
    } else {
      if (!program.programName) {
        return Alert("Please enter program title");
      }
      dispatch(saveProgram(program)).then(() => {
        message.success("Program saved successfully");
        setShowCreateProgramModal(false);
        // setCurrentStep(0);
        dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
        // form.resetFields();
      });
    }
    // setProgram({
    //   id: "",
    //   programName: "",
    //   description: "",
    //   duration: "",
    //   level: "",
    //   mode: "",
    //   type: "",
    //   department: "",
    //   faculty: "",
    //   university: "",
    //   country: "",
    //   city: "",
    //   website: "",
    //   email: "",
    //   phone: undefined,
    //   address: "",
    //   postalCode: "",
    //   state: "",
    //   countryId: "",
    //   cityId: "",
    //   departmentId: "",
    //   facultyId: "",
    //   universityId: "",
    //   createdAt: "",
    //   updatedAt: "",
    //   deletedAt: "",
    //   createdBy: "",
    //   updatedBy: "",
    //   deletedBy: "",
    //   status: "",
    //   isDeleted: false,
    //   isPublished: false,
    //   isFeatured: false,
    //   isVerified: false,
    //   isApproved: false,
    //   isRejected: false,
    //   isPending: false,
    //   isDraft: false,
    //   isArchived: false,
    //   isBlocked: false,
    //   isSpam: false,
    //   isFlagged: false,
    //   isReported: false,
    //   isClosed: false,
    //   isBanned: false,
    //   isHidden: false,
    //   isPublic: false,
    //   isPrivate: false,
    //   isProtected: false,
    //   isPaid: false,
    //   isFree: false,
    //   isFeaturedHome: false,
    //   isFeaturedCategory: false,
    //   isFeaturedSubCategory: false,
    //   isFeaturedPage: false,
    //   isFeaturedPost: false,
    //   isFeaturedEvent: false,
    //   isFeaturedCourse: false,
    //   isFeaturedInternship: false,
    //   schedules: [],
    // });
  };

  const handleEdit = (e, program) => {
    e.stopPropagation();
    setProgram(program);
    setShowCreateProgramModal(true);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    dispatch(deleteProgram(id)).then(() => {
      Alert("Program deleted successfully");
      dispatch(fetchPrograms(market, pageable.pageNumber, pageable.pageSize));
    });
  };

  const handleScheduleChange = (schedules) => {
    setProgram({ ...program, schedules });
  };

  const goToDetails = (p) => {
    if (market) {
      navigate(`/marketplace/${p.id}`);
    } else {
      navigate(`/acad-programs/${p.id}`);
    }
  };

  const handlePageChange = (pageNumber) => {
    dispatch(fetchPrograms(market, pageNumber, pageable.pageSize));
  };

  const toggleMenu = (program) => {
    setSelectedProgram(selectedProgram === program ? null : program);
  };

  const onSubscribe = (response) => {
    console.log("RES====>", response);
    const { res, message } = response;
    if (message) {
      notification.success({ message: message });
    } else {
      if (res.message) {
        notification.error({ message: res.message });
      } else {
        notification.error({ message: "Program Subscribed Successfully" });
      }
    }
    // if(res.id){

    //   // if(res.isPublished ===true){
    //     notification.success({message:'Program subscribed successfully'});
    //   // } else {
    //   //   notification.success({message:'Program unsubscribed successfully'});
    //   // }
    // } else {
    //   if(res.message){
    //     notification.error({message:res.message});
    //   } else {
    //     notification.error({message: "Program could not be subscribed. Please try again later"})
    //   }
    // }
  };

  const onPublished = (res) => {
    console.log(res);
    if (res.id) {
      if (res.isPublished === true) {
        notification.success({ message: "Program published successfully" });
      } else {
        notification.success({ message: "Program unpublished successfully" });
      }
    } else {
      if (res.message) {
        notification.error({ message: res.message });
      } else {
        notification.error({
          message: "Program could not be published. Please try again later",
        });
      }
    }
  };

  const subscribeProgram = (program) => {
    let subscribed = program.academicProgramSubscriptions?.find(
      (ps) => ps.userName === currentUser.email && (ps.active ==null || ps.active)
    );

    if (subscribed) {
      dispatch(unsubscribe(program, onSubscribe));
    } else {
      dispatch(subscribe(program, onSubscribe));
    }
  };

  const showSubscription = (prog) => {
    if (prog.academicProgramSubscriptions) {
      let subscribed = prog.academicProgramSubscriptions.find(
        (ps) => ps.userName === currentUser.email && (ps.active ==null || ps.active)
      );
      if (subscribed) {
        return (
          <div
            style={{ borderRadius: 4, display: "inline-block" }}
            className="bg-[#44bb00] p-1 text-white"
          >
            Subscribed
          </div>
        );
      } else {
        return null;
      }
    }
    return null;
  };
  const publishProgram = (p, r) => {
    dispatch(publish(p, r, onPublished));
  };
  return (
    <div className="w-full p-4">
      <div className="w-full flex flex-col">
        <div className="flow-root flex items-center">
          <h2 className="float-left text-2xl font-bold mb-4 flex justify-between">
            Academic Programs
          </h2>
          {!market && (
            <div className="float-right h-fit min-h-full flex justify-end">
              <button
                onClick={createProgram}
                className="p-2 bg-blue-600 text-white rounded flex items-center"
              >
                <BiPlus size={20} className="mr-2" /> Create program
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {programs && programs.length > 0 ? (
          programs.map((prog, i) => (
            <div
              key={`prog-${i}`}
              className="bg-white p-4 rounded shadow relative cursor-pointer"
              onClick={() => goToDetails(prog)}
            >
              <div className="flex justify-between mb-4">
                <div className="w-full">
                  <img
                    src={ContentImg} // Replace with actual image URL or a default image
                    alt={prog?.programName}
                    className="w-full h-40 object-cover rounded-t"
                  />
                </div>
                <div className="absolute top-4 right-4">
                  <button
                    className="text-gray-600 hover:text-gray-800 p-2 rounded-full"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenu(prog);
                    }}
                    // onBlur={()=>setSelectedProgram(null)}
                  >
                    &#x22EE;
                  </button>
                  {selectedProgram?.id === prog.id && (
                    <div className="absolute right-0 mt-2 w-24 bg-white border rounded shadow-lg z-10">
                      <button
                        className="block w-full text-left px-4 py-2 text-blue-600 hover:text-blue-800"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(e, prog);
                          setSelectedProgram(null);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="block w-full text-left px-4 py-2 text-red-600 hover:text-red-800"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(e, prog.id);
                          setSelectedProgram(null);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="h-24 overflow-hidden"
                style={{ position: "relative" }}
              >
                {showSubscription(prog)}
                <h3 className="font-semibold text-[#7C38FD]">
                  {prog?.programName}
                </h3>

                <p
                  className="text-sm text-gray-600 rte whitespace-normal text-ellipsis"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                  dangerouslySetInnerHTML={{ __html: prog.description }}
                ></p>
              </div>
              <div className="flex justify-end">
                {currentUser?.id === prog.ownerId && !market && (
                  <button
                    className="mt-2 mr-2 w-full bg-[#7C38FD33] hover:bg-[#7C38FD] hover:text-white text-[#7C38FD] font-bold py-2 px-4 rounded-b transition-colors duration-300"
                    onClick={(e) => {
                      e.stopPropagation();
                      publishProgram(prog, !prog.isPublished);
                    }}
                  >
                    {prog.isPublished ? `Unpublish` : "Publish"}
                  </button>
                )}
                {(currentUser?.id !== prog.ownerId) && (
                  <button
                    className="mt-2  w-full bg-[#7C38FD33] hover:bg-[#7C38FD] hover:text-white text-[#7C38FD] font-bold py-2 px-4 rounded-b transition-colors duration-300"
                    onClick={(e) => {
                      e.stopPropagation();
                      subscribeProgram(prog);
                    }}
                  >
                    {prog.academicProgramSubscriptions?.find(
                      (ps) => ps.userName === currentUser.email && (ps.active ==null || ps.active)
                    )
                      ? "Unsubscribe"
                      : "Subscribe"}
                  </button>
                )}
                {(currentUser?.id == prog.ownerId && market) && (
                  <button
                    className="mt-2  w-full bg-[#7C38FD33] hover:bg-[#cccccc] hover:text-white text-[#7C38FD] font-bold py-2 px-4 rounded-b transition-colors duration-300"
                    disabled={true}
                    
                  >
                    Created by you
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>No programs added</div>
        )}
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(pageable.pageNumber - 1)}
          disabled={pageable.pageNumber === 0}
          className="p-2 bg-gray-200 text-black rounded"
        >
          Previous
        </button>
        <span>
          Page {pageable.pageNumber + 1} of {pageable.totalPages}
        </span>
        <button
          onClick={() => handlePageChange(pageable.pageNumber + 1)}
          disabled={pageable.pageNumber + 1 >= pageable.totalPages}
          className="p-2 bg-gray-200 text-black rounded"
        >
          Next
        </button>
      </div>
      <ProgramModal
        showCreateProgramModal={showCreateProgramModal}
        setShowCreateProgramModal={setShowCreateProgramModal}
        program={program}
        setProgram={setProgram}
        handleChange={handleChange}
        handleEditorChange={handleEditorChange}
        uploadPlugin={uploadPlugin}
        handleSave={handleSave}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        form={form}
        Form={Form}
      />
    </div>
  );
};

export default AcademicProgram;
