import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  FaCalendarAlt,
  FaClock,
  FaSyncAlt,
  FaRegCalendarTimes,
} from "react-icons/fa";
import SchedulesEventCard from "../Projects/SchedulesEventCard";

const ProgramCalendarEvent = (props) => {
  const dispatch = useDispatch();
  const { event, scheduleId, projectId, eventId, ...restProps } = props;
  const [calEvent, setCalEvent] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (event) {
      setCalEvent(event);
    }
  }, [event]);

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div
        className="max-w-full p-6 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 cursor-pointer hover:shadow-xl transition-shadow duration-200"
        style={{ backgroundColor: calEvent?.colorCode || "" }}
        onClick={handleCardClick}
        {...restProps}
      >
        {calEvent && (
          <>
            <div className="flex items-center mb-4">
              <FaCalendarAlt className="icon-standard mr-2" />
              <div className="font-bold text-xl text-gray-800 dark:text-white">
                {calEvent.title}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {calEvent.summary && (
                <div className="flex items-start">
                  <div className="text-gray-600 dark:text-gray-300">
                    {calEvent.summary}
                  </div>
                </div>
              )}
              {/* {calEvent.eventType && (
                <div className="flex items-start">
                  <div className="text-gray-600 dark:text-gray-300">
                    Event Type: {calEvent.eventType}
                  </div>
                </div>
              )} */}
            </div>

            <div className="flex items-center text-gray-700 dark:text-gray-200 mb-2">
              <span>
                {calEvent.startTime
                  ? moment
                      .utc(calEvent.startTime, "YYYY-MM-DDTHH:mm:aa.SSS")
                      .local()
                      .format("LT")
                  : ""}
              </span>
              {calEvent.endTime && (
                <span>
                  {" "}
                  -{" "}
                  {moment
                    .utc(calEvent.endTime, "YYYY-MM-DDTHH:mm:aa.SSS")
                    .local()
                    .format("LT")}
                </span>
              )}
            </div>

            {calEvent.recurrenceType && (
              <div className="flex items-center text-gray-600 dark:text-gray-400 mb-4">
                <FaSyncAlt className="icon-standard mr-2" />
                Recurrence: {calEvent.recurrenceType}
              </div>
            )}

            {calEvent.eventEndDate &&
            (calEvent.recurrenceType !== "NONE" ||
              calEvent.recurrenceType != null) ? (
              <div className="flex items-center text-gray-600 dark:text-gray-400">
                <FaRegCalendarTimes className="icon-standard mr-2" />
                Event End Date: {calEvent.eventEndDate}
              </div>
            ) : calEvent.endDate ? (
              <div className="flex items-center text-gray-600 dark:text-gray-400">
                <FaRegCalendarTimes className="icon-standard mr-2" />
                Event End Date: {calEvent.endDate}
              </div>
            ) : null}
          </>
        )}
      </div>

      <SchedulesEventCard
        event={calEvent}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        scheduleId={scheduleId}
        projectId={projectId}
        eventId={eventId}
      />
    </>
  );
};

export default ProgramCalendarEvent;
