import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal } from "react-responsive-modal";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BiTime, BiX } from "react-icons/bi";
import { Tooltip } from "antd";
import "react-responsive-modal/styles.css";
import moment from "moment";
import "./DateTimeArea.css";
import { CalendarTabRenderer } from "./CalendarTabRenderer";
import DetailsModal from "../Planner/DetailsModal";

const DateTimeArea = ({
  events,
  display,
  handleSetView,
  handleDatesChange,
  updateEventsAfterSave,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [prevDateRange, setPrevDateRange] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState(display ?? "dayGridMonth");
  const [modalShow, setModalShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const handleModalClose = () => setModalShow(false);

  const handleModalShow = (event) => {
    // If it's a FullCalendar Event object
    console.log("Event Modal ==>", event);
    if (event.toPlainObject) {
      console.log("toplainObject ==>", event);
      setSelectedEvent({
        id: event.id,
        title: event.title,
        startDate: event.start,
        endDate: event.end,
        colorCode: event.backgroundColor?.slice(0, -2),
        eventType: event.extendedProps.eventType,
        recurrenceType: event.extendedProps.recurrenceType,
        seriesId: event.extendedProps.seriesId,
        summary: event.extendedProps.summary,
      });
    } else {
      // If it's custom event object from the slider
      setSelectedEvent({
        id: event.id,
        title: event.title,
        startDate: event.start,
        endDate: event.end,
        colorCode: event.color?.slice(0, -2),
        eventType: event.eventType,
        recurrenceType: event.recurrenceType,
        seriesId: event.seriesId,
        summary: event.summary,
      });
    }
    setModalShow(true);
  };

  const handleDateChange = (action) => {
    const calendarApi = calendarRef.current.getApi();
    if (action === "prev") {
      calendarApi.prev();
    } else if (action === "next") {
      calendarApi.next();
    } else if (action === "today") {
      calendarApi.today();
    }
    setCurrentDate(calendarApi.getDate());
  };

  const handleButtonClick = (view) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(view);
  };

  const handleMoreEventsClick = (info) => {
    const date = info.date;
    const dayStart = moment(date).startOf("day");
    const dayEnd = moment(date).endOf("day");

    const dayEvents = formatEvents(events).filter((event) => {
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
      return (
        eventStart.isBetween(dayStart, dayEnd, "day", "[]") ||
        eventEnd.isBetween(dayStart, dayEnd, "day", "[]")
      );
    });

    dayEvents.sort(
      (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
    );

    setSelectedDateEvents(dayEvents);
    setSelectedDate(date);
    setIsSliderOpen(true);

    return true;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const slider = document.getElementById("event-slider");
      if (slider && !slider.contains(event.target) && isSliderOpen) {
        setIsSliderOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isSliderOpen]);

  useEffect(() => {
    document.body.classList.add("app-calendar");
    return () => {
      document.body.classList.remove("app-calendar");
    };
  }, []);

  const formatEvents = (events) => {
    console.log("Events====>", events);
    return events.map((event) => ({
      id: event.id,
      eventType: event.eventType,
      title: event.title,
      start: event.startDate,
      end: event.endDate,
      color: `${event.colorCode}9A`,
      backgroundColor: `${event.colorCode}9A`,
      textColor: "#fff",
      recurrenceType: event.recurrenceType,
      seriesId: event.seriesId,
      summary: event.summary ? event.summary : "",
    }));
  };

  const renderEventContent = (eventInfo) => {
    if (eventInfo.view.type === "dayGridMonth") {
      return (
        <Tooltip title={`${eventInfo.event.title} - ${eventInfo.timeText}`}>
          <div
            className="fc-event-content"
            style={{
              backgroundColor: eventInfo.backgroundColor,
              width: 4,
              height: 4,
            }}
            onClick={() => handleModalShow(eventInfo.event)}
          >
            <div className="fc-event-title">{eventInfo.event.title}</div>
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={`${eventInfo.event.title} - ${eventInfo.timeText}`}>
        <div
          className="fc-event-content"
          style={{ padding: 5 }}
          onClick={() => handleModalShow(eventInfo.event)}
        >
          <div
            className="fc-event-title"
            style={{
              marginBottom: 5,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {eventInfo.event.title}
          </div>
          <hr />
          <div className="fc-event-title">
            {eventInfo.view.type === "timeGridDay" && (
              <span
                style={{ display: "inline-block", padding: 5, paddingLeft: 0 }}
              >
                <BiTime color="white" size={19} />
              </span>
            )}
            <span
              style={{
                display: "inline-block",
                padding: 5,
                verticalAlign: "top",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {eventInfo.timeText}
            </span>
          </div>
        </div>
      </Tooltip>
    );
  };

  const handleDatesSet = (dateInfo) => {
    const { start, end, view: currentView } = dateInfo;
    handleSetView(currentView.type);
    handleDatesChange(
      start.toISOString().split("T")[0],
      end.toISOString().split("T")[0]
    );
  };

  const calendarRef = React.createRef();
  let scrollTime = moment().format("HH") + ":00:00";

  return (
    <div className="date-time-area lg:col-span-2 hidden lg:block bg-white rounded-lg px-3 py-3 ml-3">
      <div className="">
        <CalendarTabRenderer
          currentDate={currentDate}
          view={view}
          setView={setView}
          handleDateChange={handleDateChange}
          handleButtonClick={handleButtonClick}
        />
        <FullCalendar
          id="custom-calendar"
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={view}
          headerToolbar={false}
          height={
            view == "timeGridWeek"
              ? "calc(100vh - 345px)"
              : "calc(100vh - 300px)"
          }
          events={formatEvents(events)}
          dayMaxEventRows={5}
          moreLinkClick={handleMoreEventsClick}
          slotEventOverlap={view === "timeGridWeek"}
          eventMaxStack={view === "timeGridWeek" ? 1 : 4}
          eventContent={renderEventContent}
          eventDisplay="list-item"
          nowIndicator={true}
          eventClick={(info) => handleModalShow(info.event)}
          datesSet={handleDatesSet}
          scrollTime={scrollTime}
        />

        <DetailsModal
          showModal={modalShow}
          setShowModal={setModalShow}
          onCloseModal={handleModalClose}
          event={selectedEvent}
          updateEventsAfterSave={updateEventsAfterSave}
        />

        <div
          id="event-slider"
          className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
            isSliderOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex justify-between items-center px-6 py-4 border-b">
            <h2 className="text-lg font-medium text-gray-800">
              {selectedDate && moment(selectedDate).format("MMMM D, YYYY")}
            </h2>
            <button
              onClick={() => setIsSliderOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <BiX size={24} />
            </button>
          </div>

          <div className="overflow-y-auto h-[calc(100%-64px)]">
            {selectedDateEvents.map((event) => (
              <div
                key={event.id}
                className="px-6 py-3 border-b border-gray-100 hover:bg-gray-50 cursor-pointer"
                onClick={() => {
                  handleModalShow(event);
                  setIsSliderOpen(false);
                }}
              >
                <div className="flex items-start">
                  <div
                    className="w-2 h-2 rounded-full mt-2 mr-3 flex-shrink-0"
                    style={{ backgroundColor: event.color }}
                  />
                  <div className="flex-grow">
                    <h3 className="font-medium text-gray-800">{event.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">
                      {moment(event.start).format("h:mm A")} -{" "}
                      {moment(event.end).format("h:mm A")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {isSliderOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-25 z-40"
            onClick={() => setIsSliderOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DateTimeArea;
