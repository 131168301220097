import React from "react";
import SearchInput from "./SearchInput";
import Chats from "./Chats";

const UserList = () => {
  return (
    <div
      className="bg-white overflow-y-scroll rounded-lg col-span-1 py-6 px-4"
      style={{ height: "calc(100vh - 120px)" }}
    >
      <h3 className="font-[600] text-left text-[1.6rem]">Messages</h3>
      <div>
        <SearchInput />
        <Chats />
      </div>
    </div>
  );
};

export default UserList;
