import React, { useEffect, useState, useCallback } from "react";
import SearchIcon from "../../assets/icons/search.svg";
import DefaultUserIcon from "../../assets/default-user-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../util/APIUtils";
import { setAuthenticated, setCurrentUser } from "../../actions/postActions";
import {
  fetchSearchedUser,
  CLEAR_SEARCHED_USERS,
  createChat,
  fetchChats,
} from "../../actions/chatActions";
import debounce from "lodash/debounce";
import AuthImage from "../AuthImage/AuthImage";
import { FaSquarePlus } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

const SearchInput = () => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const searchedUsers = useSelector((state) => state.chat.searchedUsers);

  useEffect(() => {
    if (!currentUser) {
      getCurrentUser()
        .then((response) => {
          dispatch(setCurrentUser(response));
          dispatch(setAuthenticated(true));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentUser, dispatch]);

  const debouncedFilterUsers = useCallback(
    debounce((value) => {
      if (value.length >= 3) {
        dispatch(fetchSearchedUser(value));
      } else {
        dispatch({ type: CLEAR_SEARCHED_USERS });
      }
    }, 300),
    [dispatch]
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedFilterUsers(value);
  };

  const handleUserClick = async (user) => {
    await dispatch(createChat(user.id));
    setInputValue("");
    dispatch({ type: CLEAR_SEARCHED_USERS });
    dispatch(fetchChats());
  };

  const handleClear = () => {
    setInputValue("");
    dispatch({ type: CLEAR_SEARCHED_USERS });
  };

  return (
    <div>
      <div className="relative my-2 col-span-6">
        <input
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={(e) =>
            e.key === "Enter" && dispatch(fetchSearchedUser(e.target.value))
          }
          className="py-3 px-12 w-full outline-none rounded-lg bg-[#FAFAFA]"
          placeholder="Search User"
        />
        <img
          className="absolute w-[15px] left-[5%] top-[30%]"
          src={SearchIcon}
          alt="search-icon"
        />
        {inputValue && (
          <button
            onClick={handleClear}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
          >
            <FaTimes />
          </button>
        )}

        {searchedUsers.length > 0 && (
          <div className="absolute w-full bg-white shadow-lg rounded-lg mt-1 max-h-60 overflow-y-auto">
            {searchedUsers
              .filter(
                (user) =>
                  user.firstName && user.lastName && user.id !== currentUser.id
              )
              .map((user) => (
                <div
                  key={user.id}
                  className="p-4 hover:bg-gray-200 cursor-pointer border-b border-gray-300 flex items-center"
                  onClick={() => handleUserClick(user)}
                >
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full mr-3 flex-shrink-0">
                      <AuthImage
                        imageurl={user.avatar || DefaultUserIcon}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="inline-block">
                      <div className="text-sm font-semibold">{`${user.firstName} ${user.lastName}`}</div>
                      <div className="text-xs text-gray-500">{user.email}</div>
                    </div>
                  </div>
                  <div className="ml-auto text-3xl">
                    <FaSquarePlus />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
