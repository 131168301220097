import React, { useState, useRef, useEffect } from "react";
import { Confirm } from "react-st-modal";
import { put } from "../../util/APIUtils";
import { message } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch } from "react-redux";
import { selectProject } from "../../actions/userProjectActions";

const EditableContent = ({
  text,
  dataKey,
  data,
  style,
  onChange,
  onBlur,
  onFocus,
  isRichtext = false, // Prop to toggle between text and rich text
}) => {
  const [editing, setEditing] = useState(false);
  const [updateText, setUpdateText] = useState(text);
  const [isConfirming, setIsConfirming] = useState(false); // Prevent repeated confirmation dialogs
  const editorWrapperRef = useRef(null); // Reference to CKEditor container
  const dispatch = useDispatch();
  useEffect(() => {
    setUpdateText(text);
  }, [text]);

  const handleFocus = () => {
    setEditing(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleSave = async () => {
    if (updateText !== text) {
      if (updateText.length < 1) {
        message.error("Field Cannot be empty!");
        setUpdateText(text);
        return;
      }
      setIsConfirming(true); // Start the confirmation process
      const isConfirmed = await Confirm(
        "Are you sure you want to save changes?",
        "Confirm Save"
      );

      if (isConfirmed) {
        const payload = { ...data, [dataKey]: updateText };
        delete payload.id;
        try {
          const response = await put(
            `/userproject-service/api/user-projects/${data?.id}`,
            payload
          );
          dispatch(selectProject(response));
          message.success("Project updated successfully.");
          setUpdateText(updateText); // Update the displayed text after saving
        } catch (error) {
          message.error(`Error updating project: ${error.message}`);
        }
      } else {
        setUpdateText(text); // Revert the text if user cancels the save
      }
      setIsConfirming(false); // Reset confirmation status
    }
    setEditing(false); // End editing mode
  };

  const handleChange = (e) => {
    setUpdateText(e.target.value);
  };

  const handleRichTextChange = (event, editor) => {
    const richTextValue = editor.getData();
    setUpdateText(richTextValue);
  };

  const handleRichTextFocus = () => {
    setEditing(true); // Set editing mode only when editor is focused
  };

  const handleRichTextBlur = async () => {
    if (!isConfirming) {
      await handleSave();
    }
  };

  useEffect(() => {
    const handleClickOutside = async (event) => {
      if (
        editorWrapperRef.current &&
        !editorWrapperRef.current.contains(event.target) &&
        !isConfirming // Prevent triggering save while confirmation is happening
      ) {
        await handleSave();
      }
    };

    if (editing) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editing, updateText, text, isConfirming]);

  return (
    <div>
      {editing && isRichtext ? (
        <div ref={editorWrapperRef}>
          <CKEditor
            editor={ClassicEditor}
            data={updateText}
            onChange={handleRichTextChange}
            onFocus={handleRichTextFocus}
          />
        </div>
      ) : isRichtext ? (
        <div
          dangerouslySetInnerHTML={{ __html: updateText }}
          onClick={handleFocus}
          style={{
            cursor: "pointer",
            padding: "5px",
            border: editing ? "1px solid grey" : "none",
            background: editing ? "white" : "transparent",
          }}
        />
      ) : (
        <input
          type="text"
          value={updateText}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleRichTextBlur}
          className={style ? style : ""}
          style={{
            border: editing ? "1px solid grey" : "none",
            background: editing ? "white" : "transparent",
            width: "100%",
            padding: editing ? "2px" : "0",
            cursor: editing ? "text" : "pointer",
            color: editing ? "black" : "inherit",
          }}
          readOnly={!editing}
        />
      )}
    </div>
  );
};

export default EditableContent;
