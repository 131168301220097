import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import EventStatus from "./EventStatus";
import { EVENT_TYPES } from "../../constantsNew";
// import { BiMenu} from 'react-icons/bi'
import { BsThreeDots, BsLoader, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Spin } from 'antd';
import { useDispatch } from "react-redux";
import { fetchPlans, updateEvent } from "../../actions/calendarActions";

const EventCard = ({key, event,handleSetEvent,setShowModal,toggleStatus})=>{
    const [showMenu,setShowMenu]=useState(false);
    const [updating,setUpdating]=useState(false);
    const [updatePlans,setUpdatePlans]=useState(false);

    const dispatch = useDispatch();
    const formatTime = (timestamp) => {
        return moment(timestamp).utc().local().format("h:mm A");
      };
    const menuRef = useRef(null)

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
        }
        //
    };
      useEffect(() => {
        // Add event listener for clicks outside the menu
        document.addEventListener("mousedown", handleClickOutside);
    
        // Cleanup function to remove the event listener
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
      const toggleModal = () => {
      
        setShowMenu(!showMenu);
      };

      const updateStatus = ()=>{
        // toggleStatus();
        setUpdating(true)
        dispatch(updateEvent(event.id, {isCompleted: !event.isCompleted}, setUpdating,setUpdatePlans));
        setShowMenu(false)
      }

      useEffect(()=>{
        if(!updating && updatePlans){
            dispatch(fetchPlans());
        }
      },[updating,updatePlans])
    //   dispatch(fetchPlans());
    return (
        <div
            key={key}
            className="py-3 px-3 my-4 w-[98%] rounded relative"
            style={{
            backgroundColor: `${event.colorCode}1A`,
            borderLeft: `8px solid ${event.colorCode}`,
            // cursor:'pointer'
            
            }}
        >   
        {/* {moment().isAfter(event.endDate) && 
            <span className="" style={{alignItems:'start', marginRight: 5,display:'inline-block',verticalAlign:'top'}}><input type="checkbox" name="isCompleted" /></span>
            } */}
            <span className="" style={{display:'inline-block',width:'100%', position:'relative'}}>
             
            <p
            className="text-sm flex"
            style={{ color: `${event.colorCode}`}}
            >
               
            <span className="flex-1">
                {moment(event.startDate).format(
                "MMM DD YYYY"
                )}
            </span>
            {event.eventType!=='DEADLINE' ?
            <span className="flex-1 flex-inline text-right">
                 {updating && <Spin />}
                {formatTime(event.startDate)}
                {event.eventType!=='REMINDER' && event.endDate &&
                <> - {" "}
                {formatTime(event.endDate)} 
                </>}
            </span> :  
            <span className="flex-1 flex-inline text-right">
                
                {formatTime(event.endDate)} 
                
            </span>}
            <span ref={menuRef} className="pl-2" style={{verticalAlign:'top',position:'relative'}}>
            {moment().isAfter(event.endDate) &&  <BsThreeDots size={24} onClick={toggleModal} style={showMenu ? {borderRadius: 4,backgroundColor:`${event.colorCode}`,color: 'white'}:{}}/> }

                <div
                    className="absolute right-0 z-10 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                    style={!showMenu ? { display: "none" } : {}}
                >
                    <div className="py-1" role="none">
                    {/* <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" --> */}
                    <button
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-[100%] text-left"
                        role="menuitem"
                        tabindex="-1"
                        style={{whiteSpace:'nowrap'}}
                        id="menu-item-0"
                        onClick={() => updateStatus(event)}
                    >
                       {event.isCompleted ? 'Mark incomplete' : 'Mark as completed'}
                    </button>
                    </div>
                    
                </div>
            </span>
            </p>
            <h6
            className="font-semibold"
            style={{ color: `${event.colorCode}` }}
            >
            {event?.title}
            </h6>
            <p
            onClick={() => {
                handleSetEvent(event);
                setShowModal(true);
            }}
            className="text-sm text-gray-500 underline cursor-pointer mt-1"
            >
            More details
            </p>
            
            <span style={{position:'absolute',right:0,bottom:5}}>
            {/* <span style={{}}>
                
                <span style={{backgroundColor: '#00aa00',color:'white',padding:'3px 8px',borderRadius:5,marginRight: 5}}>Mark as Completed</span>
                <span style={{backgroundColor: '#ff00003A',color:'white',padding:'3px 8px',borderRadius:5,marginRight: 5}}>{event.isCompleted ? 'Done' : 'OverDue'}</span>
            </span>
            */}
            {moment().isAfter(event.endDate) && 
                <EventStatus event={event}/>
            }
            {event.eventType &&
                <span style={{marginLeft: 5,backgroundColor: `${event.colorCode}`,color:'white',padding:'3px 8px',borderRadius:5}}>{EVENT_TYPES[event.eventType]}</span>
            }
            </span>
            </span>
            {/* {event.eventType &&
            <span style={{position:'absolute',right:20,bottom:5, backgroundColor: `${event.colorCode}`,color:'white',padding:'3px 8px',borderRadius:5}}>{EVENT_TYPES[event.eventType]}</span>
            } */}
        </div>

    );
}

export default EventCard;