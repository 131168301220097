import { FETCH_SCHEDULES, SAVE_SCHEDULE, UPDATE_SCHEDULE, DELETE_SCHEDULE,FETCH_TASKS,SAVE_TASK,DELETE_TASK,UPDATE_TASK } from '../actions/programScheduleActions';

const initialState = {
  schedules: [],
  tasks: []
};

export const programScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
      };
    case SAVE_SCHEDULE:
      return {
        ...state,
        schedules: [...state.schedules, action.payload],
      };
    case UPDATE_SCHEDULE:
      let schedulesTemp = state.schedules ? [...state.schedules] :[];
      if(schedulesTemp && schedulesTemp.length>0) { schedulesTemp.map(schedule => 
        {
          let sch = {...schedule}
          if(schedule.id === action.payload.id){
            sch = {...action.payload};
          } 
          return sch;
        }
      )}
      return {
        ...state,
        schedules: [...schedulesTemp]
      };
    case DELETE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter(schedule => schedule.id !== action.payload),
      };
      case FETCH_TASKS:
        return {
          ...state,
          tasks: action.payload,
        };
      case SAVE_TASK:
        return {
          ...state,
          tasks: [...state.tasks, action.payload],
        };
      case UPDATE_TASK:
        let tasksTemp = state.tasks ? [...state.tasks] :[];
        if(tasksTemp && tasksTemp.length>0) { tasksTemp.map(task => 
          {
            let sch = {...task}
            if(task.id === action.payload.id){
              sch = {...action.payload};
            } 
            return sch;
          }
        )}
        return {
          ...state,
          tasks: [...tasksTemp]
        };
      case DELETE_TASK:
        return {
          ...state,
          tasks: state.tasks.filter(t => t.id !== action.payload),
        };
    default:
      return state;
  }
};
