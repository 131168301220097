import React from "react";
import { MessageSquare } from "lucide-react";

const ForumWelcomePlaceholder = () => {
  return (
    <div className="h-full flex flex-col items-center justify-center text-center p-8">
      <div className="bg-blue-50 p-6 rounded-full mb-6">
        <MessageSquare className="w-12 h-12 text-blue-500" />
      </div>

      <h1 className="text-2xl font-semibold text-gray-800 mb-3">
        Welcome to Forum
      </h1>

      <p className="text-gray-600 max-w-md mb-8">
        Select a chat from the sidebar to start messaging, or create a new
        conversation using the{" "}
        <span className="font-bold italic">Search User</span> feature.
      </p>

      <div className="grid grid-cols-2 gap-6 text-sm text-gray-500">
        <div className="flex flex-col items-center p-4 bg-gray-50 rounded-lg">
          <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mb-2">
            <span className="text-blue-500">1</span>
          </div>
          <p>Select a contact</p>
        </div>

        <div className="flex flex-col items-center p-4 bg-gray-50 rounded-lg">
          <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mb-2">
            <span className="text-blue-500">2</span>
          </div>
          <p>Start chatting</p>
        </div>
      </div>
    </div>
  );
};

export default ForumWelcomePlaceholder;
