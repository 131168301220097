import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChats, setCurrentChat } from "../../actions/chatActions";
import DefaultUserIcon from "../../assets/default-user-icon.png";
import preppyIcon from "../../assets/icons/PreppyDog.svg";
import moment from "moment";
import AuthImage from "../../components/AuthImage";
import { useNavigate, useParams } from "react-router-dom";
import TypingIndicator from "./TypingIndicator";

const ChatSkeleton = () => (
  <div className="flex items-center justify-between p-2 animate-pulse">
    <div className="flex items-center justify-start my-2 gap-1">
      <div className="w-[50px] h-[50px] bg-gray-200 rounded-full"></div>
      <div className="pl-3">
        <div className="h-4 bg-gray-200 rounded w-24 mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-32"></div>
      </div>
    </div>
    <div className="h-3 bg-gray-200 rounded w-10"></div>
  </div>
);

const Chats = () => {
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.chat.chats);
  const typingStatus = useSelector((state) => state.chat.typingStatus);
  const currentUserId = useSelector((state) => state.users.currentUser.id);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  let { chatId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchChats());
      } catch (error) {
        console.error("Error fetching chats:", error);
      } finally {
        setIsLoading(false);
        setInitialLoad(false);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (chatId) {
      setSelectedChatId(chatId);
      dispatch(setCurrentChat(chatId));
    }
  }, [chatId, dispatch]);

  const handleChatClick = (chatId) => {
    navigate(`/forum/${chatId}`);
  };

  const sortedChats = chats.slice().sort((a, b) => {
    const lastMessageA = a.messages?.[a.messages.length - 1];
    const lastMessageB = b.messages?.[b.messages.length - 1];

    const dateA = lastMessageA ? new Date(lastMessageA.date) : new Date(0);
    const dateB = lastMessageB ? new Date(lastMessageB.date) : new Date(0);

    return dateB - dateA;
  });

  const renderPreppyChat = () => (
    <div
      className={`flex items-center justify-between cursor-pointer p-2 rounded-lg ${
        selectedChatId === "preppy" ? "bg-blue-100" : "hover:bg-gray-100"
      }`}
    >
      <div className="flex items-center justify-start gap-1">
        <div className="w-[50px] h-[50px] rounded-full overflow-hidden">
          <img src={preppyIcon} alt="Preppy" />
        </div>
        <div className="pl-3">
          <h3 className="text-[.9rem] font-[600]">Preppy [Bot]</h3>
          <p className="text-gray-600 text-[.7rem] font-[500]">
            I'm Under Construction😥
          </p>
        </div>
      </div>
    </div>
  );

  const renderChatItem = (chat) => {
    const otherParticipant = chat.participants?.find(
      (participant) => participant.user.id !== currentUserId
    );

    let participantName = otherParticipant
      ? `${otherParticipant.user.firstName || "Unknown"} ${
          otherParticipant.user.lastName || "User"
        }`
      : "Unknown User";
    if (chat.name) {
      participantName = chat.name;
    }

    const lastMessage = chat.messages?.[chat.messages.length - 1];
    const lastMessageDate = lastMessage
      ? moment(lastMessage.date).format("hh:mm a")
      : "";
    const lastMessageText = lastMessage?.text || "No messages yet";

    return (
      <div
        key={chat.id}
        className={`flex items-center justify-between my-2 cursor-pointer p-2 rounded-lg ${
          selectedChatId === chat.id ? "bg-blue-100" : "hover:bg-gray-100"
        }`}
        onClick={() => handleChatClick(chat.id)}
      >
        <div className="flex items-center justify-start gap-1 flex-1 min-w-0">
          <div className="w-[50px] h-[50px] rounded-full overflow-hidden flex-shrink-0">
            <AuthImage
              imageurl={otherParticipant?.user?.avatar || DefaultUserIcon}
              className="w-full h-full object-cover"
              maxWidth="50px"
              maxHeight="50px"
            />
          </div>
          <div className="pl-3 flex-1 min-w-0">
            <h3 className="text-[.9rem] font-[600] truncate">
              {participantName}
            </h3>
            <div className="text-gray-600 text-[.7rem] font-[500] truncate">
              {typingStatus && typingStatus.chatId === chat.id ? (
                <TypingIndicator />
              ) : (
                lastMessageText
              )}
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 ml-2">
          <p className="text-gray-500 text-[.7rem] my-1">{lastMessageDate}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="py-3">
      {renderPreppyChat()}
      <div className="mt-2 border-t pt-4">
        {initialLoad || isLoading
          ? Array(5)
              .fill(0)
              .map((_, index) => <ChatSkeleton key={index} />)
          : sortedChats.map((chat) => renderChatItem(chat))}
      </div>
    </div>
  );
};

export default Chats;
