import React, { useRef, useState } from "react";

const EventStatus =({event})=>{

   
    return (
        <span>
            {event.isCompleted ?
                <span style={{color:'#00aa00',padding:'3px',borderRadius:5, cursor:'pointer'}} >Completed</span>
                :
            <span style={{color:'#ff0000',padding:'3px',borderRadius:5}}>OverDue</span>
            }

        </span>
    )

}
export default EventStatus;