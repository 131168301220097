import React from "react";
import crossIcon from "../../assets/crossIcon.svg";

const Modal = ({ showModal, setShowModal, children }) => {
  return (
    <>
      {showModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="relative bg-white w-full max-w-[55%] m-4 rounded-lg shadow-lg">
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={() => setShowModal(false)}
            >
              <img src={crossIcon} alt="close-icon"></img>
            </button>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
