import {
  FETCH_EVENTS,
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  FETCH_COLOR_SCHEME,
  FETCH_PLANS,
} from "../actions/calendarActions";

const initialState = {
  events: [],
  plans: undefined,
  colorScheme: {},
};

export const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLANS:
      return { ...state, plans: action.payload };
    case FETCH_EVENTS:
      return { ...state, events: action.payload };
    case CREATE_EVENT:
      const newEvents = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      return { ...state, events: [...state.events, ...newEvents] };
    case UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === action.payload.id
            ? { ...event, ...action.payload }
            : event
        ),
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event.id !== action.payload),
      };
    case FETCH_COLOR_SCHEME:
      return { ...state, colorScheme: action.payload };
    default:
      return state;
  }
};
