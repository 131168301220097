import {
  FETCH_CHATS,
  CREATE_CHAT,
  FETCH_PARTICIPANTS,
  ADD_PARTICIPANT,
  FETCH_MESSAGES,
  SEND_MESSAGE,
  SET_CURRENT_CHAT,
  REFRESH_CHAT,
  SET_SEARCHED_USERS,
  CLEAR_SEARCHED_USERS,
  TYPING_STATUS,
  TYPING_STATUS_CLEAR,
  SET_CHAT_SEARCHED_USERS,
  CLEAR_CHAT_SEARCHED_USERS,
  TOGGLE_ADD_PARTICIPANT_POPUP,
  RENAME_CHAT,
  DELETE_MESSAGE,
  DELETE_CHAT,
  PIN_MESSAGE,
  UNPIN_MESSAGE,
  EDIT_MESSAGE,
} from "../actions/chatActions";

const initialState = {
  chats: [],
  participants: {},
  messages: {},
  currentChatId: null,
  searchedUsers: [],
  chatSearchedUsers: [],
  typingStatus: undefined,
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHATS:
      return {
        ...state,
        chats: action.payload,
      };
    case CREATE_CHAT:
      return {
        ...state,
        chats: [...state.chats, action.payload],
        currentChatId: action.payload.id,
      };
    case FETCH_PARTICIPANTS:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat.id === action.chatId
            ? { ...chat, participants: action.payload }
            : chat
        ),
      };
    case ADD_PARTICIPANT:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat.id === action.payload.chatId
            ? {
                ...chat,
                participants: chat.participants
                  ? [...chat.participants, action.payload.newParticipant]
                  : [action.payload.newParticipant],
              }
            : chat
        ),
      };
    case FETCH_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.chatId]: action.payload.messages,
        },
      };
    case REFRESH_CHAT:
      let chatmgs = [...state.chats];
      chatmgs.map((c) => {
        if (c.id === action.payload.chatId) {
          let msgs = [...c.messages];
          msgs.push(action.payload.message);
          c.messages = msgs;
        }
        return c;
      });
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.chatId]: [
            ...state.messages[action.payload.chatId],
            action.payload.message,
          ],
        },
      };
    case TYPING_STATUS:
      return {
        ...state,
        typingStatus: action.payload,
      };
    case SEND_MESSAGE:
      let chats = [...state.chats];
      chats.map((c) => {
        if (c.id === action.payload.chatId) {
          let msgs = [...c.messages];
          msgs.push(action.payload.message);
          c.messages = msgs;
        }
        return c;
      });
      return {
        ...state,
        chats: [...chats],
      };
    case TYPING_STATUS_CLEAR:
      return {
        ...state,
        typingStatus: undefined,
      };
    case SET_SEARCHED_USERS:
      return {
        ...state,
        searchedUsers: action.payload,
      };
    case CLEAR_SEARCHED_USERS:
      return {
        ...state,
        searchedUsers: [],
      };
    case SET_CURRENT_CHAT:
      return {
        ...state,
        currentChatId: action.payload,
      };

    case SET_CHAT_SEARCHED_USERS:
      return {
        ...state,
        chatSearchedUsers: action.payload,
      };
    case CLEAR_CHAT_SEARCHED_USERS:
      return {
        ...state,
        chatSearchedUsers: [],
      };
    case TOGGLE_ADD_PARTICIPANT_POPUP:
      return {
        ...state,
        showAddParticipantPopup: action.payload,
      };
    case RENAME_CHAT:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat.id === action.payload.chatId
            ? { ...chat, name: action.payload.newName }
            : chat
        ),
      };
    case DELETE_MESSAGE:
      const { currentChatId, msgId } = action.payload;
      return {
        ...state,
        messages: {
          ...state.messages,
          [currentChatId]: state.messages[currentChatId].filter(
            (msg) => msg.id !== msgId
          ),
        },
      };
    case DELETE_CHAT:
      return {
        ...state,
        chats: state.chats.filter((chat) => chat.id !== action.payload),
      };

    case PIN_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.chatId]: state.messages[action.payload.chatId].map(
            (message) =>
              message.id === action.payload.messageId
                ? { ...message, pinned: true }
                : message
          ),
        },
      };

    case UNPIN_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.chatId]: state.messages[action.payload.chatId].map(
            (message) =>
              message.id === action.payload.messageId
                ? { ...message, pinned: false }
                : message
          ),
        },
      };

    case EDIT_MESSAGE: {
      const { chatId, messageId, updatedMessage } = action.payload;
      return {
        ...state,
        messages: {
          ...state.messages,
          [chatId]: state.messages[chatId].map((message) =>
            message.id === messageId
              ? { ...message, text: updatedMessage }
              : message
          ),
        },
      };
    }
    default:
      return state;
  }
};
