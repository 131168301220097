import React from 'react'
import CoupanImage from "../../assets/coupan.png"
const RewardBottom = () => {
  return (
    <div className='mx-3 grid grid-flow-col gap-8 grid-cols-3' >
        <div className='col-span-2 mt-3' >
        <div className='flex items-center justify-between' >
            <h3 className='font-[700] text-[1.5rem]' >Redeem the Points</h3>
            <p className='text-[#4658EB]' >See All</p>
            </div>
            <div className='grid grid-cols-3 my-2 grid-flow-row gap-3' >
                <div>
                    <div className='py-3 px-3 grid grid-cols-5 gap-3 items-end rounded-t-lg bg-gradient-to-r from-[#4658EB] to-[#3D81F3]' >
                        <div className='col-span-3 mt-2' >
                            <h3 className='text-white font-[600] text-[1rem]' >25% Discount</h3>
                            <p className='text-gray-200' >on</p>
                            <p className='text-gray-200 text-[.5rem]' >Just redeem and copy the cupon code on their official website</p>
                        </div>
                        <div className='col-span-2' >
                            <img src={CoupanImage} />
                        </div>
                    </div>
                    <div className='bg-white flex items-center justify-between rounded-b-lg py-2 px-3' >
                        <p className='text-[#FCB351] font-[600]' >2,500 Points</p>
                        <div className='bg-[#406fef55] text-[.8rem] text-[#4070EF] px-6 py-2 rounded-full' >Redeem</div>
                    </div>
                </div>
                <div>
                    <div className='py-3 px-3 grid grid-cols-5 gap-3 items-end rounded-t-lg bg-gradient-to-r from-[#B00DDE] to-[#EF41C6]' >
                        <div className='col-span-3 mt-2' >
                            <h3 className='text-white font-[600] text-[1rem]' >25% Discount</h3>
                            <p className='text-gray-200' >on</p>
                            <p className='text-gray-200 text-[.5rem]' >Just redeem and copy the cupon code on their official website</p>
                        </div>
                        <div className='col-span-2' >
                            <img src={CoupanImage} />
                        </div>
                    </div>
                    <div className='bg-white flex items-center justify-between rounded-b-lg py-2 px-3' >
                        <p className='text-[#FCB351] font-[600]' >2,500 Points</p>
                        <div className='bg-[#406fef55] text-[.8rem] text-[#4070EF] px-6 py-2 rounded-full' >Redeem</div>
                    </div>
                </div>
                <div>
                    <div className='py-3 px-3 grid grid-cols-5 gap-3 items-end rounded-t-lg bg-gradient-to-r from-[#FE9514] to-[#FDA83B]' >
                        <div className='col-span-3 mt-2' >
                            <h3 className='text-white font-[600] text-[1rem]' >25% Discount</h3>
                            <p className='text-gray-200' >on</p>
                            <p className='text-gray-200 text-[.5rem]' >Just redeem and copy the cupon code on their official website</p>
                        </div>
                        <div className='col-span-2' >
                            <img src={CoupanImage} />
                        </div>
                    </div>
                    <div className='bg-white flex items-center justify-between rounded-b-lg py-2 px-3' >
                        <p className='text-[#FCB351] font-[600]' >2,500 Points</p>
                        <div className='bg-[#406fef55] text-[.8rem] text-[#4070EF] px-6 py-2 rounded-full' >Redeem</div>
                    </div>
                </div>
                <div>
                    <div className='py-3 px-3 grid grid-cols-5 gap-3 items-end rounded-t-lg bg-gradient-to-r from-[#14C531] to-[#14C531]' >
                        <div className='col-span-3 mt-2' >
                            <h3 className='text-white font-[600] text-[1rem]' >25% Discount</h3>
                            <p className='text-gray-200' >on</p>
                            <p className='text-gray-200 text-[.5rem]' >Just redeem and copy the cupon code on their official website</p>
                        </div>
                        <div className='col-span-2' >
                            <img src={CoupanImage} />
                        </div>
                    </div>
                    <div className='bg-white flex items-center justify-between rounded-b-lg py-2 px-3' >
                        <p className='text-[#FCB351] font-[600]' >2,500 Points</p>
                        <div className='bg-[#406fef55] text-[.8rem] text-[#4070EF] px-6 py-2 rounded-full' >Redeem</div>
                    </div>
                </div>
                <div>
                    <div className='py-3 px-3 grid grid-cols-5 gap-3 items-end rounded-t-lg bg-gradient-to-r from-[#7A5715] to-[#E9A00F]' >
                        <div className='col-span-3 mt-2' >
                            <h3 className='text-white font-[600] text-[1rem]' >25% Discount</h3>
                            <p className='text-gray-200' >on</p>
                            <p className='text-gray-200 text-[.5rem]' >Just redeem and copy the cupon code on their official website</p>
                        </div>
                        <div className='col-span-2' >
                            <img src={CoupanImage} />
                        </div>
                    </div>
                    <div className='bg-white flex items-center justify-between rounded-b-lg py-2 px-3' >
                        <p className='text-[#FCB351] font-[600]' >2,500 Points</p>
                        <div className='bg-[#406fef55] text-[.8rem] text-[#4070EF] px-6 py-2 rounded-full' >Redeem</div>
                    </div>
                </div>
                <div>
                    <div className='py-3 px-3 grid grid-cols-5 gap-3 items-end rounded-t-lg bg-gradient-to-r from-[#4658EB] to-[#3D81F3]' >
                        <div className='col-span-3 mt-2' >
                            <h3 className='text-white font-[600] text-[1rem]' >25% Discount</h3>
                            <p className='text-gray-200' >on</p>
                            <p className='text-gray-200 text-[.5rem]' >Just redeem and copy the cupon code on their official website</p>
                        </div>
                        <div className='col-span-2' >
                            <img src={CoupanImage} />
                        </div>
                    </div>
                    <div className='bg-white flex items-center justify-between rounded-b-lg py-2 px-3' >
                        <p className='text-[#FCB351] font-[600]' >2,500 Points</p>
                        <div className='bg-[#406fef55] text-[.8rem] text-[#4070EF] px-6 py-2 rounded-full' >Redeem</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
        <div className='flex items-center mx-2 mt-3 justify-between' >
            <h3 className='font-[700] text-[1rem]' >Another Way to Get Points</h3>
            <p className='text-[#4658EB]' >See All</p>
            </div>
            <div className='grid grid-cols-2  gap-4 my-2  grid-flow-row' >
                <div className='bg-white shadow-lg py-3 rounded-lg px-3' >
                        <div className='bg-[#FFF4E6] w-[110px] text-[.6rem] text-[#FCB351] py-2 px-4 rounded-full' >Get 500+ Points</div>
                        <p className='text-[1.1rem] mt-3 font-[500]' >Finish Project Before Time</p>
                        <button className='border-[1px] border-[#7743DB] text-[#7743DB] py-2 w-full px-3 mt-2 rounded-full' >Join Mission</button>
                </div>
                <div className='bg-white shadow-lg py-3 rounded-lg px-3' >
                        <div className='bg-[#FFF4E6] w-[110px] text-[.6rem] text-[#FCB351] py-2 px-4 rounded-full' >Get 500+ Points</div>
                        <p className='text-[1.1rem] mt-3 font-[500]' >Finish Project Before Time</p>
                        <button className='border-[1px] border-[#7743DB] text-[#7743DB] py-2 w-full px-3 mt-2 rounded-full' >Join Mission</button>
                </div>
                <div className='bg-white shadow-lg py-3 rounded-lg px-3' >
                        <div className='bg-[#FFF4E6] w-[110px] text-[.6rem] text-[#FCB351] py-2 px-4 rounded-full' >Get 500+ Points</div>
                        <p className='text-[1.1rem] mt-3 font-[500]' >Finish Project Before Time</p>
                        <button className='border-[1px] border-[#7743DB] text-[#7743DB] py-2 w-full px-3 mt-2 rounded-full' >Join Mission</button>
                </div>
                <div className='bg-white shadow-lg py-3 rounded-lg px-3' >
                        <div className='bg-[#FFF4E6] w-[110px] text-[.6rem] text-[#FCB351] py-2 px-4 rounded-full' >Get 500+ Points</div>
                        <p className='text-[1.1rem] mt-3 font-[500]' >Finish Project Before Time</p>
                        <button className='border-[1px] border-[#7743DB] text-[#7743DB] py-2 w-full px-3 mt-2 rounded-full' >Join Mission</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RewardBottom